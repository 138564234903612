.wrapper {
    position: relative;
    height: fit-content;
    border-radius: 0 0 5px 5px;
    padding: 11px 0 11px 15px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    transition: left .2s;
    left: -80px;
    margin-top: -52px;

    &._open {
        transition: left .2s, box-shadow .1s .1s;
        left: 0;
        box-shadow: none;
    }

    &.fullMode {
        margin-top: 0;
        display: inline;
        .flex {
            transition: display 0s 0s, margin-left .2s;
            display: inline-flex;
            margin-left: 297px;
            padding-top: 6px;
        }
        .back {
            padding: 0;
            margin-right: 11px;
            order: -1;
            transform: translateY(-1.5px);
            .tip {
                top: -36px;
                left: 0;
            }
        }
    }
    &.b:not(.fullMode) {
        margin-top: -111px;
    }
    &.b.fullMode .flex {
        margin-left: 251px;
    }
}

.flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > div:first-child {
        margin-right: 12px;
    }
}

.tip {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
}

.pin {
    position: relative;
    display: inline-block;
    width: fit-content;
    cursor: pointer;
    .pin-img {
        padding: 5px;
        cursor: pointer;
        > img {
            cursor: pointer;
            width: 12px;
            height: 12px;
        }
    }
    &:hover .tip {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
    .tip {
        top: -32.5px;
        left: -7px;
    }
}

.arrow {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:hover .tip {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
    .tip {
        top: -34px;
        left: -11px;
    }
}

.arrow .arrowBtn {
    position: relative;
    width: 16px;
    height: 26px;
    padding: 3px;
    &::before, &::after {
        content: '';
        border-radius: 1px;
        background-color: var(--color_gray);
        width: 8px;
        height: 1px;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% + 2px);
        right: 3px;
        transition: background-color .2s;
    }
    &::after {
        top: 10px;
        transform: rotate(45deg);
    }
    &.reverse::before {
        transform: rotate(45deg);
    }
    &.reverse::after {
        transform: rotate(-45deg);
    }
}

.filters {
    display: flex;
    margin: 35px 35px 25px;
    overflow: hidden;
    &_btn {
        font-size: var(--fontSize_standart);
        white-space: nowrap;
        color: var(--color_primary);
        border-bottom: 1px dashed var(--color_primary);
        cursor: pointer;
        padding: 2px 0 0;
        margin: 0 10px;
        transition: color .2s, background-color .2s;
        &:hover:not(.current) {
            color: var(--color_primary_hover);
        }
        &._current {
            color: var(--color_darkGray);
            border-radius: 4px;
            border: none;
            padding: 2px 5px 0px;
            margin: 0 5px;
            background-color: var(--color_lightGray2);
            cursor: default;
            &:hover {
                color: var(--color_darkGray);
            }
        }
    }
}

.back {
    position: relative;
    display: inline-block;
    width: fit-content;
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    padding: 5px 13px 5px 8px;
    transition: color .2s;
    &-btn {
        padding: 5px;
        cursor: pointer;
    }
    &:hover {
        color: var(--color_primary_hover);
    }
    &:hover .tip {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
    .tip {
        top: -28px;
        left: 8px;
    }
}
