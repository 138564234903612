.wrapper {
    position: relative;
    display: flex;
    align-items: baseline;
}
  
.scroll-button-block {
    position: relative;
    width: 104px;
    height: 100%;
    margin-right: 40px;
    &_mask {
        position: absolute;
        left: 0;
        top: 0;
        height: 50px;
        width: 80px;
        background-color: white;
        transition: all .2s;
        z-index: 8;
    }
    &_open {
        .scroll-button-block_mask {
            width: 325px;
        }
        .scroll-buttons {
            width: 322px;
            padding-right: 40px;

            &::after {
                display: none;
            }
            &-grad {
                right: -254px;
            }
        }
        .arrow-right, .arrow-left {
            right: -228px;
        }
    }
    &_noScroll {
        width: fit-content;
        margin-right: 0;
        .scroll-buttons {
            position: static;
            width: fit-content;
            border-right: none;
            padding-right: 0;
        }
        .scroll-button-block_mask {
            display: none;
        }
    }
}
.scroll-buttons {
    position: absolute;
    top: 0;
    left: 1px;
    display: flex;
    padding-top: 18px;
    width: 104px;
    height: 57px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s;
    z-index: 9;
    &-grad {
        position: absolute;
        top: 0;
        right: -36px;
        height: 50px;
        width: 35px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        z-index: 9;
        transition: all .2s;
    }
    > button:first-child {
        margin-right: 40px;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        z-index: 2;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}

.collabButtonsBlock {
    position: relative;
    width: 70px;
    height: 100%;
    &_open {
        .collabButtons {
            width: 287px;
            padding-right: 40px;
            &::after {
                display: none;
            }
            &-grad {
                right: -253px;
            }
        }
        .arrow-right, .arrow-left {
            right: -227px;
        }
    }
    &_open2 {
        .collabButtons {
            width: 153px;
            padding-right: 40px;
            &::after {
                display: none;
            }
            &-grad {
                right: -119px;
            }
        }
        .arrow-right, .arrow-left {
            right: -93px;
        }
    }
}
.collabButtons {
    position: absolute;
    top: 0;
    left: 1px;
    padding-left: 40px;
    padding-right: 0;
    display: flex;
    align-items: center;
    width: 70px;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s;
    z-index: 3;
    &-grad {
        position: absolute;
        top: 0;
        right: -36px;
        height: 50px;
        width: 35px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        z-index: 3;
        transition: all .2s;
    }
    > button:first-child {
        margin-right: 40px;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}
.arrow-right, .arrow-left {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #fff;
    right: -12px;
    top: 16px;
    transition: all .2s;
    z-index: 9;
    &::before, &::after {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: #979797;
        top: 3px;
        left: 9px;
        width: 1px;
        height: 8px;
        transform: rotate(-45deg);
    }
    &::after {
        top: 8px;
        transform: rotate(45deg);
    }
}
.arrow-left {
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

.screen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 3;
}

.buttonBlock {
    position: relative;
    padding: 0 40px;
    width: auto;
    display: flex;
    align-items: baseline;
}
.alert {
    position: relative;
    width: fit-content;
    margin-right: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_lightRed);
    white-space: nowrap;
    &.non_alert {
       color: var(--color_darkGray); 
       & span {
           top: 15px;
       }
    }
    &_time {
        position: absolute;
        font-size: var(--fontSize_tip);
        color: var(--color_lightGray2);
        left: 0;
        top: 28px;
        height: 14px;
        margin-right: 40px;
        width: fit-content;
        overflow: hidden;
        white-space: nowrap;
    }
}

.approvalButton {
    position: relative;
    background-color: transparent;
    margin-right: 40px;
}
.approvalButton:hover {
    z-index: 9;
    transition-delay: .3s;
    > div {
        opacity: 1;
        visibility: visible;
        z-index: 2;
    }
}
.approvalButton button {
    z-index: 2;
}
.approvalButton:hover button {
    z-index: 3;
}

.sendButtonBlock {
    position: relative;
    top: 0px;
    > button {
        width: 83px !important;
    }
}
.sendMenu-wrapper {
    position: absolute;
    display: none;
    top: -40px;
    width: fit-content;
    padding: 100px 0 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    z-index: 9;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ;
    &_vis {
        visibility: visible;
        opacity: 1;
        display: flex;
    }
}

.changesBlock {
        top: 6px;
}