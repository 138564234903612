.hidden {
    display: none;
}

.wrapper {
    position: absolute;
    width: fit-content;
    white-space: nowrap;
    background-color: white;
    border-radius: 6px;
    box-shadow: var(--shadow_menu);
    z-index: 12;
    user-select: none;
    font-size: var(--fontSize_frameNormal);
}

.history {
    font-size: var(--fontSize_frameSmall);
    border-bottom: 1px solid var(--color_lightGray);
    padding: 10px 15px 10px;
    max-height: 90px;
    color: var(--color_gray);
    overflow-y: scroll;
    scrollbar-color: #cacaca rgba(255, 255, 255, 0);
    scrollbar-width: thin;
    &:hover {
        overflow-scrolling: touch;
    }
    &::-webkit-scrollbar {
        appearance: none;
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        margin-right: 2px;
        border-radius: 2px;
        background-color: #cacaca;
        box-shadow: 0 0 1px rgba(255,255,255,0.5);
    }
}
.historyLine {
    &:hover {
        background-color: var(--color_yellow_frame);
        cursor: pointer;
    }
    &:not(:last-child) {
        margin-bottom: 5px;
    }
    & > span {
        color: var(--color_lightGray2);
        margin-left: 10px;
    }
}

.bottomPart {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 15px;
}
.vertical {
    width: 1px;
    height: 100%;
    background-color: var(--color_lightGray);
    margin-right: 15px;
    margin-left: 15px;
}

.btn {
    cursor: pointer;
    &.semi {
        cursor: initial;
        color: var(--color_lightGray);
    }
}
.btn-accept {
    color: var(--color_green);
}
.btn-decline {
    color: var(--color_darkRed);
    margin: 0 12px;
}
.btn-mute {
    color: var(--color_darkGray);
}
.btn-accept,.btn-mute {
    position: relative;
    & > div {
            top: 22px;
            left: -10px;
            visibility: hidden;
            opacity: 0;
        }
    &:hover > div {
            visibility: visible;
            opacity: 1;
            transition: opacity .2s .5s, visibility .2s .5s;
        }
}
.btn-comment {
    color: var(--color_primary);
}
.btn-format {
    margin-right: 15px;
    width: 19px;
    height: 19px;
    background-image: url('/images/icon-style-formatting.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.btn-menu {
    width: 14px;
    height: 14px;
    background-image: url('/images/para-menu-blue.png');
    background-repeat: no-repeat;
}
