.wrapper {
    display: flex;
    flex-wrap: wrap;
    &._unvisible {
        display: none;
    }
}

.pdwrapper {
    margin-top:50px;
    margin-bottom: 100px;
    min-height: 686px;
}

.pdOptions {
    font-size: var(--fontSize_standart);
    /*line-height: 1.5;*/
    letter-spacing: 0.01px;
    color: var(--color_black);
    margin: 0;
    /*padding: 2px 0 1px;*/
}
.pdFind {
    width: 200px;
    margin: 0 20px 0 10px;
    border-radius: 4px;
    border: solid 1px #dedede;
    padding-left: 4px;    
}
.pdNfound {
    color: var(--color_darkRed);
}
.pdSearch {
    margin-right: 40px;
    color: var(--color_primary);
    cursor: pointer;
}
.pdSel {
    color: var(--color_primary);
    padding: 2px 0 1px;
    margin: 0 10px 0 15px;
    cursor: pointer;
    border-bottom: dashed 1px var(--color_primary);
    &._selected {
        color: var(--color_darkGray);
        border-radius: 4px;
        background-color: rgba(222, 222, 222, 0.5);
        cursor: initial;
        border-bottom: none;
        padding: 2px 5px 1px;
        margin: 0 5px 0 10px;
    }
}

.pdHeadCont {
    padding-bottom: 10px;
    border-bottom: solid 1px var(--color_lightGray2);
    display: flex;
}

.pdHead {
    font-size: var(--fontSize_standart);
    line-height: 1.5;
    color: var(--color_gray);
}
.pdHeadCont ._pdColFirst {
    flex-basis: 450px !important;
}
.onHeadCont ._pdColFirst {
    flex-basis: 450px !important;
}
.onDoc ._pdColFirst {
    flex-basis: 450px !important;
}
.pdTitleCont:hover, .pdCompanyProject .pdStageInfo:hover{
    cursor: pointer;
    color: var(--color_primary);
}


.companyWrapper .pdColumn {
    &._pdColFirst {
        flex-basis: 371px !important;
    }
}
.pdColumn {
    padding-left: 30px;
    background-color: white;
    z-index: 3;
    position: relative;
    &._pdColFirst {
        padding-left: 0;
        flex-basis: 410px;
        min-width: 250px;
        z-index: 1;
    }
    &._pdColFirst:hover {
        z-index: 4;
    }
    & .pdTitleAft{
        display: flex;
        align-items: baseline;
    }
    &._pdCol10 {
        flex: 0 0 40px;
    }
    &._pdCol145 {
        flex: 0 0 175px;
    }
    &._pdCol180 {
        flex: 0 0 210px;
    }
    &._pdCol200 {
        flex: 0 0 230px;
    }
    &._pdCol240 {
        flex: 0 0 270px;
    }
}
.pdTitleCont {
    position: relative;
    font-size: var(--fontSize_tab);
    flex-grow: 1;
    word-break: break-word;
}
.pdTitle {
    position: absolute;
    white-space: nowrap;
    top: -10px;
    left: -10px;
    background-color: white;
    padding: 10px;
}

.pdStage {
    display: flex;
    flex-direction: column;
    &_finalized {
        position: relative;
        padding-left: 20px;
        .pdStage-text {
            color: var(--color_green);
            font-weight: bold;
            &::after, &::before {
                top: 3px;
                content: '';
                position: absolute;
                left: -14px;
                bottom: 0;
                height: 10px;
                width: 2px;
                background-color: var(--color_green);
                transform: rotate(45deg);
            }
            &::before {
                top: 6px;
                height: 6px;
                transform: rotate(-45deg);
                left: -19px;
            }
        }
    }
}
.pdStageInfo {
    color: var(--color_gray);
}
.pdStage-text {
    position: relative;
    &_green {
        color: var(--color_green);
    }
    &_red {
        color: var(--color_lightRed);
    }
}
.pdDisabledCreateProject:hover .pdDisabledCreateProject_hint {
    opacity: 1;
    visibility: visible;
}
.pdDisabledUploadDocument:hover .pdDisabledUploadDocument_hint {
    opacity: 1;
    visibility: visible;
}
.pdDisabledCreateProject, .pdDisabledUploadDocument{
    position: relative;
    button {
        margin-right: 0 !important;
    }
    &_hint {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -5px;
        top: -66px;
        width: 220px;
        color: var(--color_lightGray2);
        font-size: var(--fontSize_tip);
        border-radius: 4px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
        background-color: var(--color_darkGray);
        z-index: 1;
        padding: 10px;
        transition: all .2s;
        &:before {
            border: solid transparent;          
            position: absolute;
            border-width: 9px;
            content: '';
            top: 100%;
            border-top-color: var(--color_darkGray);
            border-bottom: none; 
        }
        > div {
            display: inline-block;
            cursor: pointer;
            transition: color .2s;
            &:hover {
                color:  var(--color_primary_hover);
            }
        }
    }
}

.pdAttention {
    display: flex;
    flex-direction: column;
    font-size: var(--fontSize_standart);
    line-height: 1.5;
    color: var(--color_lightRed);
}
.pdActivity {
    display: flex;
}
.pdInActivity > span {
    color: var(--color_gray);
}
.counter {
    margin-right: 11px;
    margin-top: -2px;
    font-size: var(--fontSize_tip);
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 100%;
    background-color: var(--color_darkRed);
}
.companyWrapper {
    margin: 20px 0;
    color: var(--color_black);
}
.companyTitle {
    font-weight: bold;
    font-size: var(--fontSize_h3);
    cursor: pointer;
}
.projectWrapper {
    position: relative;
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color_lightGray);
    color: var(--color_black);
    &:last-child {
        border: none;
        padding-bottom: 0;
    }
    &._shifted {
        margin-left: 40px;
    }
}
.projectTitle {
    font-weight: bold;
    font-size: var(--fontSize_tab);
    cursor: pointer;
}
.expander {
    display: inline-block;
    position: relative;
    left: 4px;
    top: 2px;
    width: 15px;
    height: 15px;
    background: url('/images/down@3x.png');
    z-index: 1;
    background-size: cover;
    cursor: pointer;
    &._expanded {
        background: url('/images/up@3x.png');
        background-size: cover;
    }
}
.seeAll {
    cursor: pointer;
    height: 15px;
    font-size: var(--fontSize_standart);
    font-weight: normal;
    color: var(--color_primary);
    &._shifted {
        padding-left: 40px;
    }
}
.pdDoc {
    position: relative;
    padding: 20px 0;
    margin-left: 40px;
    font-size: var(--fontSize_standart);
    font-weight: normal;
    line-height: 1.36;
    color: var(--color_darkGray);
    border-top: solid 1px var(--color_lightGray);
    display: flex;
    &:nth-child(2) {
        border: none;
    }
    &:last-child {
        padding-bottom: 0;
    }
}

.pdDocSym {
    margin: 0 31px 0 0px;
    &._shifted {
    //margin-left: 40px;
    }
   &._shifted2 {
        margin-left: 80px;
    }
}
[data-level="1"] .pdDocSym, [data-level="1"] .pdDocExt {
    margin-left: 40px;
}
[data-level="2"] .pdDocSym, [data-level="2"] .pdDocExt {
    margin-left: 80px;
}
[data-level="3"] .pdDocSym, [data-level="3"] .pdDocExt {
    margin-left: 120px;
}
[data-level="4"] .pdDocSym, [data-level="4"] .pdDocExt {
    margin-left: 160px;
}
[data-level="5"] .pdDocSym, [data-level="5"] .pdDocExt {
    margin-left: 200px;
}
[data-level="6"] .pdDocSym, [data-level="6"] .pdDocExt {
    margin-left: 240px;
}
[data-level="7"] .pdDocSym, [data-level="7"] .pdDocExt {
    margin-left: 280px;
}

.pdDocExt {
    font-size: 9px;
    width: 32px;
    margin-right: 10px;
    display: inline-block;
    color: var(--color_gray); 
    &._shifted2 {
        margin-left: 80px;
    }
}

.dragover:after {
        content: "";
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        border: 1px dashed var(--color_primary);
        border-radius: 8px;
        top: 0;
        left: -10px;
        z-index: 5;
    }

.onDoc {
    position: relative;
    padding: 20px 0;
    font-size: var(--fontSize_standart);
    font-weight: normal;
    line-height: 1.36;
    color: var(--color_darkGray);
    border-top: solid 1px var(--color_lightGray);
    display: flex; 
    &:hover .onDocControls {
        display: block;
    }
}

.onHeadCont {
    padding: 5px 80px 5px 0;
    display: flex;
    position: relative;
}

.onDocControls {
    position: relative;
    display: none;
    flex: 1 1 auto;
}

.add-document {
    margin: 20px 0 40px;
    font-family: var(--fontFamily);
    cursor: pointer;
    width: 400px;
    border-radius: 10px;
    border: 1px dashed var(--color_lightGray2);
    height: 160px;
    color: var(--color_gray);
    font-size: var(--fontSize_18);
    position: relative;
    text-decoration: none;
    text-align: center;
    transition: border .2s ease-out, color .2s ease-out, background-color .2s;
    & path {
        transition: stroke .2s ease-out;
    }
    & > span {
        color: var(--color_primary);
    }
    &.active, &:hover {
        border: 1px dashed var(--color_primary);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    &:hover {
        border: 1px dashed var(--color_gray);
    }
}

.add-project {
    width: 32%;
    padding: 30px;
    border-radius: 25px;
    border: 1px dotted rgba(0, 0, 0, 0.1);
    height: 300px;
    color: var(--color_gray);
    font-family: var(--fontFamily);
    cursor: pointer;
    position: relative;
    text-decoration: none;
    text-align: center;
    transition: border .2s ease-out, color .2s ease-out;
    & path {
        transition: stroke .2s ease-out;
    }
    &:hover {
        border-color: var(--color_lightGray);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    & > span {
        color: var(--color_primary);
    }
}

.margined {
    margin-right: 2%;
    margin-bottom: 30px;
}

.add-project-nav {
    width: 100%;
    height:100%;
    display: block;

    cursor: pointer;
    color: var(--color_gray);
    transition: border .2s ease-out, color .2s ease-out;
    & path {
        transition: stroke .2s ease-out;
    }
    &:hover {
        border-color: var(--color_lightGray);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    & > span {
        color: var(--color_primary);
    }
}
.pages-block {
    position: relative;
    width: calc(100% + 80px);
    padding: 0 40px;
    left: -40px;
    border-bottom: 1px solid var(--color_lightGray2);
}

.pages {
    display: inline-block;
    margin-right: 40px;
    padding-bottom: 15px;
    font-size: var(--fontSize_tab);
    line-height: 1.36;
    letter-spacing: 0.01px;
    color: var(--color_darkGray);
    cursor: pointer;
    > .counter {
        display: inline-block;
        margin-left: 11px;
        margin-right: 0;
    }
    &._isSelected {
        border-bottom: solid 5px var(--color_lightGray2);
    }
}
.oneProject-controls {}
.oneProject-title {
    width: 100%;
    font-size: var(--fontSize_h1);
    color: var(--color_black);
    font-weight: 700;
    border: none;
    &-hint {
        color: var(--color_darkGray);
        width: auto;
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
        top: -33px;
        left: 0;
        position: absolute;
        transition: all .2s;
        z-index: 10;
        div {
            width: 100%;
            position: static;
        }
    }
}
.oneProject-title:hover ~ .oneProject-title-hint{
    opacity: 1;
    visibility: visible;
}
.oneProject-docs {
    position: relative;
}

.acme {
    width: fit-content;
    position: relative;
    font-size: var(--fontSize_tab);
    line-height: 19px;
    color: var(--color_darkGray);
    margin: 20px 0 20px 40px;
}
.acme img {
    left: -40px;
    top: 4px;
    position: absolute;
    height: 11px;
}
.uploadDocument {
    height: 20px;
    width: fit-content;
    white-space: nowrap;
    display: inline-block;
    padding: 3px 10px 2px;
    line-height: 12px;
    cursor: pointer;
    text-align: center;
    font-size: var(--fontSize_button);
    border-radius: var(--btn_borderRadius_primary);
    color: var(--color_primary);
    border: 1px solid var(--color_primary);
    transition: all .2s;
    margin-right: 20px;
    &:hover {
        border-color: var(--color_primary_hover);
        color: var(--color_primary_hover);
    }
}

.dragScreen {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    min-height: 155px;
    z-index: 7;
    font-family: var(--fontFamily);
    cursor: pointer;
    border-radius: 10px;
    border: 1px dashed var(--color_primary);
    background-color: rgba(255, 255, 255, 0.75);
    color: var(--color_black);
    font-size: var(--fontSize_18);
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    svg {
        margin-top: 0;
    }
    & path {
        stroke: var(--color_black);
    }
    & > span {
        color: var(--color_primary);
    }
    &.pj {
        top: 35px;
        height: calc(100% - 50px);
    }
    &.small {
        top: -15px;
        height: calc(100% + 25px);
        min-height: 46px;
        svg {
            display: none;
        }
    }
    &.vis {
        opacity: 1;
        visibility: visible;
    }
}
.dragActive > div, .dragActive {
    background-color: #f8f8f8;
}

.opCompaniesTeam {
    width: fit-content;
    /*margin: 0 auto;*/
}

.op-settings {}
.op-rename {
    margin-bottom: 50px;
    input {
        display: block;
        max-width: 100%;
        font-size: var(--fontSize_h1);
        color: var(--color_black);
        font-weight: 700;
        border-radius: 10px;
        padding: 3px 20px 2px;
        border: 1px solid var(--color_lightGray);
        margin-bottom: 20px;
        margin-top: 10px;
    }
}
.op-delete {}

.disDoc {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: -30px;
    transform: rotate(45deg);
    color: #d8d8d8;
    font-size: 25px;
    width: 20px;
    height: 20px;
}


.projectDropDown {

}

.dragFrame {
    position: relative;
    margin: -1px -5px 1px;
    padding: 0 4px;
    border: 1px dashed transparent;
    border-radius: var(--btn_borderRadius_primary);
    &.isOver {
      border-color: var(--color_primary); 
      & > * {
          opacity: 0.3;
      }
    }
}

.marg20 {
    margin-bottom: 20px;
    width: 1085px;
    align-items: baseline;
}
.crossPlace {
    right: -14px;
    position: relative;   
    z-index: 2;
    top: -46px; 
}

.arrowShift  {
    left: 10px;
    top: 0;
    cursor: pointer;
}

.showResults {
        font-size: var(--fontSize_standart);
        line-height: 1.5;
        color: var(--color_gray);    
}
.searchBlock {
    margin: 20px 40px 0 0;
    display: inline-block;
    vertical-align:top; 
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    width: 320px;
    & textarea {
        margin-top: 10px;
        color: var(--color_darkGray);
        width: 100%;
        height: 35px;
        padding: 9px 15px;
        border-radius: 4px;
        border: solid 1px var(--color_lightGray);
        resize: none;
        &.bigSearch   {
            height: 84px;
        }
        &::placeholder {
            color: var(--color_gray);
        }
    }
}
.search-header {
    font-weight: bold;
}
.searching {
    position: relative;
    & span {
        position: absolute;
    }
}
.tempHidden {
    display: none;
}