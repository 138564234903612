.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper form {
  padding-top: 102px;
}

.form-inner {
  position: relative;
  margin: 0 auto;
  padding: 40px;
  width: 420px;
  border-radius: 25px;
  box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.logo {
  position: absolute;
  top: -62px;
  left: 0;
}

.title {
  color: var(--color_black);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_h1);
  font-weight: bold;
  padding-bottom: 30px;
}

.inviteText {
  position: relative;
  font-size: var(--fontSize_18);
  color: var(--color_darkGray);
  line-height: 30px;
  padding-bottom: 50px;
  margin-top: 72px;
  padding-top: 50px;
  .logo  {
    top: -22px;
  }
  .invitor {
    color: var(--color_black);
    font-weight: bold;
  }
}

.row {
  position: relative;
  padding: 20px 0;
  display: block;
  &.border {
    border-bottom: 1px solid var(--color_lightGray2);
  }
}

.loginLink {
  position: absolute;
  cursor: pointer;
  right: 40px;
  top: 60px;
  font-size: var(--fontSize_standart);
  > a {
    color: var(--color_primary);
    text-decoration: none;
    &:hover {
      color: var(--color_primary_hover);
    }
  }
}

.error-message {
  position: absolute;
  color: var(--color_lightRed);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_standart);
  top: 0;
  left: 0;
  &.grey {
      color: var(--color_gray);
  }
}

.mail-desc {
  padding-top: 20px;
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
}

.genPass-message {
  padding-top: 20px;
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
  > span {
    color: var(--color_lightRed);
  }
}

.input {
  display: block;
  height: 19px;
  width: 100%;
  color: var(--color_darkGray);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_tab);
  border: 0;
  padding: 0;
  &::-webkit-input-placeholder {
    color: var(--color_darkGray);
    font-size: var(--fontSize_tab);
    font-weight: normal;
  }
  &.password {
    padding-right: 110px;
  }
}

.disabled-input {
  display: block;
  height: 19px;
  background-color: #fff;
  width: 100%;
  color: var(--color_gray);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_tab);
  border: 0;
  padding: 0;
}

.forgot-password {
  position: absolute;
  top: 26px;
  right: 5px;
  color: var(--color_primary);
  font-family: var(--fontFamily);
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
}

.message-wrapper {
  padding-top: 40px;
  text-align: center;
  color: var(--color_lightRed);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_h3);
  font-weight: 400;
}

.button-row {
  padding-top: 30px;
  text-align: center;
}

.link-row {
  padding-top: 40px;
  padding-bottom: 50px;
  text-align: center;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_tab);
  color: var(--color_gray);
}
.link-row2 {
  position: absolute;
  top: -60px;
  right: 0;
  text-align: right;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
  button {
    margin-right: 0 !important;
  }
}

.link {
  display: inline-block;
  margin-left: 20px;
  color: var(--color_black);
  font-weight: bold;
  text-decoration: none;
  transition: color .2s;
  &:hover {
    color: var(--color_primary_hover);
  }
}

.activation {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 190px);
  color: var(--color_black);
  font-family: var(--fontFamily);
  font-size: 30px;
  font-weight: 300;
}

.link-btn{
  position: absolute;
  top: 17px;
  bottom: 10px;
  right: 10px;
  &__a{
    font-family: var(--fontFamily);
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    text-decoration: none;
    cursor: pointer;
    transition: color .2s;
    &:hover {
      color: var(--color_primary_hover);
    }
  }
}



.checkbox {
  font-size: var(--fontSize_tab);
  display: flex;
  align-items: start;
  width: fit-content;
  padding: 20px 0 10px 0;
  > label > span > svg {
    top: -1px;
  }
  > div > span {
    cursor: pointer;
    margin-top: 4px;
  }
  > label > span:nth-child(2) {
    transform: translateY(-10px);
  }
  &-text {
    padding-left: 10px;
    color: var(--color_gray);
    line-height: 19px;
    cursor: pointer;
    > a {
      color: var(--color_black);
      text-decoration: none;
      transition: color .2s;
      &:hover {
        color: var(--color_primary_hover);
      }
    }
  }
}

.quotes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 100%;
  margin-right: 100px;
  padding-top: 70px;
  &-quote {
    font-size: var(--fontSize_18);
    color: var(--color_darkGray);
    line-height: 30px;
    padding-bottom: 100px;
    &:last-child {
      padding-bottom: 0;
    }
    &-text {
      font-style: italic;
      padding-bottom: 40px;
    }
    &-desc {
      font-size: var(--fontSize_tab);
      line-height: 19px;
      color: var(--color_gray);
    }
  }
}
