.wrapper {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 10;
    &._absolute {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.menu {
    position: absolute;
    bottom: -5px;
    right: -5px;
    min-height: fit-content;
    background-color: #fff;
    box-shadow: var(--shadow_alert);
    border-radius: 6px;
    padding: 40px;
    &_btns > *{
      white-space: nowrap;
    }
    &_btns > *:not(:last-child) {
      margin-bottom: 13px;
    }
    &._absolute {
      top: 0;
    }
}
.line {
  height: 1px;
  border: solid 1px var(--color_lightGray);
}
