.screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.wrapper {
    position: absolute;
    top: 0px;
    left: calc(80rem + 40px);
    width: 450px;
    padding-top: 10px;
    height: fit-content;
    background-color: white;
    z-index: 1;
}

.togglesBlock {
    position: fixed;
    top: 110px;
    padding-left: 38px;
    left: 840px;
    width: 100%;
    background-color: white;
    z-index: 5;
}

.toggles {
    background-color: white;
    border-bottom: 1px solid var(--color_lightGray);
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    display: inline-block;
    height: 24px;
    margin-bottom: 18px;
    z-index: 2;
    .toggle {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all .2s;
        user-select: none;
        &:not(:last-child) {
            margin-right: 20px;
        }
        &:hover {
            color: var(--color_primary_hover);
        }
        &._new {
            color: var(--color_primary);
            font-weight: bold;
        }
        &._sel {
            color: var(--color_darkGray);
            &::after {
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -10px;
                left: 0;
                border-radius: 2px 2px 0 0;
                background-color: var(--color_lightGray2);
            }
        }
    }
}

.filters {
    position: relative;
    top: 0;
    left: 20px;
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    display: flex;
    align-items: flex-end;
    > span {
        line-height: 18px;
        margin-right: 10px;
    }
}

.communication {
    margin-top: 40px;
    margin-left: 20px;
}

.messagesList {
    a {
        z-index: 0;
    }
    & > div:first-child {
        margin-top: 0 !important;
        transition: none !important;
    }
}


.viewPopup {
    &_wrapper {
        position: relative;
        margin-left: 40px;
        > button {
            z-index: 6;
        }
    }
    &_subTitle {
        margin-bottom: 5px;
    }
    position: absolute;
    top: -35px;
    right: -40px;
    padding: 75px 40px 40px 40px;
    width: max-content;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: var(--shadow_menu);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    &._vis {
        opacity: 1;
        visibility: visible;
    }
}