.wrapper {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  &.checked {
    .icon-checkbox {
      opacity: 1;
    }
    .fake {
      border-color: var(--color_primary);
      background-color: var(--color_primary);
    }
  }
  &.medium {
    .fake {
      width: 15px;
      min-width: 15px;
      height: 15px;
    }
    .icon-checkbox {
      width: 10px;
      height: 12px;
      left: 2px;
      top: 1px;
    }
    .checkbox-text {
      margin-left: 10px;
      font-size: var(--fontSize_standart);
      transform: translateY(-1px);
    }
  }
  &.small {
    align-items: initial;
    .fake {
      width: 12px;
      min-width: 12px;
      height: 12px;
    }
    .icon-checkbox {
      width: 8px;
      height: 10px;
      left: 1px;
      top: -3px;
    }
    .checkbox-text {
      margin-left: 5px;
      font-size: var(--fontSize_standart);
      transform: none;
    }
  }
  &.gray.checked .fake {
      border-color: var(--color_gray);
      background-color: var(--color_gray);
  }

  & input {
    display: none;
  }
}

.fake {
  width: 15px;
  min-width: 15px;
  height: 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: all .2s;
}

.icon-checkbox {
  width: 10px;
  height: 12px;
  fill: #fff;
  opacity: 0;
  transition: opacity .2s;
  position: relative;
  left: 2px;
}

.checkbox-text {
  margin-left: 10px;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_standart);
  font-weight: 400;
  color: var(--color_darkGray);
  transform: translateY(-1px);
}


