.wrapper {
    margin-left: 20px;
    user-select: none;
    position: relative;
}
.screen {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 12;
    background-color: white;
}
.row {
    display: flex;
    align-items: baseline;
    color: var(--color_darkGray);
    font-size: var(--fontSize_standart);
    &.centered {
        align-items: center;
    }
}

.bordered {
    padding: 2px 10px 2px;
    border-radius: 5px;
    border: solid 1px var(--color_lightGray2);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 100%;
    &.opened {
        border-radius: 5px 5px 0 0;
    }
}
.fontPicker {
    flex-basis: 148px;    
    position:relative;
    height: 19px;
}
.shifted5 {
    top: 7px;
}
.pickColor {
    display: inline-block;
    height: 15px;
    width: 4px;
    border-radius: 2px;    
    margin: 0 4px;
}
.back-picker {
    position: relative;
    top: 2px;
}
.sizePicker {
    flex-basis: 67px;
    margin-left: 10px;
    margin-right: 20px;
    padding: 2px 5px;
    height: 19px;
}

.BIH {
    display: flex;
    align-items: baseline;
}
.sign {
    color: var(--color_primary);
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif !important;
    line-height: 1;
    &.selected {
        color: var(--color_black);
    }    
    &.bold {
      min-width: 20px;
      margin-right: 7px;
    }
    &.italic {
       font-style: italic;
       font-weight: normal;
       font-family: serif !important;
       min-width: 15px;
       margin-right: 5px;
    }
    &.minus {
        position: relative;
        font-family: monospace !important;
        font-weight: normal;
        font-size: var(--fontSize_standart);
    }
}
.clickable {
    cursor: pointer;
}
.svgIcons {
  width: 128px;
  height: 19px;
  margin-right: 20px;
  border-radius: 5px;
  border: solid 1px var(--color_lightGray2);
  display: flex;  
  & > div {
      text-align: center;
      flex-grow: 1;
  }
  & > :not(:last-child) {
      border-right: solid 1px var(--color_lightGray2);
  }
}
.prima {
    color: var(--color_primary);
}

.marg12 {
    margin-top: 12px;
}

.marg10 {
    margin-top: 10px;
}

.marg25 {
    margin-top: 25px;
}

.margl20 {
    margin-left: 20px;
}

.font_popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0 10px 5px;
    background-color: #fff;
    transition: all .2s;
    border-radius: 0 0 5px 5px;
    z-index: 2;
    height: 200px;
    border: solid 1px var(--color_lightGray2);
    border-top: none;
}
.font-line {
    padding: 3px 0;
    &:hover {
        color: var(--color_primary);
    }
}

.highlights {
    position: absolute;
    z-index: 2;
    width: 190px;
    /* height: 100px; */
    box-shadow: var(--shadow_menu);
    background-color: #fff;
    top: 100%;
    right: 0;
    padding: 10px;
    border-radius: 6px;
    font-size: var(--fontSize_frameNormal);
}
.noHighlight {
    color: var(--color_primary);
    line-height: 18px;
    height: 20px;
    text-align: center;
    border: 1px dashed var(--color_primary);
    border-radius: 6px;
    margin-bottom: 10px;
}
.mainHighlight {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    margin-right: 10px;
}
.smallHighlights {
    width: 110px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    & > div {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        cursor: pointer;
    }
    & > div:nth-child(4n) {
        margin-right: 0;
    }
}
.vertical {
    width: 1px;
    background-color: var(--color_lightGray);
    align-self: stretch;
}
.copyBtn {
    width: 14px;
    height: 14px;
    background-image: url('/images/icon-link.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.ptClass {
    color: var(--color_lightGray2);
}

.headingContainer {
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.heading {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    margin-bottom: 2px;
}

.changeIndent {
    flex-basis: 67px;
    width: 67px;
    margin-right: 15px;
    padding: 2px 5px;
}

.stylesBtns {
    margin-left: 20px;
    display: flex;
    & :first-child {
        border-radius: 5px 0 0 5px;
        border-right: 0px;
    }
    & :last-child {
        border-radius: 0 5px 5px 0;
        border-left: 0px;
    }
}

.stylesBtn {
    padding: 2px 10px;
    border: solid 1px var(--color_lightGray2);
    &.active {
        border: solid 1px var(--color_gray);
        background-color: var(--color_gray);
        color: var(--color_white);
    }
    &:hover {
        cursor: pointer;
    }
}

.stylesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 10px;
}

.blurOptions {
    opacity: 0.25;
    cursor: default;
    user-select: none;
    pointer-events: none;
}