.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 40px 0;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
}

.popup-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: 0 auto;
    padding-bottom: 40px;
    width: 400px;

}
.popup {
    border-radius: 20px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-color: #fff;
    color: var(--color_black);
    padding: 40px;
}

.input {
    position: relative;
    margin-bottom: 40px;
    button {
        position: absolute;
        right: 30px;
        top: 14px;
    }
    input {
        font-size: var(--fontSize_tab);
        color: var(--color_darkGray);
        width: 100%;
        padding: 12px 55px 8px 15px;
        border: 1px solid var(--color_lightGray);
    }
    input::placeholder {
        color: var(--color_gray);
    }
    > div {
        top: 8px;
        right: 10px;
    }
}

.candidates {
    margin-top: 40px;
    > div:last-child {
        margin-bottom: 0;
    }
}

.justAdded {
    position: relative;
    padding: 20px;
    border-radius: 6px;
    background-color: #f9f9f9;
    left: -20px;
    width: calc(100% + 40px);
    &.ext {
        background-color: #f3f8ff;
    }
}
