.wrapper {
  position: static;
  label:not(:first-child):not(:last-child) {
    border-left: none;
    border-right: none;
  }
  label:first-child {
    border-radius: var(--btn_borderRadius_primary) 0 0 var(--btn_borderRadius_primary);

  }
  label:last-child {
    border-radius: 0 var(--btn_borderRadius_primary) var(--btn_borderRadius_primary) 0;
  }
  input {
    display: none;
  }
  &._s {
    label:first-child {
      border-right: none;

    }
    label:last-child {
      border-left: none;
    }
  }
}

.blue {
  position: static;
  & .label {
    color: var(--color_primary);
    border-color: var(--color_primary);
    &.current {
      color: var(--color_white);
      background-color: var(--color_primary);
      border-color: var(--color_primary);
    }
  }
}

.label {
  height: 20px;
  font-size: var(--fontSize_standart);
  display: inline-block;
  padding: 3px 10px 1px;
  color: var(--color_darkGray);
  border: 1px solid var(--color_lightGray2);
  transition: all .2s;
  cursor: pointer;
  user-select: none;
  &.current {
    color: var(--color_white);
    background-color: var(--color_gray);
    border-color: var(--color_gray);
  }
  &:hover:not(.current) {
    color: var(--color_primary_hover);
  }
}

