.listWrapper {
    margin-top: 16px;
}
.wrapper {
  margin: 0 0 0 20px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 0;
  background: transparent;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_tab);
  line-height: 1.29;
  letter-spacing: 0;
  text-align: left;  
  & > img {
      margin-right: 10px;
  }
}

.approved {
  position: relative;
  top: 2px;
  color: #7fb069;
}

.progress {
    display: flex;
    flex-direction: column;
    color: var(--color_primary);
    position: relative;
    font-family: var(--fontFamily);
    margin-bottom: 37px;
}

.pending {
    color: var(--color_darkGray);
}

.dropdown-content {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #f1f1f1;    
    z-index: 1;
    top: 45px;
    left: -90px;
    padding: 16px 40px 6px 16px;
    border-radius: 8px;
}

.progress:hover .dropdown-content {
    display: flex;
}

.title {
    font-size: var(--fontSize_h3);
    color: var(--color_black);
    font-weight: bold;
    margin-bottom: 20px;
}

.right {
  position: relative;
  display: flex;
  align-items: center;
  &:not(:last-child) {
      margin-bottom: 10px;
  }
  & > a {
        margin-left: 0 !important;
  }
}

.user-data {
  margin-left: 10px;
  color: var(--color_gray);
  font-size: var(--fontSize_standart);
  font-weight: 400;
}

.user-name {  
  font-size: var(--fontSize_tab);
  color: var(--color_darkGray);
  &._approved {
    color: #4d4d4d;
  }
}

.right:hover .remindBtn {
    opacity: 1;
    visibility: visible;
}

.remindBtn {
    display: none;
    //display: flex;
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    height: 33px;
    transition: all .2s;
    > button {
        height: 16px !important;
    }
}

