.wrapper {
    position: relative;
    display: flex;
    align-items: baseline;
    padding: 0 0 0 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
}

.screen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 3;
}

.statusBlock {
    display: flex;
    align-items: baseline;
}
.buttonBlock {
    position: relative;
    padding: 0 40px;
    width: auto;
    display: flex;
    align-items: baseline;
    button {
        margin: 0;
    }
}
.time {
    height: 14px;
    margin-top: 3px;
    margin-right: 40px;
    padding-left: 22px;
    width: fit-content;
    overflow: hidden;
    color: var(--color_gray);
    white-space: nowrap;
}
.text {
    color: var(--color_green);
    height: 14px;
    padding-right: 40px;
    white-space: nowrap;
    &.bold {
        font-weight: bold;
    }

    &.docuSign_text {
        padding-right: 0;
    }
    &_red {
        width: fit-content;
        margin-right: 40px;
        font-size: var(--fontSize_standart);
        color: var(--color_lightRed);
        height: 14px;
        white-space: nowrap;
    }
}
.checkMark {
    position: relative;
    padding-left: 22px;
    &::after, &::before {
        top: 0px;
        content: '';
        position: absolute;
        left: 7px;
        bottom: 0;
        height: 10px;
        width: 2px;
        background-color: var(--color_green);
        transform: rotate(45deg);
    }
    &::before {
        top: 3px;
        height: 6px;
        transform: rotate(-45deg);
        left: 2px;
    }
    &.bold {
        &::after {
            top: 2px;
        }
        &::before {
            top: 5px;
        }
    }
}

.btnNewBlock {
    position: relative;
    padding-top: 15px;
    height: 100%;
    padding-left: 40px;
    & > button + button {
       transform: translate(-7px, 0); 
    }
}
.btnNewPopup {
    position: absolute;
    top: -20px;
    left: -1px;
    padding: 70px 40px 40px;
    width: 208px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    transition: all .2s;
    > button {
        white-space: normal !important;
        margin-right: 0 !important;
        text-align: left !important;
        line-height: 18px;
        height: fit-content !important;
    }
    &_visible {
        opacity: 1;
        visibility: visible;
    }
}

.approvalButton {
    z-index: 7;
    position: relative;
    background-color: transparent;
    button {
        margin-right: 40px;
    }
}
.approvalButton:hover > div {
    opacity: 1;
    visibility: visible;
    z-index: 2;
}
.approvalButton button {
    z-index: 2;
}
.approvalButton:hover button {
    z-index: 3;
}

.cancelFinalize {
    height: 100%;
    display: flex;
    align-items: center;
}

.docusign_btn {
    position: relative;
    white-space: nowrap;
    margin-right: 40px;
    & > div {
        display: inline-block;
        font-size: var(--fontSize_standart);
    }
    > button, .docuSign_text {
        position: relative;
        z-index: 8;
    }
    &._open {
        .docuSign_popup {
            opacity: 1;
            visibility: visible;
        }
    }
}

.docuSign_popup {
    position: absolute;
    top: -40px;
    left: -40px;
    padding: 95px 40px 40px;
    width: 435px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    z-index: 7;
    transition: all .2s;
}

.signer {
    display: flex;
    width: 355px;
    position: relative;
    & + .signer {
        margin-top: 10px;
        padding-top: 10px;
        &::before {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            width: 315px;
            top: 0;
            left: 40px;
            background-color: var(--color_lightGray);
        }
    }
    &-left {
        display: flex;
        width: 190px;
        margin-right: 40px;
        &_nameBlock {
            margin-left: 15px;
            &_name {
                color: var(--color_darkGray);
                font-size: var(--fontSize_tab);
                font-weight: bold;
            }
            &_mail {
                color: var(--color_gray);
                font-size: var(--fontSize_tip);
            }
        }
    }
    &-right {
        color: var(--color_gray);
        font-size: var(--fontSize_standart);
        &_info {
            margin-bottom: 2px;
            &.red {
                color: var(--color_lightRed);
            }
            &.green {
                color: var(--color_green);
                padding-left: 23px;
                &::after {
                    left: 8px;
                }
                &::before {
                    left: 3px;
                }
            }
        }
        &_date {

        }
    }
}

.margin40 {
    min-width: 40px;
}

.downloadPopup {
    position: relative;
    font-size: var(--fontSize_standart);
    height: 50px;
    white-space: nowrap;
    user-select: none;
    z-index:5;

    &._open {
        .downloadPopup_btn {
            z-index: 6;
            transition-delay: 0s;
        }
    }

    &_btn {
        position: relative;
        z-index: 1;
        transition: z-index 0s .2s;

        .arrowBtn {
            position: relative;
            cursor: pointer;
            display: inline-block;
            width: 20px;
            margin-left: 5px;

            > button {
                transform: translate(-7px, 0);
            }
        }
    }

    &_popup {
        position: absolute;
        top: -35px;
        left: -40px;
        width: 173px;
        padding: 70px 2px 35px 40px;
        background-color: var(--color_white);
        border-radius: 6px;
        z-index: 5;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
        opacity: 0;
        visibility: hidden;
        transition: visibility .2s, opacity .2s;

        &._vis {
            opacity: 1;
            visibility: visible;
        }
    }
}

.initiateDisabled {
    position: relative;
    > div {
        top: 35px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s, visibility .2s;
    }
    > button:hover ~ div {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
}
.signPopupWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 40px 0;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    display: flex;
    align-items: center;
}
.signPopup {
    position: relative;
    margin: auto;
    padding: 40px;
    width: 400px;
    border-radius: 20px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-color: #fff;
    font-size: var(--fontSize_h3);
    color: var(--color_black);
    &_blueText {
        margin-top: 40px;
        display: flex;
        font-size: var(--fontSize_standart);
        color: var(--color_primary);
        transition: color .2s;
        > button {
            margin-left: 5px;
        }
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}

.gray {
    color: var(--color_darkGray);
}
.red {
    color: var(--color_lightRed);
}

.scrollBlock {
    position: relative;
    width: 89px;
    height: 100%;
    .arrow-right, .arrow-left {
        right: -11px;
    }
    &_open {
        z-index: 3;
        .scrollBlockButtons {
            width: 235px;
            &::after {
                display: none;
            }
            &-grad {
                right: -182px;
            }
        }
        .arrow-right, .arrow-left {
            right: -156px;
        }
    }
}
.scrollBlockButtons {
    top: 0;
    left: 1px;
    display: flex;
    align-items: baseline;
    width: 89px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s;
    z-index: 3;
    height: 40px;
    &-grad {
        position: absolute;
        top: 0;
        right: -36px;
        height: 50px;
        width: 35px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        z-index: 3;
        transition: all .2s;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}
.arrow-right, .arrow-left {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #fff;
    right: -12px;
    top: 0px;
    transition: all .2s;
    z-index: 4;
    &::before, &::after {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: #979797;
        top: 3px;
        left: 9px;
        width: 1px;
        height: 8px;
        transform: rotate(-45deg);
    }
    &::after {
        top: 8px;
        transform: rotate(45deg);
    }
}
.arrow-left {
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}
.borderH {
    top: -16px;
    right: 0px;
    position: absolute;
    &.borderH-left {
        right: -146px;
    }
}