.wrapper {
  margin-top: 50px; 
  width: calc(80rem + 440px);
}

.templatesList {
  margin-top: 58px;
  width: 100%;
  font-size: 12px;

  &_head, &_row {
    display: flex;

    > div:nth-child(1) {
      flex-basis: 361px;
      min-width: 250px;
      max-height: 361px
    }

    > div:nth-child(2) {
      flex: 0 0 103px;
    }

    > div:nth-child(3) {
      flex: 0 0 97px;
    }

    > div:nth-child(4) {
      flex: 0 0 114px;
      white-space: nowrap;
    }

    > div:nth-child(5) {
      flex: 0 0 200px;
    }

    > div:not(:nth-child(6)) {
      padding-right: 20px;
    }
    > div:nth-child(6) {
      display: flex;
      justify-content: end;
      flex: 0 0 114px;
      padding-right: 40px;
    }
    
    &:hover .onDocControls {
        opacity: 1;
    }
  }

  &_head {
    border-bottom: 1px solid var(--color_lightGray2);
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: var(--color_gray);
  }

  &_row, &_folderRow {
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color_lightGray2);
    }

    > div:nth-child(1) {
      cursor: pointer;
      transition: all .2s;

      &:hover {
        color: var(--color_primary_hover);
      }
    }
  }

  &_row > div:nth-child(1), &_folderRow > div:nth-child(1) {
    font-size: 14px;
    color: var(--color_darkGray)
  }

  &_folderRow {
    &_title {
      cursor: pointer;
      width: fit-content;
    }

    &_docList {
      > div:last-child {
        padding-bottom: 0;
      }
    }

    .templatesList_row {
      margin-left: 40px;

      > div:nth-child(1) {
        flex-basis: 410px;
      }
    }
  }
}

._leftArrow {
  position: relative;
  padding-right: 24px;

  &::before, &::after {
    content: '';
    border-radius: 1px;
    position: absolute;
    background-color: #979797;
    top: calc(50% - 2px);
    right: 10px;
    width: 1px;
    height: 8px;
    transform: rotate(-50deg);
  }

  &::after {
    right: 4px;
    transform: rotate(50deg);
  }

  &_open {
    &::before, &::after {
      transform: rotate(50deg);
      top: calc(50% - 2px);
    }

    &::after {
      transform: rotate(-50deg);
    }
  }
}

.folderIcon {
  image-rendering: -webkit-optimize-contrast;
  transform: translateY(1px);
  margin-right: 27px;
  width: 14px;
  height: 12px;
}

.docIcon {
  transform: translateY(1px);
  margin-right: 30px;
  min-width: 11px;
  min-height: 13px;
}

.doc_title {
  position: relative; /*
  white-space: nowrap;
  overflow: hidden;
  height: 17px; */
  overflow-wrap: anywhere;
  &:hover + .doc_title_hint{
    opacity: 1;
    visibility: visible;
  }
  &_hint {
    position: absolute;
    left: 31px;
    top: -10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: var(--shadow_alert);
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
  &._cut {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 100%;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    }
  }
}

.doc_description {
  margin-left: 40px;
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
}

.popup {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 40px 0;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    display: flex;
    align-items: center;
  }

  &_wrapper {
    position: relative;
    margin: auto;
    padding: 40px;
    box-shadow: var(--shadow_menu);
    border-radius: 10px;
    background-color: white;
  }

  &_wrapper .dropzone {
    width: 400px;
    height: 273px;
    padding: 40px;
    border-radius: 10px;
    border: 1px dashed var(--color_lightGray2);
    margin-bottom: 40px;
    cursor: pointer;

    &_title {
      font-size: var(--fontSize_h2);
      color: var(--color_black);
      font-weight: bold;
      margin-bottom: 20px;
    }

    &_text {
      font-size: 18px;
      white-space: nowrap;
      color: var(--color_gray);
      text-align: center;
    }

    .docList {
      color: var(--color_darkGray);
      font-size: var(--fontSize_tab);
      width: fit-content;

      &-item {
        width: fit-content;
        position: relative;
        transition: color .2s;
        padding-right: 20px;
        margin-bottom: 20px;
        word-break: break-word;
        > button {
          display: block;
          margin-top: 6px;
          margin-left: 41px;
        }
        > div:first-child {
          display: flex;
          max-width: 300px;
        }
        .deleteBtn {
          top: -3px;
          right: 0;
        }

        /*&:hover {
          color: var(--color_primary_hover);
        }*/
      }
    }
  }
}

.popup_wrapper .UP_dropzone {
  width: 360px;
  height: 200px;
  margin-top: 40px;
  transition: border .1s ease-out, color .1s ease-out, background-color .1s;
  & path {
    transition: stroke .1s ease-out;
  }
  &.active, &:hover {
    color: var(--color_black);
    & path {
      stroke: var(--color_black);
    }
  }
  &.active {
    border: 1px dashed var(--color_primary);
  }  
}

.UP_docList {
  margin: 40px 0 20px;
  &_item {
    position: relative;
    > .docIcon {
      position: absolute;
    }
    > span {
      color: var(--color_darkGray);
      font-size: 14px;
      transition: all .2s;
      display: inline-block;
      margin-left: 41px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:hover > span {
      color: var(--color_primary_hover);
    }
  }
}

.UP_rename > div {
  left: -20px;
  width: 320px;
}

.ATP_fakeMask {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 400px;
    height: 273px;
    cursor: pointer;
    &._hide {
        display: none;
    }
}

.editBtn {
  position: relative;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  top: 5px;
  margin-left: 20px;
  cursor: pointer;
  z-index: 1;
  transition: z-index 0s .2s;

  & span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #606060;
    display: inline-block;

    &:not(:first-child):not(:last-child) {
      margin: 3px 0;
    }
  }
  &._open {
    z-index: 10;
    transition: z-index 0s 0s;
  }
}

.pinIcon {
  width: 22px;
  height: 26px;
  padding: 3px 5px 7px;
  cursor: pointer;
}

.renamePopup, .settingsPopup {
  position: absolute;
  padding: 20px;
  width: 360px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: var(--shadow_menu);
  cursor: default;
  z-index: 9;
  &_input {
    margin-bottom: 20px;
    font-size: 14px;
    color: var(--color_darkGray);
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid var(--color_lightGray);
    width: 100%;
  }
}
.popupBottom {
  display: flex;
  align-items: baseline;
}
.settingsPopup {
  top: -35px;
  right: -35px;
  padding: 30px 60px 30px 30px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  > button {
    height: fit-content;
    padding: 10px;
    margin-right: 0;
  }
  &._open {
    opacity: 1;
    visibility: visible;
  }
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8;
}

.spinwrapper {
    width: 100%;
    transform: translateY(25px);
    height: 100%;
    position: absolute;
    z-index: 2;
}

.orangeStop {
    margin-bottom: 40px;
    color: var(--color_orange);
    width: 400px;
    font-height: 14px;
    & div {
        color: var(--color_darkGray);
        margin-top: 10px;
    }
}

.hinted {
    display: inline-block;
    width: 102px;
    margin-right: 10px;
    position: relative;
    white-space: nowrap;
    & > div {
            bottom: 27px;
            left: 0px;
            visibility: hidden;
            opacity: 0;
        }
        &:hover > div {
            visibility: visible;
            opacity: 1;
            transition: opacity .2s .5s, visibility .2s .5s;
        }
}

.onDocControls {
    position: relative;
    opacity: 0;
    padding-left: 35px;
    flex: 1 1 auto;
}
.arrowShift  {
  left: 10px;
  top: 3px;
  cursor: pointer;
}

.drop-down {
  top: 0px;
  left: -40px;
  position: absolute;
  z-index:6;  
  cursor: initial;
  &.hidden {
    display: none;
  }
}
.folderLine {
  line-height: 20px;
  cursor: pointer;
  &:hover {
      color: var(--color_primary_hover);
  }
  &.selected-folder {
      color: var(--color_black);
      font-weight: bold;
  }
}

.folderShow {
    font-size: var(--fontSize_button);
}
.folderClick {
    font-size: var(--fontSize_button);
    color: var(--color_primary);
    cursor: pointer;
    position: relative;    
}

.unvisible {
   display: none;
}