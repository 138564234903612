.wrapper {
    display: flex;
    min-height: 100vh;
  }

  .inner {
    margin: auto;
  }

  .title {
    margin: 0 0 50px;
    color: var(--color_black);
    font-family: var(--fontFamily);
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    &._success{
      font-size: var(--fontSize_h1);
      margin: 0 0 10px;
    }
  }

  .slog {
    margin: 12px 0 84px 0;
    color: var(--color_black);
    font-family: var(--fontFamily);
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    opacity: 0.5;
    letter-spacing: 0.6px;
    width: 585px;
    &._success{
      margin: 0 auto;
      text-align: center;
    }
  }

  .form-inner {
    margin: 0 auto;
    padding: 15px 25px;
    width: 460px;
    border-radius: 25px;
    box-shadow: 0 50px 100px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .row {
    position: relative;
    padding: 20px 0 16px;
    display: block;
    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        height: 1px;
        background: var(--color_lightGray2);
        bottom: 0;
        left: 0;
        right: 0;
        width: 98%;
        margin: auto;
      }
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  .error-message {
    color: var(--color_lightRed);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_standart);
    padding-left: 5px;
  }

  .input {
    display: block;
    width: 100%;
    color: #000;
    font-family: var(--fontFamily);
    font-size: var(--fontSize_tab);
    font-weight: 600;
    border: 0;
    padding: 7px 10px 12px 5px;
    &::-webkit-input-placeholder {
      color: var(--color_darkGray);
      font-size: var(--fontSize_tab);
      font-weight: normal;
    }
  }

  .disabled-input {
    display: block;
    background-color: #fff;
    width: 100%;
    color: var(--color_gray);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_tab);
    font-weight: 600;
    border: 0;
    padding: 7px 10px 12px 5px;
  }

  .forgot-password {
    position: absolute;
    top: 26px;
    right: 5px;
    color: var(--color_primary);
    font-family: var(--fontFamily);
    font-size: 15px;
    font-weight: 400;
    text-decoration: underline;
  }

  .message-wrapper {
    padding-top: 40px;
    text-align: center;
    color: var(--color_lightRed);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_h3);
    font-weight: 400;
  }

  .button-row {
    padding-top: 40px;
    text-align: center;
  }

 .link {
    padding-top: 20px;
    text-align: center;
    display: block;
    margin: 0;
    color: var(--color_primary);
    font-weight: 600;
    text-decoration: none;
    font-family: var(--fontFamily);
    font-size: 15px;
    transition: color 0.2s;
    &:hover{
      color: var(--color_primary_hover);
    }
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
  }


