.tabs-block {
    position: relative;
    width: calc(100% + 80px);
    padding: 0 40px;
    left: -40px;
    border-bottom: 1px solid var(--color_lightGray2);
    margin-bottom: 40px;
}

.tabs {
    display: inline-block;
    margin-right: 40px;
    padding-bottom: 15px;
    font-size: var(--fontSize_tab);
    line-height: 1.36;
    letter-spacing: 0.01px;
    color: #606060;
    cursor: pointer;
    user-select: none;
    &._isSelected {
        border-bottom: solid 5px var(--color_lightGray2);
    }
}

.columns {
    display: flex;
    justify-content: space-between;
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    &_time {

    }
    &_general {
        color: #606060;
        width: 360px;
    }
}

.controls {
    display: flex;
    > button {
        font-size: var(--fontSize_standart);
        color: var(--color_primary);
        border: none;
        border-bottom: 1px dashed var(--color_primary);
        margin: 0 15px 1px 5px;
        padding: 4px 0 3px 0;
        background-color: #fff;
        cursor: pointer;
    }
    > button._active {
        color: var(--color_darkGray);
        background-color: rgba(222, 222, 222, 0.5);
        border: none;
        cursor: default;
        margin: 0 10px 0 0;
        padding: 4px 5px 3px 5px;
        border-radius: 4px;
    }
}

.userList {
    margin-top: 40px;
    margin-bottom: 40px;
}

.userListItem {
    display: flex;
    margin-bottom: 40px;
    > div:first-child {
        width: 204px;
        padding-right: 20px;
    }
    label {
        > div:first-child {
            display: none;
        }
        > div:last-child > a {
            margin-left: 0;
        }
    }
}

.timeCounter {
    display: flex;
    flex-direction: column;
}

.detailStats {
    width: 601px;
    margin-left: 40px;
    .timeCounter {
        position: relative;
        display: inline-block;
        width: 200px;
        margin-right: 0;
    }
}

.readingLine, .commentingLine, .editingLine {
    margin-top: 5px;
    width: 80px;
    height: 2px;
}
.readingLine {
    background-color: var(--color_lightGray2);
    left: 0;
}
.commentingLine {
    background-color: var(--color_green);
    left: 0;
}
.editingLine {
    background-color: var(--color_lightRed);
    left: 0;
}

.counterPartStats {
    padding-left: 40px;
    display: flex;
    align-items: flex-end;
    > div:first-child {
        width: 164px;
    }
}

.sideControls {
    position: relative;
    top: 1px;
    width: 272px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid var(--color_lightGray2);
    margin-left: 25px;
    overflow: hidden;
    button {
        position: relative;
        top: -2px;
        cursor: pointer;
        font-size: 11px;
        padding: 0;
        width: 90px;
        height: 26px;
        text-align: center;
        border: none;
        color: var(--color_gray);
        background-color: #fff;
    }
    button._active {
        cursor: default;
        background-color: var(--color_lightGray2);
        color: #fff;
    }
}

.flex {
    display: flex;
}
.small {
    font-size: var(--fontSize_tip);
}
.gray {
    color: var(--color_gray);
}
.green {
    color: var(--color_green);
}
.red {
    color: var(--color_lightRed);
}
