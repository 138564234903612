.flexRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.changeIndent {
    flex-basis: 67px;
    width: 67px;
    height: 19px;
    margin-right: 15px;
    padding: 2px 5px;
    &.cm {
        flex-basis: 75px;
        width: 75px;
    }
}

.bordered {
    /* padding: 2px 10px 2px; Посмотреть почему тут так происходит???? */
    border-radius: 5px;
    border: solid 1px var(--color_lightGray2);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    &.opened {
        border-radius: 5px 5px 0 0;
    }
}

.sign {
    color: var(--color_primary);
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif !important;
    line-height: 1;
    &.selected {
        color: var(--color_black);
    }    
    &.bold {
      min-width: 20px;
      margin-right: 7px;
    }
    &.italic {
       font-style: italic;
       font-weight: normal;
       font-family: serif !important;
       min-width: 15px;
       margin-right: 5px;
    }
    &.minus {
        position: relative;
        font-family: monospace !important;
        font-size: var(--fontSize_standart);
        font-weight: normal;
        margin-right: 5px;
        &.plus {
            margin-right: 0px;
            margin-left: 5px;
        }
    }
}

.clickable {
    cursor: pointer;
}

.ptClass {
    color: var(--color_lightGray2);
}