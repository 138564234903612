.form {
    margin: 25px 0 20px 0;
    font-family: var(--fontFamily);
    text-align: left;
    &-title {
      margin-bottom: 16px;
      font-weight: bold;
      line-height: 20px;
      margin-top: 20px;
    }
    &-email{
      margin-bottom: 20px;
      margin-right: 22px;
    }
  }
  
  .left {
     display: flex; 
  }
  
  .error-message {
    text-align: left;
    color: var(--color_lightRed);
    font-size: var(--fontSize_standart);
    line-height: 19px;
  }
  
  .input {
    font-size: var(--fontSize_tab);
    font-family: var(--fontFamily);
    text-align: left;
    width: 100%;
    background-color: #fff;
    border: none;
    &-area{
      padding: 7.5px 15px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      font-size: var(--fontSize_tab);
      font-family: var(--fontFamily);
      text-align: left;
      /* margin-bottom: 20px; */
      width: 100%;
      background-color: #fff;
      position: relative;
      z-index: 2;
    }
  }
  

  .close-button{
    position: absolute;
    top: 25px;
    right: 25px;
    box-sizing: content-box;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    background: var(--color_lightGray2);
    cursor: pointer;
    &:before{
      display: inline-block;
      box-sizing: content-box;
      width: 15px;
      height: 2px;
      position: absolute;
      content: "";
      top: 16.2px;
      left: 9.5px;
      border: none;
      text-overflow: clip;
      background: #ffffff;
      transform: rotateZ(45deg);
    }
    &:after{
      display: inline-block;
      box-sizing: content-box;
      width: 15px;
      height: 2px;
      position: absolute;
      content: "";
      top: 16.2px;
      left: 9.5px;
      border: none;
      text-overflow: clip;
      background: #ffffff;
      text-shadow: none;
      transform: rotateZ(-45deg);
    }
    &:hover {
      background: #ababab;
    }
  }

  .email{
    &__list{
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      z-index: 1;
      border-radius: 4px;
      border: solid 1px #ffffff;
      -webkit-background-clip: padding-box; 
      -moz-background-clip: padding; 
      background-clip: padding-box;
      overflow: hidden;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
      &._open{
        border: solid 1px #d7d7d7;
      }
      &_wp{
        position: relative;
      }
    }
    &__item{
      height: 52px;
      width: 100%;
      display: flex;
      padding: 10px 13px;
      text-align: left;
      border-bottom: 1px solid #eee;
      &:nth-of-type(even){
        background: #ffffff;
      }
      &:nth-of-type(odd){
        background-color: #f5f5f5;
      }
      &:last-of-type{
        border-bottom: none;
      }
    }
  }

  .user-name {
    color: #4d4d4d;
    font-family: var(--fontFamily);
    font-size: var(--fontSize_tab);
    font-weight: 400;
  }
  
  .user-email {
    color: #9c9c9c;
    font-family: var(--fontFamily);
    font-size: var(--fontSize_standart);
    font-weight: 400;
  }

  .user-data {
    margin-left: 10px;
    flex: 1;
  
    & > *:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  
.names {
  display: flex;
  margin-top: 15px;
}

.half {
  margin-right: 10px;
}

.inputStyle {
   border: none !important;
   cursor: text;
   font-family: var(--fontFamily);
}

.hoverStyle {
   display:none;
}

.inputStyle:hover + .hoverStyle, .hoverStyle:hover {
  display: inline-block;  
  font-size: var(--fontSize_standart);
  font-weight: normal;
  color: var(--color_darkRed);
  padding-left: 20px;
  font-family: var(--fontFamily);
  cursor: pointer;
}