.wrapper {
    position: relative;
    &:not(:last-child):not(.noBottomBorder) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 40px);
            height: 1px;
            background-color: var(--color_lightGray);
        }
    }
}

.wrapper_user {
    position: relative;
    display: flex;
    font-size: var(--fontSize_standart);
    margin-bottom: 10px;
    > div:first-child {
        margin: 0;
    }
    .right {
        position: relative;
        width: 111px;
    }
    .approved {
        color: var(--color_darkGray);
        width: 118px;
        > div {
            display: inline;
            white-space: normal;
        }
        > img {
            width: 11px;
            height: 11px;
            margin-right: 10px;
            transform: translateY(1px);
        }
    }
    .notApproved {
        margin-bottom: 10px;
    }
    .notApproved, .time {
        color: var(--color_gray);
    }
    .text {
        position: relative;
        color: var(--color_darkGray);
    }
    .time:not(._wrap) > span {
        white-space: nowrap;
    }
    .stats_arrow {
        position: absolute;
        top: 8px;
        right: -34px;
        width: 20px;
        height: 13px;
        cursor: pointer;
        > button {
            transform: translate(-7px, -3px);
        }
    }
    .stats {
        margin-top: 10px;
        .stat {
            white-space: normal;
            margin-bottom: 10px;
        }
        .stat:last-child {
            margin-bottom: 0;
        }
    }

}
