.wrapper {
  position: relative;
  margin: auto;
  padding: 40px;
  width: 400px;
  border-radius: 20px;
  box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-color: #fff;
}

.text {
  font-family: var(--fontFamily);
  font-size: var(--fontSize_h3);
  text-align: left;
  line-height: unset;
  color: var(--color_black);
  font-weight: normal;
  padding-right: 40px;
  word-break: break-word;
  &.notification {
    margin-top: 20px;
  }
}

.header {
  font-family: var(--fontFamily);
  font-size: var(--fontSize_h2);
  padding-right: 20px;
  font-weight: bold;
  text-align: left;
  line-height: 1.4;
  color: var(--color_black);
  margin-bottom: 40px;
}

.question {
  font-family: var(--fontFamily);
  font-size: var(--fontSize_h3);
  font-weight: bold;
  text-align: left;
  line-height: 1.4;
  color: var(--color_black);
  padding-right: 20px;
  margin-bottom: -20px;
  margin-top: 20px;
}

.buttons-row {
  margin-top: 38px;
  padding: 0;
  display: flex;
  justify-content: space-between;

  &._single-btn {
     justify-content: center;
   }
  .button {
    min-width: 149px;
  }
  &._no-btns {
    margin-top: 0;
  }
}

.popup-close {
    font-size: var(--fontSize_bigPopup);
    position: absolute;
    cursor: pointer;
    right: 38px;
    top: 28px;
    transform: rotate(45deg);
    color: #d8d8d8;
}

.coverMessage {
  position: relative;
  display: flex;
  &_wrapper {
    width: 320px;
    padding: 20px;
    background-color: #f3f8ff;
    font-size: var(--fontSize_standart);
    margin-top: 20px;
    margin-bottom: 40px;
    border-radius: 8px;
    &_top {
      margin-bottom: 20px;
    }
    .coverMessage_title {
      font-size: var(--fontSize_tab);
      color: var(--color_darkGray);
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: -5px;
    }
  }
  & > a:first-child {
    margin-right: 15px;
  }
  .mainPart {
    width: 100%;
    margin-left: 4px;

    .head {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      margin-bottom: 5px;
      > div {
        margin-right: 10px;
      }
      .userName {
        color: var(--color_darkGray);
        font-weight: bold;
      }
      .gray {
        color: var(--color_gray);
      }
    }

    .content {
      font-size: var(--fontSize_tab);
      color: var(--color_darkGray);
      line-height: 19px;
      white-space: normal;
      word-break: break-word;
    }
  }
}

.dropzone {
    position: relative;
    width: 320px;
    height: fit-content;
    min-height: 242px;
    border-radius: 10px;
    border: 1px dashed var(--color_lightGray2);
    padding: 40px 40px 175px;
    margin-top: 40px;
    transition: border .2s ease-out, color .2s ease-out, background-color .2s;
    cursor: pointer;
    &-title {
        font-size: var(--fontSize_h2);
        color: var(--color_black);
        font-weight: bold;
        margin-bottom: 20px;
    }
    &:hover {
        border: 1px dashed var(--color_primary);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    & path {
        transition: stroke .2s ease-out;
    }
    .plus {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: var(--fontSize_18);
        color: var(--color_gray);
        text-align: center;
        .plus-sign-icon {
            width: 25%;
            margin: 20px auto 16px;
            display: block;
        }
    }

}


