.deleted, .resolved {
    color: var(--color_gray);
    font-size: var(--fontSize_tip);
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    &.resolved:last-child {
        margin-top: -10px;
    }
    &.deleted:last-child {
        margin-top: -4px;
    }
}
.ml40 {
    margin-left: 40px;
}
.wrapper {
    position: relative;
    width: 320px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    font-size: var(--fontSize_standart);
    transition: background-color .2s;
    &.ext {
        background-color: #f3f8ff;
    }
    &.freshExt {
        background-color: #E5EFFD;
        .repliesList > div:last-child {
            padding-bottom: 0;
        }
    }
    &.private {
        background-color: white;
        border: 1px solid var(--color_lightGray);
    }
    &.clps_hov {
        transition: margin-top .2s .5s !important;
    }
    &.clps:not(.unclps) {
        position: relative;
        max-height: 130px;
        overflow: hidden;
        text-overflow: fade;
        box-shadow: var(--shadow_menu);
        &.side, & > div:first-child, & > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) button, & .pinImg, & .popup_wrapper {
            display: none;
        }
        .title {
            margin-top: -5px;
        }
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0);
            background-image: linear-gradient(to top, #f9f9f9 17px, rgba(249, 249, 249, 0));
            transition: opacity .1s;
        }
        &.ext::after {
            background-image: linear-gradient(to top, #f3f8ff 17px, rgba(249, 249, 249, 0))
        }
        &.freshExt::after {
            background-image: linear-gradient(to top, #E5EFFD 17px, rgba(249, 249, 249, 0))
        }
    }
    &.unclps {
        margin-top: 0 !important;
        max-height: none !important;
        transition: all .2s !important;
        & + div {
            margin-top: 0 !important;
            transition: all .2s !important;
        }
    }
    &:hover {
        .popup_showBtn {
            opacity: 1;
            visibility: visible;
        }
        .hideBtn {
            opacity: 1;
            visibility: visible;
        }
        .pinImg {
            opacity: 1;
            visibility: visible;
        }
    }
}

.side {
    position: relative;
    font-size: var(--fontSize_tip);
    color: var(--color_gray);
    width: fit-content;
    padding-left: 15px;
    background: url(/images/icon-lock.svg) no-repeat 0 1px / 6px 8px;
    margin-bottom: 20px;
    &.ext {
        padding-left: 0;
        background: none;
    }
    &:hover {
        .side-tip > div {
            transition: opacity .2s .5s, visibility .2s .5s;
            opacity: 1;
            visibility: visible;
        }
    }
}
.side-tip > div {
    top: auto;
    bottom: 25px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
}

.sideToggles-wrapper {
    position: relative;
    margin-top: 10px;
    margin-left: 52px;
    &._open {
        .sideToggles-popupBtn_arrow {
            z-index: 6;
            transition: z-index .0s .0s;
        }
    }
}
.sideToggles-popupBtn {
    color: var(--color_gray);
    width: fit-content;
    cursor: pointer;
    &_arrow {
        position: relative;
        display: inline-block;
        z-index: 1;
        transition: z-index .0s .2s;
        > button {
            transform: translate(-7px, -6px);
        }
    }
}
.sideToggle-popup {
    position: absolute;
    top: -20px;
    left: -16px;
    border-radius: 10px;
    box-shadow: var(--shadow_menu);
    padding: 20px 66px 20px 40px;
    background-color: #ffffff;
    opacity: 0;
    z-index: 5;
    visibility: hidden;
    transition: all .2s;
    &_toggles {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        z-index: 2;
    }
    &_btn {
        position: relative;
        margin-bottom: 20px;
        width: fit-content;
        cursor: pointer;
        &._sel {
            font-weight: bold;
            transform: translateY(2px);
            order: -1;
        }
        &.int {
            color: var(--color_gray);
        }
        &.ext {
            color: var(--color_darkRed);
        }
        &:hover &_tip > div {
                opacity: 1;
                visibility: visible;
                transition: opacity .2s .5s, visibility .2s .5s;
        }
        &_tip > div {
            top: auto;
            bottom: 25px;
            font-weight: normal;
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s, visibility .2s;
        }
    }
    &._vis {
        opacity: 1;
        visibility: visible;
    }
}


.title {
    display: block;
    margin: -15px 0 15px;
    font-weight: bold;
    width: 100%;
    color: var(--color_darkGray);
    font-size: var(--fontSize_tab);
    cursor: text;
    border: none;
    background-color: transparent;
    font-family: var(--fontFamily);
    &::placeholder {
        color: var(--color_gray);
        font-weight: normal;
    }
}

.tipElement:not(:last-child) {
    margin-bottom: 10px;
}

.repliesList {
    > div {
        padding-top: 15px;
        padding-left: 49px;
    }
}

.popup_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    &._open {
        .popup, .popup_showBtn {
            opacity: 1;
            visibility: visible;
        }
        .popup_showBtn {
            z-index: 3;
            transition: opacity .2s, visibility .2s, z-index .0s .0s;
        }
    }
}

.popup_showBtn {
    position: absolute;
    top: 20px;
    right: 15px;
    border: none;
    padding: 2px 5px 1px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    background: transparent;
    cursor: pointer;
    transition: opacity .2s, visibility .2s, z-index .0s .2s;
    > span:not(:last-child) {
        margin-bottom: 2px;
    }
    > span {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--color_darkGray);
        display: inline-block;
    }
}

.popup {
    position: absolute;
    top: -58px;
    right: 0;
    width: fit-content;
    width: -moz-fit-content;
    margin: 58px 0 691px 20px;
    padding: 20px 66px 20px 20px;
    border-radius: 10px;
    box-shadow: var(--shadow_menu);
    background-color: #ffffff;
    transition: opacity .2s, visibility .2s;
    opacity: 0;
    visibility: hidden;
    box-sizing: content-box;
    z-index: 2;
    > button {
        display: block;
        height: 15px;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.pinImg {
    position: absolute;
    right: 42px;
    top: 22px;
    width: 12px;
    height: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    cursor: pointer;
}

.recipients {
    color: var(--color_gray);
    margin-top: 10px;
    margin-left: 45px;
}

.recipientsPopup {
    position: absolute;
}

.hideBtn {
    position: absolute;
    width: fit-content;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
}
