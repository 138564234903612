.wrapper,.wrapper label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 30px;
    &._disabled {
        opacity: 0.5;
    }
}

.left {
    min-width: 25px;
    div > span svg {
        top: -3px;
    }
}

.right {
    flex: 1;
    display: flex;
    align-items: center;
}

.user-data {

}

.user_hintPlace {
    position:absolute;
    left: 100px;
    width: 0;
    height: 0;
    top: 8px;
    opacity: 0;
    transition-property: display;
    transition: all .3s;
    visibility: hidden;
    z-index: 10;
}
.user_hintPlace div {
    width: auto;
}
.user_hintPlace div::before {
    border: none;
}
.user-data:hover .user_hintPlace {
    visibility: visible;
    opacity: 1;
}
.user_hintPlace-lastEmail {
    height: fit-content;
    top: 2px;
    white-space: nowrap;
    div::before {
        bottom: -8px;
        left: 40px;
        border-left: 6px solid transparent;
        border-top: 8px solid var(--color_darkGray);
        border-right: 6px solid transparent;
    }
}

.user-name {
    color: #4d4d4d;
    font-family: var(--fontFamily);
    font-size: var(--fontSize_tab);
    font-weight: 400;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-email {
    color: #9c9c9c;
    font-family: var(--fontFamily);
    font-size: var(--fontSize_tip);
    font-weight: 400;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    &_red {
        color: var(--color_darkRed);
    }
}

.button-prove {
    margin-right: 12px;
    margin-bottom: -8px;
    cursor: pointer;
    width: 58px;
    height: 38px;
    background-image: url(/images/off.png);
    background-position-x: -6px;
}
.shifted {
    background-image: url(/images/on.png);
    background-position-x: 0;
}
.user-company {
    font-size: var(--fontSize_tab);
    font-weight: bold;
    color: var(--color_darkGray);
}

.SU_check {
    width: 12px;
    height: 12px;
    margin: 2px -2px 0 5px;
    cursor: pointer;
    position: relative;
}
.SU_buttons {
    display: flex;
    position: absolute;
    top: 9px;
    right: 0;
}
.SU_buttons div {
    margin-right: 8px;
}
.SU_buttons img {
    height: 16px;
    width: 16px;
}
.SU_hintPlace {
    position:absolute;
    left: 60%;
    width: 0;
    height: 0;
    top: 0;
    opacity: 0;
    transition-property: display;
    transition: all .3s;
    visibility: hidden;
}
.SU_check img:hover ~ .SU_hintPlace {
    visibility: visible;
    opacity: 1;
}
.wrapper:hover .SU_remindBtn {
    visibility: visible;
    opacity: 1;
}
.SU_remindBtn {
    display: none;
    //display: flex;
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    height: 29px;
    transition: all .2s;
    > button {
        height: 16px !important;
    }
}

.SU_admin {
    position: absolute;
    right: 30px;
    top: 14px;
    color: var(--color_gray);
    font-size: 9px;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: all .2s;
    z-index: 1;
    &._active {
        visibility: visible;
        opacity: 1;
        color: var(--color_primary);
        &:hover {
            color: var(--color_primary_hover);
        }
    }
    &._untouchable {
        cursor: default;
        transition-delay: 999999s;
    }
}
.SU_admin:hover .SU_admin_hintPlace > div {
    opacity: 1;
    visibility: visible;
    transition-delay: .5s;
}
.SU_admin_hintPlace > div {
    opacity: 0;
    visibility: hidden;
    left: -50px;
    transition: all .2s;
}
.wrapper:hover .SU_admin {
    opacity: 1;
    visibility: visible;
}


.left div > span svg{
    top: -1px;
}
