.screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 11;
}
.scroll {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px 0;
    overflow: auto;
    z-index: 12;
}
.wrapper {
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 30px;
    box-shadow: var(--shadow_menu);
    width: 1240px;
}

.tabs {
    position: relative;
    width: 100%;
    padding: 0 40px;
    border-bottom: 1px solid var(--color_lightGray2);
    margin-bottom: 20px;
    &_tab {
        display: inline-block;
        margin-right: 40px;
        padding-bottom: 15px;
        font-size: var(--fontSize_tab);
        line-height: 1.36;
        letter-spacing: 0.01px;
        color: #606060;
        cursor: pointer;
        user-select: none;
        > a {
            text-decoration: none;
            color: var(--color_primary);
            transition: color .2s;
            &:hover {
                color: var(--color_primary_hover);
            }
        }
        &.tg {
            color: var(--color_primary);
            & img {
                margin-left: 8px;
            }
        }
        &._sel {
            border-bottom: solid 5px var(--color_lightGray2);
        }
    }
}

.controls {
    display: flex;
    margin-left: 40px;
    > button {
        font-size: var(--fontSize_standart);
        color: var(--color_primary);
        border: none;
        border-bottom: 1px dashed var(--color_primary);
        margin: 0 15px 1px 5px;
        padding: 4px 0 3px 0;
        background-color: #fff;
        cursor: pointer;
    }
    > button._active {
        color: var(--color_darkGray);
        background-color: rgba(222, 222, 222, 0.5);
        border: none;
        cursor: default;
        margin: 0 10px 0 0;
        padding: 4px 5px 3px 5px;
        border-radius: 4px;
    }
}

.slider {
    overflow: hidden;
    display: flex;
    padding-top: 50px;
    padding-left: 40px;
    padding-bottom: 40px;
    &_item {
        min-width: 800px;
        height: fit-content;
        margin-right: 40px;
        transition: opacity .2s;
        opacity: 0.6;
        cursor: pointer;
        &:last-child {
            min-width: 840px;
            margin-right: 0;
            padding-right: 40px;
        }
        &._active {
            opacity: 1;
            cursor: default;
        }
        &_image {
            box-shadow: var(--shadow_menu);
            height: 450px;
            & > img {
                width: 800px;
                height: 450px;
            }
        }
        &_text {
            padding-top: 20px;
            font-size: var(--fontSize_tab);
            line-height: 19px;
            color: var(--color_darkGray);
            max-width: 200px;
        }
    }
}

.video {
    padding: 20px 40px 40px;
}

._CHROME_correction {
    .slider {
        img {
            image-rendering: -webkit-optimize-contrast;
        }
    }
}