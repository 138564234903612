.wrapper {
    margin: 0 auto;
    width: 750px;
    padding-top: 160px;
    color: var(--color_black);
    min-height: 745px;
}

.title {
    position: relative;
    font-size: 50px;
    font-weight: 700;
    > img {
        position: absolute;
        top: 20px;
        bottom: 0;
        left: -70px;
        width: 20px;
        height: 25px;
    }
}

.selection-cols {
    display: flex;
    padding-top: 100px;
    &-col {
        width: 210px;
        margin-right: 100px;
        display: flex;
        flex-direction: column;
        &-head {
            font-size: var(--fontSize_h2);
            padding-bottom: 20px;
            font-weight: bold;
            line-height: 40px;
        }
        &-link > a {
                display: block;
                line-height: 36px;
                text-decoration: none;
                color: var(--color_primary);
                font-size: var(--fontSize_18);
                padding-bottom: 20px;
                transition: color .2s;
                &:hover {
                    color: var(--color_primary_hover);
                }
        }
    }
}

.selection {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;

    &-link {
        > a {
            display: block;
            width: 150px;
            text-decoration: none;
            font-size: var(--fontSize_18);
            color: var(--color_primary);
            transition: color .2s;
            &:hover {
                color: var(--color_primary_hover);
            }
        }

    }
}

.version {
    color: var(--color_gray);
    line-height: 19px;
    padding-top: 20px;
}

.subTitle, .subTitle2 {
    position: relative;
    font-size: var(--fontSize_h1);
    font-weight: 700;
    line-height: 40px;
    padding-top: 100px;
    padding-bottom: 40px;
    padding-left: 76px;

    &-desc {
        font-size: var(--fontSize_18);
        line-height: 36px;
        padding-bottom: 40px;
    }

    > span {
        position: absolute;
        display: block;
        top: 113px;
        left: 0;
        color: var(--color_gray);
        font-size: var(--fontSize_tab);
        font-weight: normal;
        line-height: normal;
    }
}

.subTitle2 {
    font-size: var(--fontSize_h2);
    padding-top: 0;

    > span {
        top: 9px;
    }
}

.section {
    .paragraph:last-child, .paragraph-col:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
    }
    .section .paragraph:last-child, .paragraph-col:last-child {
        padding-bottom: 20px;
        margin-bottom: 0;
        border: none;
    }
}

.paragraph, .paragraph-col {
    position: relative;
    font-size: var(--fontSize_18);
    line-height: 36px;
    padding-left: 76px;
    padding-bottom: 40px;

    > span {
        position: absolute;
        display: block;
        top: 6px;
        left: 0;
        color: var(--color_gray);
        font-size: var(--fontSize_tab);
        font-weight: normal;
        line-height: normal;
    }
}

.cols, .head-cols {
    font-size: var(--fontSize_18);
    line-height: normal;
    margin-left: 76px;
    padding: 0 0 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color_lightGray);
    display: flex;

    > div:first-child {
        min-width: 163px;
        width: 163px;
        margin-right: 50px;
    }

    > span {
        left: -76px;
    }
}

.head-cols {
    font-weight: normal;
    font-style: italic;
    color: #acacac;
    border-bottom: 1px solid var(--color_lightGray2);
}

.subParagraph {
    position: relative;
    font-size: var(--fontSize_18);
    line-height: 36px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 76px;

    > span {
        position: absolute;
        display: block;
        top: 16px;
        left: 0;
        color: var(--color_gray);
        font-size: var(--fontSize_tab);
        font-weight: normal;
        line-height: normal;
    }
}

.signature {
    font-size: var(--fontSize_tab);
    margin-top: 100px;
    color: var(--color_gray);
    margin-bottom: 200px;
}

.link {
    display: inline;
    text-decoration: none;
    cursor: pointer;
    color: var(--color_primary);
    border-bottom: dashed 1px var(--color_primary);
    transition: all .2s;
    &:hover {
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}