.screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.wrapper {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    & > button {
        z-index: 1;
        transition: z-index 0s .2s;
    }
    &._open {
        & > button {
            z-index: 6;
            transition: z-index 0s 0s;
        }
        & .popup {
            visibility: visible;
            opacity: 1;
        }
    }
}

.popup {
    position: absolute;
    top: -22px;
    right: -40px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    padding: 36px 135px 40px 40px;
    border-radius: 6px;
    background-color: white;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
}