.popup {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 40px 0;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    display: flex;
    align-items: center;
  }

  &_wrapper {
    position: relative;
    margin: auto;
    padding: 40px;
    box-shadow: var(--shadow_menu);
    border-radius: 10px;
    background-color: white;
  }

  &_wrapper .dropzone {
    width: 400px;
    height: 273px;
    padding: 40px;
    border-radius: 10px;
    border: 1px dashed var(--color_lightGray2);
    margin-bottom: 40px;
    cursor: pointer;

    &_title {
      font-size: var(--fontSize_h2);
      color: var(--color_black);
      font-weight: bold;
      margin-bottom: 20px;
    }

    &_text {
      font-size: 18px;
      color: var(--color_gray);
      text-align: center;
    }

    .docList {
      color: var(--color_darkGray);
      font-size: var(--fontSize_tab);
      width: fit-content;

      &-item {
        width: fit-content;
        position: relative;
        transition: color .2s;
        padding-right: 20px;
        margin-bottom: 20px;
        > div:first-child {
          display: flex;
          max-width: 300px;
        }
        .deleteBtn {
          top: -3px;
          right: 0;
        }

        &:hover {
          color: var(--color_primary_hover);
        }
      }
    }
  }
}

.ETP_templateTitle_input, .ETP_templateDesc_input {
    padding: 0;
    background-color: #fff;
    border: none;
    resize: none;
    &:hover,&:focus {
       text-decoration: underline var(--color_primary) dashed;
       -webkit-text-decoration-line: underline !important;
       -webkit-text-decoration-color: var(--color_primary) !important;
       -webkit-text-decoration-style: dashed !important;
       text-underline-offset: 2px;
       -webkit-text-underline-offset: 2px !important;
    }
}

.ETP_templateTitle_input {    
    font-size: 20px;
    height: 24px;
    font-weight: bold;
    color: var(--color_black);    
    width: 100%;    
  }

.ETP_templateDesc_input {
    font-size: 14px;
    min-height: 18px;
    color: var(--color_darkGray);
    margin-bottom: 40px;
    margin-top: 20px;
    max-width: 400px;
    width: 200%;
    &::placeholder {
      color: var(--color_darkGray);
    }
  }

.ETP_standardTeam, .ETP_relatedDocuments {
  width: 400px;
  border-radius: 10px;
  padding: 40px;
  min-height: 300px;
}

.ETP_standardTeam {
  background-color: #f9f9f9;
  margin-right: 40px;

  .ETP_userList {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    & > div:last-child {
      margin-bottom: 0;
    }
    & > div + div {
      margin-top: 0;
    }
  }
}

.ETP_relatedDocuments {
  border: 1px solid var(--color_lightGray2);

  .ETP_documentList {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    &_item {
      &:not(:first-child) {
        margin-top: 20px;
      }
      .docIcon {
        transform: translateY(4px);
      }
      &_controls  {
        padding-left: 40px;
        > label:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.docIcon {
  transform: translateY(1px);
  margin-right: 30px;
}

.AMP_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 40px 0;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
}

.AMP_popup-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  margin: 0 auto;
  padding-bottom: 40px;
  width: 400px;

}
.AMP_popup {
  border-radius: 20px;
  box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-color: #fff;
  color: var(--color_black);
  padding: 40px;
}

.AMP_input {
  position: relative;
  margin-bottom: 40px;
  button {
    position: absolute;
    right: 30px;
    top: 8px;
  }
  input {
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    width: 100%;
    padding: 10px 55px 10px 15px;
    border: 1px solid var(--color_lightGray);
  }
  input::placeholder {
    color: var(--color_gray);
  }
  > div {
    top: 5px;
    right: 10px;
  }
}

.AMP_candidates {
  margin-top: 40px;
  > div:last-child {
    margin-bottom: 0;
  }
}