.wrapper {
    height: fit-content;
    padding: 12px 0;
    border-radius: 5px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    opacity: .5;
    transition: opacity .2s;
    &:hover {
        opacity: 1;
    }
    &._open {
        transition: box-shadow .1s .1s;
        opacity: 1;
        border-radius: 5px 5px 0 0;
        box-shadow: none;
    }
    &.fullMode {
        display: inline-block;
        padding: 10px 15px 20px 35px;
    }
}
.tip {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
}
.menu-btn {
    position: relative;
    margin: 0 auto;
    width: 25px;
    .menu-img {
        padding: 5px;
        width: 25px;
        cursor: pointer;
        > img {
            width: 15px;
            cursor: pointer;
        }
    }
    &:hover .tip {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
    .tip {
        top: -31px;
        left: -4px;
    }
}


.back {
    position: relative;
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    width: 100%;
    margin: 15px auto 0;
    padding-top: 15px;
    padding-bottom: 5px;
    border-top: 1px solid var(--color_lightGray);
    transition: color .2s;
    &-btn {
        width: fit-content;
        margin: 0 auto;
        padding: 5px;
        cursor: pointer;
    }
    &:hover {
        color: var(--color_primary_hover);
    }
    &:hover .tip {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
    .tip {
        top: -17px;
        left: 22px;
    }
}
