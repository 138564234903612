.deleted {
    color: var(--color_gray);
}

.wrapper {
    position: relative;
    display: flex;
    & > a:first-child {
        margin-right: 15px;
        margin-left: 5px;
    }

    &._edit {
        & > a:first-child {
            transform: translateY(6px);
        }
    }

    &._freeze {
        background-color: #E5EFFD;
        &:first-child:not(input) {
            margin-top: 20px;
            border-radius: 8px 8px 0 0;
        }
        &:first-child:not(input) ~ &:last-child {
            padding-bottom: 15px;
            border-radius: 0 0 8px 8px;
        }
        &:first-child:last-child {
            margin-top: 20px;
            border-radius: 8px;
            padding-bottom: 15px;
        }
    }
    &:not(._freeze) + &._freeze {
        margin-top: 20px;
        border-radius: 8px 8px 0 0;
        &:last-child {
            border-radius: 8px;
        }
    }

    &:hover {
        .popup_showBtn {
            opacity: 1;
            visibility: visible;
        }
    }
}

.editPart {
    position: relative;
    width: 100%;
    .textarea {
        height: 38px;
        padding: 8px 65px 10px 15px;
        display: inline-block;
        color: var(--color_darkGray);
        font-family: var(--fontFamily);
        font-size: var(--fontSize_tab);
        letter-spacing: 0;
        border-radius: 4px;
        border: 1px solid var(--color_lightGray);
        background-color: #fff;
        resize: none;
        overflow: hidden;
        min-height: 29px;
        width: 100%;
        appearance: none;
        &::placeholder {
            color: var(--color_gray);
        }
    }
    .textarea.large {
        padding: 8px 15px 25px;
        & ~ .send {
            bottom: 11px;
        }
    }
    .send {
        cursor: pointer;
        color: var(--color_primary);
        position: absolute;
        bottom: 14px;
        right: 30px;
        transition: color .2s;
        font-size: var(--fontSize_standart);
        &:hover {
            color: var(--color_primary_hover);
        }
    }
    .clear-cross {
        top: -7px;
        right: -19px;
    }
}

.mainPart {
    width: 100%;
    margin-left: 4px;
    .head {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 5px;
        > div {
            margin-right: 10px;
        }
        .userName {
            color: var(--color_darkGray);
            font-weight: bold;
        }
        .gray {
            color: var(--color_gray);
        }
    }

    .content {
        font-size: var(--fontSize_tab);
        color: var(--color_darkGray);
        line-height: 19px;
        white-space: pre-wrap;
        word-break: break-word;
    }
}

.unreadMark {
    position: absolute;
    top: 0;
    left: -20px;
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: var(--color_primary);
}

.popup_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    &._open {
        .popup, .popup_showBtn {
            opacity: 1;
            visibility: visible;
        }
        .popup_showBtn {
            z-index: 3;
            transition: opacity .2s, visibility .2s, z-index .0s .0s;
        }
    }
}

.popup_showBtn {
    position: absolute;
    top: 15px;
    right: -5px;
    border: none;
    padding: 2px 5px 1px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    background: transparent;
    cursor: pointer;
    transition: opacity .2s, visibility .2s, z-index .0s .2s;
    > span:not(:last-child) {
        margin-bottom: 2px;
    }
    > span {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--color_darkGray);
        display: inline-block;
    }
}

.popup {
    position: absolute;
    top: -63px;
    right: -20px;
    width: fit-content;
    width: -moz-fit-content;
    margin: 58px 0 691px 20px;
    padding: 20px 66px 20px 20px;
    border-radius: 10px;
    box-shadow: var(--shadow_menu);
    background-color: #ffffff;
    transition: opacity .2s, visibility .2s;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    > button {
        display: block;
        height: 15px;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.freezePart {
    margin-top: 5px;
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
}

