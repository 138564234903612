.reviewed {
    position: relative;
    padding: 8px 0 0 40px;
    min-width: 248px;
    max-width: 283px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
    &.cut::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
    &-text {
        max-width: 240px;
        height: 14px;
        padding-right: 40px;
        white-space: nowrap;
        overflow: hidden;
    }
    &-time {
        max-width: 206px;
        height: 14px;
        width: fit-content;
        overflow: hidden;
        color: var(--color_gray);
        white-space: nowrap;
    }
    &-time.cut {

    }
    &-hintPlace &-time {
        max-width: none;
    }
    &-hintPlace {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        color: var(--color_darkGray);
        transition: all .2s;
        z-index: 6;
        left: 30px;
        top: 8px;
        padding: 10px;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
        width: fit-content;
        height: fit-content;
        background-color: white;
        border-radius: 6px;
        div {
            font-size: var(--fontSize_standart);
            width: fit-content;
            color: var(--color_darkGray);
            white-space: nowrap;
            background-color: #fff;
            &::after {
                display: none;
            }
        }
        > div:last-child {
            color: var(--color_gray);
        }
    }
}
.reviewed:hover .reviewed-hintPlace {
    visibility: visible;
    opacity: 1;
}
