.wrapper {
  display: inline;
  align-items: center;
  height: 100%;
  position: relative;
  top: 0;
  right: 0;
  &._open{
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    
    .drop-down {
      top: 32px;
      right: -184px;
    }
  }
}

.button {
  padding: 10px 0;
  margin: 0 0 0 50px;
  border: none;
  background: transparent;
  transition: all 0.2s;
  font-size: 0;
  cursor: pointer;
  &:hover {
    & > span {  
      background-color: var(--color_primary_hover__dark);
    }
  }

  & > span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--color_primary);
    display: inline-block;
    &:nth-child(2) {
      margin: 0 4px;
    }
  }
}

.drop-down {
  position: absolute;
  top: 30px;
  right: -15px;
  width: 227px;
  box-shadow: var(--shadow);
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  box-sizing: border-box;
  background-color: var(--bg);
  z-index: 1;
  &::before {
   content: '';
   position: absolute;
   top: -7px;
   left: 18px;
   width: 13px;
   height: 13px;
   box-shadow: var(--shadow);
   background-color: var(--bg);
   transform: rotate(45deg);
   border: solid 1px #f2f2f2;
   z-index: 0;
  }
}

.drop-down-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  background-color: var(--bg);
  border-bottom: solid 1px rgba(222,222,222,.5);
  &:last-of-type{
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:first-of-type{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.drop-down-item {
  padding: 15px 17px 16px 17px;
  color: var(--color_darkGray);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_tab);
  font-weight: 400;
  border: 0;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0 !important;
  display: flex;
  align-items: center;
  filter: grayscale(.75); 
  &:hover {
      filter: grayscale(0); 
  }
  
  & > svg {
      margin-right: 10px;
  }

  &:not(:last-child) {

    box-sizing: border-box;
  }
}

.icon{
  height: 24px;
  width: auto;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -5px;
}

.planar {
  height: 15px;
  font-size: var(--fontSize_standart);
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: var(--color_primary);
  margin-right: 10px;
  cursor: pointer;
}

.spinner {
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
  display: inline-flex;
  align-items: center;
  transform: translateY(2px);
  user-select: none;
  > div {
    position: static;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    img {
      height: 15px;
      width: 15px;
      margin: 0;
    }
  }
}