.screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.wrapper {
    position: fixed;
    top: 154px;
    left: 0;
    border-radius: 5px;
    width: 80px;
    transition: width .2s, height .2s;

    &._open {
        transition: box-shadow .1s .1s, width .2s, height .2s;
        box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    }

    &.fullMode {
        width: 400px;
        padding-top: 30px;
        background-color: white;
    }
}

.title {
    position: absolute;
    left: 40px;
    top: 25px;
    z-index: 1;
}
