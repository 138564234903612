.wrapper {
  position: absolute;
  right: 0;
  top: 0;
}

.button {
  border: none;
  background: transparent;
  transition: all 0.2s;
  font-size: 0;
  cursor: pointer;
  position: absolute;
  padding: 16px 0;
  border-radius: 5px;
  right: 0px;
  top: -16px;
  display: flex;
  z-index: 6;
  flex-direction: column;
  & > span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #606060;
    display: inline-block;
    &:nth-child(2) {
      margin: 3px 0;
    }
  }
  &:hover{
    & > span {
      background-color: var(--color_primary_hover__dark);
    }
  }
}


.drop-down {
  position: absolute;
  padding: 10px;
  top: 5px;
  right: 0;
  width: 200px;
  box-shadow: var(--shadow);
  border-radius: 10px;
  box-sizing: border-box;
  background-color: var(--bg);
  z-index: 6;
}

.drop-down-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--bg);
  z-index: 6;
  &:last-of-type{
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:first-of-type{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.drop-down-item {  
  color: var(--color_primary);
  padding: 10px;
  text-decoration: none;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_standart);
  font-weight: 400;
  border: 0;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0 !important;
  &.delete {
    color: var(--color_darkRed);
    transition: all .2s;
    &:hover {
      color: var(--color_darkRed_hover);
    }
  }
  &:hover {
    color: var(--color_primary_hover);
    background: #fdfdfd;
  }

  &:not(:last-child) {

    box-sizing: border-box;
  }
  
  & img {
      margin-right: 7px;
  }
}

.divider {
   width: 200px;
   margin: 10px 0 10px -10px;
   height: 1px;
   background-color: var(--color_lightGray);
}
