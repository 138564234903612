.wrapper {
    position: fixed;
    top: 40px;
    left: calc(50% - 389px);
    padding: 40px;
    border-radius: 20px;
    box-shadow: var(--shadow_big);
    background-color: #ffffff;
    font-size: var(--fontSize_standart);
    width: fit-content;
    display: flex;
    justify-content: space-between;
    z-index: 15;
    min-width: 784px;
    &.offsetTop {
        top: 166px;
    }
    button {
        white-space: nowrap;
    }
}

.event-name {
    display: flex;
    > img {
        height: 30px;
        width: 28px;
        margin-right: 20px;
        margin-top: 4px;
    }
    .text {
        color: var(--color_black);
        font-size: var(--fontSize_tab);
    }
    .btnBlue {
        color: var(--color_primary);
        cursor: pointer;
        transition: all .2s;
        &:hover {
            color: var(--color_primary_hover)
        }
    }
}

.event-tagline {
    font-size: 30px;
    font-weight: bold;
    color: var(--color_black);
    transform: translateY(-8px);
    > img {
        width: 64px;
        height: 20px;
    }
}

.gray {
    color: var(--color_gray);
}