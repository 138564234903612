/* ---------------------------- */
.wrapper{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  z-index: 6;
}
.sidebar{
  position: absolute;
  right: 0;
  top: 0;
  width: 412px;
  height: auto;
  min-height: 100%;
  background-color: #ffffff;
  box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  padding: 40px 50px 40px 40px;
  font-family: var(--fontFamily);
  z-index: 2;
  margin-right: -412px;
  animation-name: marginsidebar;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  &__header{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  &__title{
    margin: 0;
    padding-bottom: 50px;
    font-size: var(--fontSize_h1);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    color: var(--color_black);
  }

}


@keyframes marginsidebar {
  from {margin-right: -412px;}
  to {margin-right: 0px;}
}


.sidebar__wp{
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.flex{
  display: flex;
  justify-content: space-between;
}

.img{
  &-wrapper{
    width: 90px;
    height: 90px;
    background-color: var(--color_primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__text{
      font-size: 22px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.91;
      letter-spacing: 0.6px;
      color: #ffffff;
      text-transform: uppercase;
    }
    &_isBg{
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      .img-wrapper__text{
        display: none;
      }
    }
  }
  &-btn{
    font-size: var(--fontSize_standart);
    text-align: center;
    color: var(--color_primary);
    cursor: pointer;
    margin: 10px 0 40px 0;
    transition: color .2s;
    &:hover {
      color: var(--color_primary_hover);
    }
  }
}

.input-area {
  position: relative;
  width: 100%;
  display: block;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.inp-name {
  width: 155px;
  &:first-child {
    margin-right: 10px;
  }
}
.account{
  &-form{
    padding-top: 20px;
  }
  &-inp{
    width: 100%;
    height: 39px;
    border: solid 1px var(--color_lightGray);
    padding: 10px;
    border-radius: 4px;
    font-size: var(--fontSize_tab);
    font-family: var(--fontFamily);
    &::placeholder{
      color: var(--color_gray);
      font-family: var(--fontFamily);
    }
  }
  &-fields{
    margin: 50px 0;
    &-name {
      display: flex;
    }
  }
}

.form{
  &-text{
    font-size: var(--fontSize_tab);
    font-weight: normal;
    color: var(--color_darkGray);
    margin: 35px 0 25px;
  }
  &-btn{
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-size: var(--fontSize_tab);
    background-color: var(--color_primary);
    color: #ffffff;
    border: none;
    font-weight: 300;
    margin: 35px auto 0;
    display: block;
    cursor: pointer;
    transition: background-color,border-color 0.2s;
    &:hover{
      background-color: var(--color_primary_hover__dark);
    }
  }
  &-logout{
    background-color: white;
    color: var(--color_darkGray);
    border: solid 1px var(--color_lightGray2);
    font-weight: 300;
    margin: 16px auto 0;
    &:hover{
      background-color: #fcfcfc;
      border-color: var(--color_darkGray);
    }
  }
}
.inp-file{
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  display: none;
  &__label{
    margin-left: 40px;
    width: fit-content;
    cursor: pointer;
    &:hover button {
      color: var(--color_primary_hover);
      border-color: var(--color_primary_hover);
    }
    button {
      z-index: -1 !important;
    }
  }
  &__text{
    font-size: var(--fontSize_tip);
    line-height: 18px;
    letter-spacing: 0.01px;
    color: var(--color_gray);
    margin: 10px 0 0 45px;
  }
}

.text_isError{
  color: var(--color_lightRed);
}
.file-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 33px;
}

.error-message {
  font-family: var(--fontFamily);
  color: var(--color_lightRed);
  font-size: var(--fontSize_standart);
  line-height: 19px;
  margin-top: 7px;
}
.email-message {
  font-family: var(--fontFamily);
  color: var(--color_lightRed);
  font-size: var(--fontSize_standart);
  line-height: 19px;
  padding-left: 9px;
}

.account-company {
  margin: 50px 0;
  color: var(--color_darkGray);
  &-name {
    margin-bottom: 20px;
  }
  &-link {
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    text-decoration: none;
    transition: color .2s;
    &:hover {
      color: var(--color_primary_hover);
    }
  }
}

.account-company-manage {
  display: flex;
  button {
    white-space: nowrap;
  }
  &-create {
    width: 150px;
    margin-right: 20px;
    &-plan {

    }
  }
  &-join {
    width: 150px;
  }
  &-desc {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    padding: 10px 0;
  }
}

.account-paying {
  margin: 50px 0;
  &-card {
    padding: 40px;
    border-radius: 10px;
    &-title {
      font-size: var(--fontSize_h1);
      color: var(--color_black);
      padding-bottom: 20px;
    }
  }
  .free {
    background-color: var(--color_lightGray);
  }
  .basic {
    background-color: #ffeedf;
  }
}

.account-paying-card-desc {
  color: var(--color_darkGray);
  padding-bottom: 40px;
  font-size: var(--fontSize_tab);
  .red {
    color: var(--color_darkRed);
  }
  .gray {
    color: var(--color_gray);
  }
}

.logoutBtn-row {
  display: flex;
  justify-content: center;
  button {
    margin-right: 0 !important;
  }
}

.JC-popup-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 5;
}
.JC-popup-card {
  position: relative;
  width: 400px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: var(--color_black);
  &-title {
    font-size: var(--fontSize_h2);
    font-weight: bold;
    padding-bottom: 40px;
  }
  &-text {
    font-size: var(--fontSize_h3);
    padding-bottom: 20px;
  }
  &-inputArea {
    margin-bottom: 20px;
    > input {
      width: 100%;
      font-size: var(--fontSize_tab);
      color: var(--color_darkGray);
      padding: 9px 14px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: solid 1px var(--color_lightGray);
    }
  }
  &-btnRow {
    display: flex;
    justify-content: space-between;
  }
}

.timeBlock {
  margin-bottom: 40px;
  height: 193px;
  &_12h {
    display: inline-flex;
    font-size: var(--fontSize_standart);
    margin-right: 40px;
    > div {
      padding: 3px 10px 2px;
      color: var(--color_darkGray);
      border-top: 1px solid var(--color_lightGray2);
      border-bottom: 1px solid var(--color_lightGray2);
      user-select: none;
      cursor: pointer;
      &._MAC_correction span {
        position: relative;
        top: 2px;
      }
      &._sel {
        color: #fff;
        background-color: var(--color_gray);
        border-color: var(--color_gray);
        cursor: default;
      }
    }
    > div:first-child {
      border-left: 1px solid var(--color_lightGray2);
      border-radius: 5px 0 0 5px;
    }
    > div:last-child {
      border-right: 1px solid var(--color_lightGray2);
      border-radius: 0 5px 5px 0;
    }
  }
  &_formatBlock {
    position: relative;
    top: -5px;
    font-size: var(--fontSize_standart);
    user-select: none;
    width: fit-content;
    display: inline-block;
    &_current {
      position: relative;
      z-index: 1;
      transition: z-index 0s .2s;
      display: flex;
      padding-left: 25px;
      cursor: pointer;
      > span {
        position: absolute;
        top: 10px;
        left: 5px;
        display: inline-block;        
      }
      > div {
        > div:first-child {
          color: var(--color_darkGray);
        }
        > div:last-child {
          color: var(--color_gray);
        }
      }
    }
    &_popup {
      position: absolute;
      top: -20px;
      left: -20px;
      width: 181px;
      padding: 65px 20px 20px 45px;
      background-color: #fff;
      box-shadow: var(--shadow_menu);
      transition: all .2s;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
      z-index: 2;
      > div {
        cursor: pointer;
        > div:first-child {
          color: var(--color_darkGray);
        }
        > div:last-child {
          color: var(--color_gray);
        }
      }
    }
    &._open {
      .timeBlock_formatBlock_current {
        transition: z-index 0s 0s;
        z-index: 3;
        font-weight: bold;        
      }
      .timeBlock_formatBlock_popup {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &_timezone {
    position: relative;
    font-size: var(--fontSize_standart);
    &_current {
      position: relative;
      width: fit-content;
      z-index: 1;
      padding-left: 25px;
      cursor: pointer;
      transition: z-index 0s .2s;
      .arrow {
        position: absolute;
        top: 4px;
        left: 0px;
        display: inline-block;
      }
    }
    &_popup {
      position: absolute;
      top: -20px;
      left: -20px;
      width: 340px;
      height: 230px;
      padding: 50px 20px 20px 45px;
      background-color: #fff;
      box-shadow: var(--shadow_menu);
      transition: all .2s;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
      z-index: 2;
      > div {
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    &._open {
      .timeBlock_timezone_current {
        transition: z-index 0s 0s;
        z-index: 3;
        font-weight: bold;
      }
      .timeBlock_timezone_popup {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.bold {
  font-weight: bold;
}
.gray {
  color: var(--color_gray);
}
.grayPad {
  color: var(--color_gray);
  margin-right: 3px;
}
.tzPad {
  margin-bottom: 20px;
}

._arrow {
    position: absolute;
    top: 7px;
    left: 11px;
    display: inline-block;
    &._MAC_correction {
        top: 5px;
    }
    &::before, &::after {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: var(--color_gray);
        top: calc(50% - 2px);
        right: 10px;
        width: 1px;
        height: 8px;
        transform: rotate(-50deg);
    }
    &::after {
        right: 4px;
        transform: rotate(50deg);
    }
}

._open ._arrow {
    &::before, &::after {
        transform: rotate(50deg);
        top: calc(50% - 2px);
    }
    &::after {
        transform: rotate(-50deg);
    }
}
