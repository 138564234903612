.wrapper {
  padding-top: 210px;
  min-height: 101vh;
  margin: auto;
  font-family: var(--fontFamily);
}

.inner {
  margin: auto;
  color: var(--color_black);
  font-family: var(--fontFamily);
}

.title {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0.02px;  
}
.space80 {
  width: 100%;
  height: 80px;
}
.subtitle {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.title-row {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
.title-rowBlock1, .title-rowBlock2 {
    display: flex;
}
.title-rowBlock1 {
    margin-right: 100px;
    img {
        width: 100px;
        height: 100px;
        margin-right: 50px;
    }
}
.title-quote {
    width: 330px;
    font-size: 24px;
    span {
        font-size: var(--fontSize_tab);
    }
}

.title-rowBlock2 {
    img {
        width: 182px;
        height: 115px;
        margin-right: 50px;
    }
}
.title-bamText {
    font-size: 24px;
}
.title-getAccBtn {
    margin-top: 40px;
    button {
        height: 42px !important;
        width: 167px !important;
        font-size: var(--fontSize_18) !important;
    }
}

.button-row {
  text-align: center;
  transition-property: opacity;
  transition-duration: 0.5s;
  &._opaque {
     opacity: 0;
  }
}

.sectionOne {
    width: 1155px;
    height:787px;
    margin: 0 auto 116px;
    background: url('/images/contract-one-ipad-bg-compressed.jpg');
    background-size: cover;
    position: relative;
}

.sectionOne img {
    position: absolute;
    top: 53px;
    left: 177px;
}
.intermedia {
  width: 487px;
  font-size: 25px;
  line-height: 1.6;
  margin: auto;
  position: relative;
}
.intermediaImg {
  left:0;
  top: 2px;
  position: relative;
}
.img20 {
  margin: 0 20px;
  left:0;
  top: 1px;
  position: relative;
}
.space200 {
  width: 100%;
  height: 200px;
}

.sectionTwo {
    width: 1140px;
    margin: auto;  
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.sectionTwoColumn {
    width: 300px;
    font-size: var(--fontSize_h2);
    line-height: 1.5;
}
.sectionTwoHeader {
    font-size: var(--fontSize_h1);
    font-weight: bold;
    line-height: 1.18;
    letter-spacing: 0.01px;
    margin-bottom: 40px;
}

.sectionTwoHalf {
    width: 1280px;
    margin:130px auto 130px auto;  
    display: flex;
    justify-content: center;
}
.clarity {
    color: var(--color_primary);
    font-weight: bold;
    font-size: 24px;
    margin-top: 40px;
    span {
        font-size: var(--fontSize_18);
    }
}
.sectionPink {
  width: 1126px;
  margin: auto;
  padding: 80px 100px;
  border-radius: 20px;
  background-color: #fbfbfb;
}
.titlePink {
  width: 400px;
  font-size: var(--fontSize_h1);
  font-weight: bold;
  line-height: 1.18;
  margin: auto;
}
.pink2column {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.pinkColumn {
  width: 443px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 0 20px 120px;
  font-size: 26px;
  position: relative;
}
.pinkContain {
  position: absolute;
  top: 27px;
  left: 0;
  width: 120px;
  display: flex;
  justify-content: center;
}
.pinkText0 {
    color: var(--color_darkGray);
}
.pinkText1 {
    color: var(--color_gray);
}
.pinkText2 {
    color: var(--color_lightGray2);
}
.pinkText3 {
    color: var(--color_lightGray);
}
.pinkText4 {
    color: var(--color_primary);
}
.pinkColumn2 {
  width: 926px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 100px 20px 120px;
  font-size: 26px;
  position: relative;  
}
.pinkColumn4 {
  display: flex;  
  justify-content: space-between;
  font-size: var(--fontSize_h2);
  line-height: 1.5;
  color: var(--color_darkGray);
  margin-top: 40px;
}    
.ordinal {
  margin-top: 40px;
  font-size: var(--fontSize_h2);
  font-weight: normal;
  line-height: 1.5; 
}

.sectionSVG {
  width: 954px;
  height: 1422px;
  margin: 100px auto 200px;
  background:url('/images/process.png');  
  background-size: cover;
}
.versions {
  margin: 72px 28px 68px -20px;
  background:url('/images/versions-image.png');
  background-size: cover;
  width: 918px;
  height: 470px;
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) { 
    .sectionSVG {
        width: 954px;
        height: 1422px;
        margin: 100px auto 200px;
        background:url('/images/process@3x.png');
        background-size: cover;
    }
    .versions {
        margin: 72px 28px 68px -20px;
        background:url('/images/versions-image@3x.png');
        background-size: cover;
        width: 918px;
        height: 470px;
    }
}


.changes1 {
  width: 480px;
  margin: 84px 0 200px;
}
.changes {
  display: flex;
  margin-top: 40px;
  justify-content:space-between;
}
.changesColumn {
   width: 200px;
}
.bold {
  font-weight: bold;  
}
.sectionGrey {
  background-color: #e3e3e3;
  padding: 80px;
}
.titleGreyWhite {
  font-size: 80px;
  font-weight: bold;
  color: #ffffff;
  margin: auto;
  position: relative;
  width: 400px;
  text-align: center;
}
.titleGreyWhite > svg {
  position: absolute;
  left: 8px;
  top: 37px;
}
.titleGrey {
  display: flex;
  justify-content:space-between;
}
.sectionTwoColumn268 {
  width: 268px;
  position: relative;
}
.aws {
  left: 0;
  top: 5px;
  position: relative;
}
.cite {
  font-weight: normal;
  text-indent: -12px;
}
.cite1 {
  font-size: var(--fontSize_tab);
  font-weight: normal;
  line-height: 1.36;
  color: #606060;
  margin-top: 40px;
}
.sectionGrad {
  background-image: linear-gradient(to bottom, #f6f6f6, 190px, #f6f6f6, #fefefe);
  padding: 80px 0 80px 365px;
}
.sectionGrad1 {
  margin-top: 40px;
  width: 400px;
  font-size: var(--fontSize_h1);
  font-weight: bold;
  line-height: 1.18;
}
.sectionGrad .titleGreyWhite {
  text-align: left;
  margin: 0;
}
.sectionOrange {
  background-color: #f89f53;
  box-shadow: 0 0 14px 14px #f89f5340;
  padding: 80px 0 80px 363px;
  font-size: 50px;
  font-weight: bold;
}
.white {
  color: white;
  position: relative;
}
.white > img {
  position: absolute;
  top: 24px;
  left: -90px;
}
.schedule1 {
  text-decoration: none;
}
.schedule {
  color: white;
  width: 400px;
  height: 80px;
  border-radius: 20px;
  border: solid 2px #ffffff;
  font-size: var(--fontSize_h1);
  line-height: 2;
  text-align: center;
}


.s2title {
  font-size: 60px;  
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.4px;
  display: inline-block;
  width: 470px;
  position: sticky;
  top: 130px;
}

.test {
  padding-top: 20vh;
  opacity: 0;
  transition: 0.8s;
}

.s2opaque{
    height: 3px;
    width: 100%;
    z-index: 2;
    position: sticky;
    bottom: 0;
    background: linear-gradient(to top, white, white 97%, rgba(255,255,255,0));
}

.s2titleDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 470px;
}

.remarkAbsolute {
  position: absolute;
  top: -200px;
  bottom: 0;
  left: -525px;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.s2titleHide {
  opacity: 0;
  transition: opacity 0.4s linear 0.3s;    
}
.remark {
  display: inline-block;
  width: 393px;
  margin-top: 70px;
  bottom: 0px;
  visibility: visible;
  font-weight: normal;
  line-height: normal;
  &._width {
    width: 100%;
  }
}
.remark img {
    display: block;
    margin-bottom: 16px;
}
.remark div {
  font-size: 22px;  
  letter-spacing: 0.6px;
}
.remark span {
  font-size: var(--fontSize_18);
  color: var(--color_lightGray);
  display: block;
  margin-bottom: 16px;
  letter-spacing: normal;
}
.remark p {
  font-size: var(--fontSize_h3);
  line-height: 1.5;
  margin: 0;
  letter-spacing: normal;
}

.margin0 {
    margin: 0;
}
images>div {
    margin: 0;
    width: 565px;
}
.images>div+div, .subimages>div+img{
    margin-top: 50px;
    position: relative;
}

.remark-full {
    border-radius: 25px;
    border: 2px solid var(--color_primary);
    width: 565px;
    padding: 50px 30px 30px 30px;
}

.pane {
    border-radius: 25px;
    background-color: #f8f8f8;
    padding: 30px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 565px;
    align-items: center;
}
.pane > * {
    margin-top: 20px;
}
.pane div {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.17;
  text-align: center;
  color: #000000;
}
.pane p {
  margin-bottom: 0;
  font-size: var(--fontSize_h2);
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--color_black);
}

.clink {
    color: var(--color_primary);
    display: inline-block;
    font-size: var(--fontSize_tab);
    font-weight: bold; 
    cursor: pointer;
}

.columnOfThree {
  font-size: var(--fontSize_tab);
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--color_darkGray);
  width:350px;
}
.columnOfThree img {
    margin: 0 auto 30px auto;
    display: block;
}
.advSec {
  font-size: var(--fontSize_h1);
  font-weight: bold;  
  line-height: 1.18;
  color: var(--color_black);
  margin-bottom: 60px;
}
.columnOfThree p {
    margin: 0;
}
.columnOfThree p + div {
  height: 30px;    
}

.sectionFour {
    display:none;
    margin: auto;
    padding-top: 50px;
    margin-top: -54px;
    background-image: linear-gradient(to bottom, #f9f9f9, #ffffff);
}

.plans {
    margin: 70px auto 100px auto;
    width: 1120px;
    minwidth: 1120px;
    display: flex;
    justify-content: space-between;
}

.faq {
    margin: auto;
    width: 800px;
    minwidth: 800px;    
}
.faq label {
  font-size: 22px;
  color: var(--color_black);
  padding: 25px 0;
  position: relative;
  display: block;
  cursor: pointer;
  margin:0;
}
.faq input+div {
    display: inline-block;
    position: relative;
    left: 30px;
    top: 6px;
    width: 25px;
    height: 25px;    
    background: url('/images/down@3x.png');
    z-index: 1;
    background-size: cover;
}
.faq input:checked+div{
    background: url(/images/up@3x.png);
    background-size: cover;
}
.input-hidden{
  display:none;
}
.faq input:checked~.input-hidden{
  display: block;
  margin-top: 10px;
  margin-bottom: 1px;
  font-size: var(--fontSize_h3);
  line-height: 1.75;
  color: var(--color_darkGray);
  width: 700px;
}
.faq img {
    display: block;
    margin: 20px auto 20px 0;
}
.faq .input-hidden>p{
  margin: 0;
}
.faq .input-hidden>p+p{
  margin-top: 12px;
}
.faq  input {
    position: fixed;
    top: -20px;
    height: 0;
}
.imagesWrapper input {
    position: fixed;
    top: -20px;
    height: 0;
}

.imagesWrapper {
  max-width: 565px;
  border-radius: 25px;
  position: relative;
}

.italic {
  font-style: italic; 
}
.internal{
  color: var(--color_gray);
}
.external{
  color: var(--color_primary);
}
.orange {
  color: var(--color_orange);
}


.imagesBottom {
    position: absolute;
    bottom: 0;
}
.imagesBottom30 {
    position: absolute;
    bottom: 30px;
}

.imagesTop {
    position: absolute;
    top: 0;
}

.imagesTop60 {
    position: absolute;
    top: 60px;
}
.imagesTopLeft {
    position: absolute;
    top: 60px;
    left: 60px;
}

.imagesTitle_a1 {
  margin: 0 auto 20px auto;
  width: 469px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.17;
  text-align: center;
  color: #000000; 
  z-index: 3;
  position: relative;
}

.imagesWrapper input+div {
  margin: 0 auto 34px auto;
  display: block;
  position: relative;
  width: 100px;
  height: 1px;
  &:after{
    content: "See More";
    position: absolute;
    display: inline-block;
    cursor: pointer;
    width: 100px;
    text-align: center;
    font-size: var(--fontSize_18);
    font-family: var(--fontFamily);
    z-index: 1;
    color: var(--color_primary);
  }
  &.orange:after{
      color: var(--color_orange);
  }
}
.imagesWrapper input:checked+div{
    &:after{
     content: "Hide text";
    }
}

.imagesWrapper  input:checked~.input-hidden{
  display: block;
  margin: 0 30px;
  font-size: var(--fontSize_h3);
  line-height: 1.75;
  color: var(--color_darkGray);
  z-index: 3;
  position: relative;
  &>p {
      margin: 0;
      padding: 0;
      &+p{
          margin-top: 12px;
      }
  }
}


.bg-1-a {
    background-color: #e9f0fa;
    padding-top: 50px;
    & footer {
        height: 447px;
    }
}

.bg-1-c {    
    &>article{
        position: absolute;
        top:293px;
        left: 30px;
        display: inline-block;
        width: 461px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.17;
        color: white;
    }
    &>nav {
        width: 505px;
        font-size: var(--fontSize_h2);
        line-height: 1.5;
        position: absolute;
        top:383px;
        left: 30px;
        display: inline-block;
        color: white;
    }
}

.bg-2-a {
    background-color: #e8f0fb;
    padding-top: 406px;
    & article {
      text-align: center;
      z-index: 3;
      position: relative;
      width: 503px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.17;
      color: #31393c;
      margin: 0 auto 20px auto;
    }
    & footer {
        height: 19px;
    }
}

.bg-2-b {    
    &>article{
        position: absolute;
        top:222px;
        left: 30px;
        display: inline-block;
        width: 505px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.17;
        color: #212121;
    }
    &>nav {
        width: 505px;
        font-size: var(--fontSize_h2);
        line-height: 1.5;
        position: absolute;
        top:313px;
        left: 30px;
        display: inline-block;
        color: var(--color_black);
    }
}

.bg-2-c {
    padding-top: 316px;
    & article {
      text-align: center;
      z-index: 3;
      position: relative;
      width: 525px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.17;
      color: var(--color_black);
      margin: 0 auto 20px auto;
    }
    & footer {
        height: 18px;
    }
}

.imagesWrapper input~svg.svg-small{
  display:inline-block;
}
.imagesWrapper input~svg.svg-big{
  display:none;
}
.imagesWrapper input:checked~svg.svg-small{
  display:none;
}
.imagesWrapper input:checked~svg.svg-big{
  display:inline-block;
}

.bg-3-a{
    background-color: #e9f0fa;
    padding-top: 60px;
    & article {
      text-align: center;
      z-index: 3;
      position: relative;
      width: 527px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.17;
      color: var(--color_black);
      margin: 0 auto 20px auto;
    }
    & footer {
        height: 371px;
    }
}

.bg-3-b {
    &>article{
        position: absolute;
        top:208px;
        left: 30px;
        display: inline-block;
        width: 505px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.17;
        color: black;
    }
    &>nav {
        width: 505px;
        font-size: var(--fontSize_h2);
        line-height: 1.5;
        position: absolute;
        top:338px;
        left: 30px;
        display: inline-block;
        color: #31393c;
    }
}

.bg-1-b {
    background-color: #fcf0e6;
    padding-top: 356px;
    & article {
      text-align: center;
      z-index: 3;
      position: relative;
      width: 469px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.17;
      color: black;
      margin: 0 auto 20px auto;
    }
    & footer {
        height: 19px;
    }
}

