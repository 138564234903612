.screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wrapper {
    position: relative;
    font-size: var(--fontSize_standart);
    white-space: nowrap;
    margin-right: 37px;
    &._open {
        & .approveBtn {
            z-index: 6;
            transition-delay: 0s;
        }
    }
    &._dispVer {
        margin-right: 0;
    }
}

.approveBtn {
    position: relative;
    z-index: 1;
    transition: z-index 0s .2s;
    .arrowBtn {
        position: relative;
        top: -5px;
        cursor: pointer;
        display: inline-block;
        width: 20px;
        margin-left: 5px;
        > button {
            transform: translate(-7px, -5px);
        }
    }
}

.popup {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 400px;
    padding: 100px 2px 40px 40px;
    background-color: var(--color_white);
    border-radius: 6px;
    z-index: 5;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s, opacity .2s;

    &._vis {
        opacity: 1;
        visibility: visible;
    }
}
.scrollBlock {
    padding-right: 38px;
    overflow-y: hidden;
    scrollbar-color: #cacaca transparent;
    scrollbar-width: thin;
    &:hover {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -moz-overflow-scrolling: touch;
        -ms-overflow-scrolling: touch;
        -o-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }
    &::-webkit-scrollbar {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        margin-right: 2px;
        border-radius: 2px;
        background-color: #cacaca;
        box-shadow: 0 0 1px rgba(255,255,255,0.5);
    }
}

.approversList {

}
.tip {
    position: absolute;
    top: 0;
    left: 35px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .0s, visibility .0s;
    z-index: 7;
    &._vis {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s .5s, visibility .2s .5s;
    }
}
