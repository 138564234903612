.wrapper {
    width: 244px;
    position: absolute;
    border-radius: 5px;
    border: 1px dashed var(--color_orange);
    background-color: white;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 11%);
    font-size: var(--fontSize_readable); 
    color: var(--color_darkGray); 
    z-index: 12;
}

.datePlace {
    width: 100%;
    padding: 2px 5px;
    border-bottom: 1px dashed var(--color_orange);
}
.input {
    border: none;
    &.invalid {
        color: var(--color_darkRed);
    }
}
.calendar {
    padding: 20px 10px 10px 20px;
}
.monthPlace {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
}
.propMonth {
    padding: 0 10px;
    cursor: pointer;
    color: var(--color_primary);
    &:hover {
        color: var(--color_primary_hover);
    }
}
.dow {
    width: 20px;
    margin: 0 10px 10px 0;
    display: inline-block;
    white-space: nowrap;
    &.bold {
        font-weight: bold;
    }
    &.day {
        cursor: pointer;
    }
    &.gray {
        color: var(--color_gray);
    }
    &:hover {
        color: var(--color_primary_hover);
    }
}