
.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.alert {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 300px;
    background-color: var(--color_white);
    border-radius: 6px;
    box-shadow: var(--shadow_alert);
    padding: 20px;
    color: var(--color_darkGray);
    font-size: var(--fontSize_tab);
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    z-index: 15;
    .red {
        color: var(--color_lightRed);
    }
    &._vis {
        opacity: 1;
        visibility: visible;
    }
}
