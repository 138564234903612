.wrapper {
    position: relative;
}
.line {
    position: absolute;
    bottom: 0px;
    width: calc(100% + 80px);
    background-color: var(--color_lightGray2);;
    height: 1px;
    left: -40px;
}
.element {
    display: inline-block;
    margin-right: 40px;
    padding-bottom: 15px;
    font-size: var(--fontSize_tab);
    line-height: 1.36;
    letter-spacing: 0.01px;
    color: var(--color_darkGray);
    cursor: pointer;
    height: 34px;
    text-decoration: none;
    &[data-current="true"] {
        border-bottom: solid 5px var(--color_lightGray2);
        cursor: default;
    }
}