.wrapper {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 20px 40px 20px 20px;
    border-radius: 6px;
    box-shadow: var(--shadow_alert);
    background-color: #ffffff;
    font-size: var(--fontSize_tab);
    width: 300px;
    z-index: 25;
    display: flex;
    flex-direction: column;
    button {
        white-space: nowrap;
    }
}

.event-name {
    display: flex;
    > img {
        height: 30px;
        width: 28px;
        margin-right: 20px;
        margin-top: 4px;
    }
    .text {
        color: var(--color_black);
        font-size: var(--fontSize_tab);
    }
    .btnBlue {
        color: var(--color_primary);
        cursor: pointer;
        transition: all .2s;
        &:hover {
            color: var(--color_primary_hover)
        }
    }
}
