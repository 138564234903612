.wrapper {
  padding-top: 250px;
  text-align: center;
  font-family: sans-serif;
}

.title {
  margin-bottom: 20px;
  color: var(--color_black);
  font-size: 30px;
  font-weight: 300;
}

.log-in-button {
  padding: 10px 20px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  background-color: var(--color_primary);
  font-size: var(--fontSize_tab);
  font-weight: 400;
}
