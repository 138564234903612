.wrapper {
    min-width: 1286px;
    position: relative;
    &._unvisible {
         display: none;
    }
  }
  
  .popupMode {
      position: absolute;
      top: 40px;
      padding-bottom: 40px;
      width: calc(100% - 80px);
      background-color: white;
      z-index: 10;
  }
  
  .popupModeWindow {
      padding: 40px;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
      border-radius: 10px;
      min-height: 600px;
  }
  
  .iframe {
      border: 0 none transparent;
  }
  .viewFrame {
      width: 80rem;
      height: calc(100vh - 120px);
  }
  .viewImage {
      overflow: auto;
      max-height: 100%;
  }
  .viewImageSpan {
      display: inline-block;
  }
  .sizeControl {
      position: absolute;
      margin-left: 20px;
      width: 90px;
      top: 0;
      left: 80rem;
      text-align: center;
      cursor: pointer;
      color: var(--color_gray);
      font-size: var(--fontSize_h3);
      font-weight: bold;  
      z-index:15;
      display: flex;
      align-items: center;
      & > div {
          flex-grow: 1;
      }
      & .percent {
          font-weight: normal;
          font-size: var(--fontSize_standart);
          margin: 5px 0;
      }
  }
  .preframe {
      width: 100%;
      height: 0;
      position: relative;
  }
  .clauseScreen {
      position:fixed;
      height: 100%;
      width: 100%;
      top:0;
      left:0;
      background-color: #ffffff12;
  }
  
  .rulerWrapper {
      width: calc(80rem + 480px);
      margin: auto;
      position: sticky;
      top: 110px;
      background-color: var(--color_white);
      /* height: 0px; */
  }