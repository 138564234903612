.wrapper {
    position: absolute;
    font-size: var(--fontSize_tip);
    padding: 10px;
    color: var(--color_lightGray2);
    background-color: var(--color_darkGray);
    border-radius: 4px;
    transition: opacity .2s, visibility .2s;
    user-select: none;
    cursor: default;
    z-index: 12;
    white-space: nowrap;
    &::before {
        content: "";
        border: solid transparent;
        position: absolute;
        border-width: 9px;
    }
}

.header {
    font-weight: bold;
    padding-bottom: 10px;
    margin-top: -2px;
}
.top::before {
    bottom: 100%;
    border-bottom-color: var(--color_darkGray);
    border-top: none;
}
.bottom::before {
    top: 100%;
    border-top-color: var(--color_darkGray);
    border-bottom: none;
}
.center::before {
    left: calc(50% - 8px);
}
.left::before {
    left: 10px;
}
.right::before {
    right: 10px;
}