.wrapper {
    position: relative;
    height: fit-content;
    border-radius: 0 0 5px 5px;
    padding: 0 2px 15px 0;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    transition: left .2s;
    left: -80px;
    border-top: 1px solid var(--color_lightGray);

    &._open {
        transition: left .2s, box-shadow .1s .1s;
        left: 0;
        box-shadow: none;
    }

    &.fullMode {
        border-top: none;
        padding: 0 2px 35px 0;
        .paraList {
            padding: 0 20px 0 35px;
            max-height: calc(100vh - 410px);
        }
    }
    &.b:not(.fullMode) .paraList {
        max-height: calc(100vh - 294px);
    }
}

.title {
    line-height: normal;
    margin-left: 40px;
    margin-bottom: 18px;
}

.paraList {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 262px);
    overflow-y: hidden;
    font-size: var(--fontSize_standart);
    padding: 20px 0 0 15px;
    color: var(--color_darkGray);

    scrollbar-color: #cacaca transparent;
    scrollbar-width: thin;
    &:hover {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -moz-overflow-scrolling: touch;
        -ms-overflow-scrolling: touch;
        -o-overflow-scrolling: touch;
        overflow-scrolling: touch;
        .paraItem::after {
            width: 36px;
        }
    }
    &::-webkit-scrollbar {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        margin-right: 2px;
        border-radius: 2px;
        background-color: #cacaca;
        box-shadow: 0 0 1px rgba(255,255,255,0.5);
    }
    &.recentParaList {
        padding-bottom: 32px !important;
    }
}

.paraItem_wrapper {
    position: relative;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    &._chg::before {
        content: '';
        position: absolute;
        left: -13px;
        top: 2px;
        height: calc(100% - 4px);
        width: 2px;
        border-radius: 2px;
        background-color: var(--color_orange);
    }
    &._current .paraItem {
        background-color: var(--color_lightGray2);
    }
}
.paraItem {
    padding: 5px 0 5px 5px;
    border-radius: 4px;
    overflow: hidden;
    transition: background-color .2s, color .2s;
    &:hover {
        color: var(--color_primary_hover)
    }
    & span {
        margin-right: 15px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 24px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    }
}
.popupPara {
    position: absolute;
    color: var(--color_primary_hover);
    font-size: var(--fontSize_standart);
    top: 0;
    left: 15px;
    padding: 5px;
    max-width: 340px;
    overflow: hidden;
    white-space: nowrap;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s, opacity .2s;
    & > div {
        padding: 5px;
        border-radius: 4px;
        & > span {
            margin-right: 15px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 300px;
        width: 40px;
        height: 33px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    }
    &._current > div {
        background-color: var(--color_lightGray2);
    }
    &._vis {
        opacity: 1;
        visibility: visible;
        transition: visibility .2s, opacity .2s;
    }
}
