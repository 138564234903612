.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    padding: 40px 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 15;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    position: relative;
    color: var(--color_black);
    padding: 40px;
    width: 760px;
    height: fit-content;
    border-radius: 20px;
    background: white;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    margin: auto;
}

.title {
    font-size: var(--fontSize_h1);
    font-weight: bold;
    padding-bottom: 40px;
    line-height: 40px;
}

.current {
    font-size: var(--fontSize_h3);
    padding-bottom: 20px;
    span {
        font-weight: bold;
    }
}

.desc {
    font-size: var(--fontSize_tab);
    width: 240px;
    color: var(--color_gray);
}
.red {
    color: var(--color_darkRed);
}
.gray {
    color: var(--color_gray);
}

.plan-basic {
    width: 320px;
    border-radius: 10px;
    padding: 40px;
    color: var(--color_black);
    background-color: rgba(255, 238, 223, 0.51);
    cursor: pointer;
    transition: all .2s;
    &-desc {
        font-size: var(--fontSize_tab);
        opacity: 0.6;
        padding-bottom: 40px;
    }
    &-title {
        font-size: var(--fontSize_h2);
        font-weight: bold;
        padding-bottom: 10px;
    }
}
.plan-basic:hover, .selected {
    background-color: #ffeedf;
}
.plan-basic.selected {
    color: #fff;
    background-color: var(--color_orange);
}

.price {
    width: 210px;
    display: flex;
    padding-bottom: 20px;
    > div {
        height: 40px;
        padding-right: 5px;
    }
    > div:nth-child(1) {
        font-size: 25px;
    }
    > div:nth-child(2) {
        font-size: 50px;
        transform: translateY(-15px);
    }
    > div:nth-child(3) {
        width: 101px;
        font-size: var(--fontSize_h3);
        padding-right: 0;
    }
}
.price2 {
    margin-left: 35px;
    width: 125px;
    display: flex;
    justify-content: space-between;
    opacity: 0.6;
    > div {
        height: 30px;
    }
    > div:nth-child(1) {
        font-size: 13px;
        padding-right: 5px;
    }
    > div:nth-child(2) {
        font-size: 25px;
        padding-right: 7px;
        transform: translateY(-5px);
    }
    > div:nth-child(3) {
        width: 76px;
        font-size: var(--fontSize_standart);
        transform: translateY(-4px);
    }
}

.plan-renew {
    margin-top: -10px;
    display: flex;
    align-items: flex-start;
    transform: translateY(10px);
    &-head {
        font-size: var(--fontSize_h3);
        color: var(--color_gray);
        padding-bottom: 10px;
    }
    &-months {
        position: relative;
        width: 215px;
        padding-right: 80px;
        font-size: 25px;
    }
    &-total {
        width: 182px;
        max-width: 200px;
        padding-right: 80px;
        &-sum {
            white-space: nowrap;
            transform: translateY(-8px);
            font-size: 50px;
            span {
                display: inline-block;
                transform: translateY(-15px);
                font-size: 25px;
            }
        }
    }
    &-checkBtn {
        align-self: center;
        button {
            height: 40px;
        }
        .paymentDesc {
            display: inline-block;
            width: 115px;
            font-size: var(--fontSize_standart);
            padding-left: 20px;
            color: var(--color_gray);
            transform: translateY(4px);
        }
    }
}

.monthsUp, .monthsDown {
    cursor: pointer;
    position: absolute;
    right: 75px;
    top: 33px;
    width: 18px;
    height: 13px;
    &:before, &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 4px;
        width: 1px;
        height: 10px;
        background-color: #979797;
        transform: rotate(45deg);
        transition: background-color .2s;
    }
    &:after {
        transform: rotate(-45deg);
        left: 11px;
    }
}
.monthsDown {
    top: 45px;
    &:before {
        transform: rotate(-45deg);
    }
    &:after {
        transform: rotate(45deg);
    }
}
.monthsUp:hover {
    &:before, &:after {
        background-color: #555555;
    }
}
.monthsDown:hover {
    &:before, &:after {
        background-color: #555555;
    }
}

.calc {
    width:100%;
    height: 110px;
    margin: 32px auto 0 auto;
    button {
        margin-top: 10px;
    }
    &-wrapper {
        position: relative;
        border-radius: 10px;
        background-color: #ffffff;
        //box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    }
    &-choicePlan {
        float:left;
    }
    &-choice {
        font-size: var(--fontSize_h3);
    }
    &-plan {
        font-size: 22px;
        letter-spacing: 0.6px;
        width: 420px;
    }
    &-stripe {
        font-size: var(--fontSize_tab);
        width: 161px;
        margin-left: 18px;
        color: var(--color_gray);
        float:left;
    }
    &-btn{
        width: 220px;
        height: 40px;
        border-radius: 6px;
        font-size: var(--fontSize_tab);
        background-color: var(--color_primary_hover);
        color: #ffffff;
        border: none;
        font-weight: 300;
        display: block;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover{
            background-color: var(--color_primary_hover__dark);
        }
        float:left;
        &._decline {
            margin-left: 40px;
            opacity: 0.8;
        }
        &._disable {
            opacity: 0.2;
            cursor: initial;
        }
    }
}