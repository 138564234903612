.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_standart);
  line-height: 35px;
  font-weight: bold;
  color: #fff;
  border-radius: 50%;
  background-color: #ababab;
  z-index: 4;
  cursor: default;
  padding-top: 3px;
  &._pointer {
    cursor: pointer;
  }

  &._user-list {
    cursor: default;
    width: 25px;
    height: 25px;
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
    padding-top: 0;
  }

   &._small-user {
      cursor: default;
     width: 25px;
     height: 25px;
     padding-top: 0;
      margin-right: 15px;
      margin-left: 15px;
    }

  &._small {
    width: 25px;
    min-width: 25px;
    height: 25px;
    font-size: var(--fontSize_tip);
    display: flex;
    line-height: 25px;
    padding-top: 0;
  }

  &._border {
    transform: translateY(4px);
    padding-top: 0;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;
      border: 2px solid white;
      border-radius: 50%;
    }
    &::after {
      border-color: var(--color_gray);
      top: -4px;
      left: -4px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
    }
  }

  &._medium {
    display: flex;
    font-size: var(--fontSize_tip);
    width: 25px;
    height: 25px;
    margin: 0 3px;
    line-height: 25px;
    padding-top: 0;
  }

  &._centered {
    margin: 0 3px;
  }

  &._deleted {
     opacity: 0.5;
   }

  &._no-external {
    &._with-outline {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ababab;
      width: 25px;
      height: 25px;
      line-height: 25px;
      padding-top: 0;
    }
  }

  &._external {
    background-color: var(--color_primary);

    &._with-outline {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--color_primary);
       width: 25px;
       height: 25px;
       line-height: 25px;
       padding-top: 0;
    }
  }
}
