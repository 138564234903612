.wrapper {
    margin-top: 50px;
    padding-bottom: 100px;
}
.pages-block {
    position: relative;
    width: calc(100% + 80px);
    padding: 0 40px;
    left: -40px;
    border-bottom: 1px solid var(--color_lightGray2);
}
.pages {
    display: inline-block;
    margin-right: 40px;
    padding-bottom: 15px;
    font-size: var(--fontSize_tab);
    line-height: 1.36;
    letter-spacing: 0.01px;
    color: var(--color_darkGray);
    cursor: pointer;
    height: 34px;
    .counter {
        display: inline-block;
        margin-left: 11px;
        font-size: var(--fontSize_tip);
        line-height: 20px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: var(--color_darkRed);
    }
    &._isSelected {
        border-bottom: solid 5px var(--color_lightGray2);
    }
}
.page-wrapper {
    margin: 50px auto 0 auto;
    width: fit-content;
    min-width: 750px;
}
.teams-wrapper {
    width: 890px;
}
.company-title_input {
    width: 748px;
    color: var(--color_darkGray);
    font-size: var(--fontSize_h2);
    padding: 10px 15px;
    height: 46px;
    border: solid 1px var(--color_lightGray);
    border-radius: 4px;
    margin-bottom: 55px;
}
.companyName-error {
    margin-top: -45px;
    margin-bottom: 27px;
    color: var(--color_darkRed);
}
.company-details {
    display: flex;
    margin-bottom: 40px;
    &-column {
        width: 345px;
        margin-right: 50px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.company-details_input, .addMembers_input {
    width: 320px;
    color: var(--color_darkGray);
    font-size: var(--fontSize_tab);
    height: 39px;
    padding: 10px 15px;
    border: solid 1px var(--color_lightGray);
    border-radius: 4px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}
.company-details_input {
    width: 345px;
}

.members-wrapper {

}

.teams {
    padding-top: 80px;
}

.teamsList {
    margin-top: 40px;
}
.DeM-list {
    margin-top: 20px;
    > div {
        transform: translateX(-40px);
    }
    > div:not(:last-child) {
        margin-bottom: 20px;
    }
}

.deactivatedMembersList {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    > div {
        width: fit-content;
        margin-right: 40px;
    }
    > button {
        margin-right: 20px;
    }
}

.members {
    margin-top: 40px;
    &-head {
        display: flex;
        font-size: var(--fontSize_standart);
        color: var(--color_gray);
        padding-bottom: 10px;
        border-bottom: 1px solid var(--color_lightGray2);
    }
    &-list {

    }
}
.members-spinner > div {
    top: 500px;
}
.members-head_col {
    padding-right: 20px;
    &:last-child {
        padding-right: 0;
    }
}

.col1 {
    width: 240px;
}
.col2 {
    width: 160px;
}
.col3 {
    width: 225px;
}
.col4 {
    width: 152px;
}
.col5 {
    width: 90px;
}
.col6 {
    position: relative;
    width: 20px;
}

.userLine-wrapper {
    position: relative;
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-items: flex-start;
    &:not(:last-child) {
        border-bottom: 1px solid var(--color_lightGray);
    }
    > div {
        padding-right: 20px;
        &:last-child {
            padding-right: 0;
        }
    }

    label > div:first-child {
        display: none;
    }
    label > div:not(:first-child) {
        > a {
            margin-left: 0;
        }
    }
}
.userLine-edit_btn {
    position: absolute;
    top: -8px;
    left: 2px;
    border: none;
    background: transparent;
    transition: all 0.2s;
    font-size: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1px 6px;
    & > span {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--color_darkGray);
        display: inline-block;
        &:nth-child(2) {
            margin: 3px 0;
        }
    }
    &:hover{
        & > span {
            background-color: var(--color_primary_hover__dark);
        }
    }
    &_open {
        z-index: 3;
    }
}
.userLine-settings {
    position: absolute;
    top: -48px;
    right: -28px;
    width: 220px;
    cursor: default;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    z-index: 2;
    > button:not(:last-child) {
        margin-bottom: 12px;
    }
}

.addMember-wrapper {
    padding: 40px 0;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    overflow: auto;
}
.addMember {
    position: relative;
    padding: 40px;
    margin: 0 auto;
    width: 400px;
    min-height: 595px;
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    font-size: var(--fontSize_tab);
}

.addMember-addTeamBtn, .showDeM-btn {
    cursor: pointer;
    width: fit-content;
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    border-bottom: 1px dashed var(--color_primary);
    padding-top: 1px;
    padding-bottom: 4px;
    height: 20px;
    transition: all .2s;
    &:hover {
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}

.addMember-inputs {
    margin-bottom: 50px;
}
.addMember-access {
    margin-bottom: 50px;
    &-active {
        position: relative;
        width: fit-content;
        color: var(--color_darkGray);
    }
    &-current {
        cursor: pointer;
        transition: color .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}

.addMember-access-popup {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 280px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    z-index: 2;
}
.addMember-access-popup.superAdmin {
    top: -212px;
}
.addMember-access-popup.admin {
    top: -118px;
}

.arrow._open {
    &:after {
        transform: rotate(-45deg);
    }
    &:before {
        transform: rotate(45deg);
    }
}
.arrow {
    position: absolute;
    top: 0;
    right: -24px;
    width: 15px;
    height: 19px;
    cursor: pointer;
    z-index: 3;
    &:hover {
        &:after, &:before {
            background-color: #515151;
        }
    }
    &:after, &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 8px;
        right: 5px;
        transform: rotate(45deg);
        top: 9px;
        border-radius: 1px;
        background-color: #979797;
        transition: background-color .2s;
    }
    &:before {
        transform: rotate(-45deg);
        right: 0;
    }
}

.addMember-access-select {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    > div {
        cursor: pointer;
        color: var(--color_darkGray);
        margin-bottom: 10px;
        transition: color .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
    > span {
        color: var(--color_gray);
        font-size: var(--fontSize_standart);
    }
    .superAdmin {
        color: var(--color_darkRed);
        &:hover {
            color: var(--color_darkRed_hover);
        }
    }
}


.addMember-team {
    position: relative;
    margin-bottom: 50px;
    &-current {
        cursor: pointer;
        width: fit-content;
        color: var(--color_darkGray);
        transition: color .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}
.addMember-team-popup-screen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 5;
}
.addMember-team-popup {
    position: absolute;
    padding: 40px;
    margin-bottom: 40px;
    width: 401px;
    height: fit-content;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    top: 70px;
    left: -40px;
    z-index: 5;
    &-line {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        transition: color .2s;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &:hover {
            color: var(--color_primary_hover);
        }
        &-checkbox {
            display: inline-block;
            width: fit-content;
            > div > span > svg {
                transform: translateY(-2px);
            }
        }
        &-teamName {
            display: inline-block;
            margin-left: 5px;
        }
    }
}

.addTeam-parentTeams {
    position: relative;
}

.addTeam-parentTeamsList {
    > span {
        display: inline-block;
        position: relative;
        font-size: var(--fontSize_tab);
        color: var(--color_darkGray);
        padding-right: 20px;
        padding-bottom: 20px;
        > span {
            top: -4px;
            right: 0;
        }
    }
}

.addMember-billing {
    margin-bottom: 50px;
}

.addTeam-wrapper {
    padding: 40px 0;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addTeam {
    position: relative;
    padding: 40px;
    margin: auto;
    min-width: 400px;
    height: fit-content;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
}
.addTeamFake {
    height: 800px;
}
.addTeamsCont {
    display: flex;
    margin: 40px 0 40px;
    width: fit-content;
    position: relative;
}
.addTeamsPart {
    width: 360px;
    padding-right: 40px;
}
.addTeamsPart+.addTeamsPart {
    border-left: solid 1px var(--color_lightGray2);
    padding: 0 0 0 40px;
}
.addTeamsBtn-row {
    button {
        margin-right: 10px !important;
    }
}

.addTeam-input {
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    width: 100%;
    height: 37px;
    padding: 9px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px var(--color_lightGray);
    background-color: #ffffff;
}
.teamName-error {
    position: absolute;
    left: 40px;
    top: 150px;
    color: var(--color_darkRed);
    font-size: var(--fontSize_tab);
}
.emailError {
    color: var(--color_darkRed);
    font-size: var(--fontSize_standart);
    margin-bottom: 20px;
}

.TL-wrapper {
    margin-bottom: 50px;
}
.TL-head {
    position: relative;
    margin-bottom: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.TL-head:hover .TL-head-controls {
    opacity: 1;
    visibility: visible;
}
.TL-head-controls {
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    padding-left: 40px;
}
.TL-titleS:hover ~ .TL-hint {
    opacity: 1;
    visibility: visible;
}
.TL-hint {
    position: absolute;
    top: -37px;
    left: -55px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--color_darkGray);
    font-size: var(--fontSize_tip);
    color: var(--color_lightGray2);
    transition: all .2s;
    z-index: 5;
    &:after {
        content: "";
        border: solid transparent;
        position: absolute;
        top: 100%;
        right: 20px;
        border-top-color: var(--color_darkGray);
        border-width: 7px;
    }
}
.TL-title {
    position: relative;
    font-weight: bold;
    color: var(--color_darkGray);
    cursor: pointer;
    &_close {
        &:after {
            transform: rotate(45deg);
        }
        &:before {
            transform: rotate(-45deg);
        }
    }
}
.TL-inputName {
    font-weight: bold;
    color: var(--color_darkGray);
    border: none;
    padding-left: 0;
}
.TL-btnEdit {
    display: inline-block;
    cursor: pointer;
    > img {
        width: 12px;
        height: 12px;
    }
}
.TL-btnAdd {
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    padding-bottom: 2px;
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    border-bottom: dashed 1px var(--color_primary);
    transition: all .2s;
    &:hover {

    }
}
.TL-userList {
    > div {
        padding-bottom: 20px;
        > label {
            width: fit-content;

            > div:first-child {
                display: none;
            }

            > div:not(:first-child) > a {
                margin-left: 0;
            }
        }
    }
}
.TL-subTeamsList {
    padding-left: 40px;
}

.listCandidates {
    > div {
        padding-bottom: 20px;
    }
}
.listUser {
    > div {
        padding-bottom: 10px;
    }
}

.billing-wrapper {

}

.saveBtnRow {
    margin-top: 40px;
}

.passwordPopup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 5;
}
.passwordPopup-card {
    position: relative;
    padding: 40px;
    width: 400px;
    border-radius: 20px;
    background-color: white;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    color: var(--color_black);
}
.passwordPopup-title {
    font-size: var(--fontSize_h2);
    font-weight: bold;
    margin-bottom: 40px;
}
.passwordPopup-text {
    font-size: var(--fontSize_h3);
    margin-bottom: 20px;
}
.passwordPopup-input {
    padding: 10px 15px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--color_lightGray);
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
}
.passwordPopup-btnRow {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}


