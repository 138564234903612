.header {
  font-family: var(--fontFamily);
  position: relative;
  min-width: 1346px;
  &._future {
      min-width: auto;
  }
  &._fixed {
    position: fixed;
    width: 100%;
    max-width: inherit;
    z-index: 3;
  }
    &._bg {
        background: rgba(255, 255, 255, 0.92);
    }
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
    opacity: 0.9;
    &._visible {
       display: block;
       z-index: 4;
    }
}

.wrapper {
  padding: 40px;
  min-height: 120px;
  display: flex;
  align-items: center;
    justify-content: space-between;
}

.transparent {
    height: 50%;
    background: linear-gradient(to bottom, white, rgba(255,255,255,0));
}

.title-version {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto auto auto 23px;
}

.titleLink {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.title {  
  font-size: var(--fontSize_tab);
  line-height: 1.29;  
  letter-spacing: 0;
  text-align: left;
  color: var(--color_black);
  position: relative;
  top: 2px;
  margin-right: 50px;
}

.version {
  font-size: var(--fontSize_tab);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0;
  text-align: left;
  color: var(--color_orange);
  position: relative;
  top: 2px;
}

.left {
    align-items: center;
    display: flex;
}

.right {
  margin: auto 0 auto 0;
  align-items: center;
  display: flex;
}

.clink {
    color: var(--color_primary);
    display: inline-block;
    padding-right: 20px;
    padding-left: 20px;
    font-size: var(--fontSize_tab);
    font-weight: bold;
}

.helpBtn {
    color: var(--color_primary);
    font-size: var(--fontSize_standart);
    font-weight: bold;
    width: 35px;
    height: 35px;
    line-height: 33px;
    border-radius: 50%;
    border: solid 1px var(--color_primary);
    margin-right: 20px;
    text-align: center;
    text-decoration: none;
    z-index: 6;
}

.teams {   
  color: var(--color_primary);
  font-size: var(--fontSize_standart);
  border: 0;
  transition: color 0.2s;
  padding: 12px 0px;
  cursor: pointer;
  vertical-align: top;
  margin-right: 20px;
  &:hover {
    color: var(--color_primary_hover);
  }
}

.mission {
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    font-weight: bold;
    span:nth-child(1) {
        color: var(--color_primary_hover);
    }
    span:nth-child(2) {
        color: var(--color_lightRed);
    }
    span:nth-child(3) {
        display: block;
        font-weight: normal;
    }
}
.header-mission {
    margin-left: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
}
.header-dataProtection {
    margin-left: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
    > a {
        text-decoration: none;
        color: var(--color_darkGray);
        transition: color .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}

.promoButton, .promoButtonArch, .promoButtonFill {
    color: rgba(255, 255, 255, 0.8);
    padding: 12px 0;
    border-radius: 6px;
    font-size: var(--fontSize_tab);
    border: none;
    background-color: rgba(255, 255, 255, 0);
    transition: all .2s;
    &._dark {
        color: var(--color_primary);
        border-color: var(--color_primary);
    }
    &:hover {
        cursor: pointer;
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}
.promoButtonFill, .promoButtonArch {
    padding: 11px 20px;
    border: 1px solid rgba(255, 255, 255, 0);
}
.promoButtonFill {
    border-color: var(--color_primary);
    background-color: white;
    color: var(--color_primary);
    &._dark:hover {
        background: var(--color_primary_hover);
    }
    &._dark {
        color: white;
        background-color: var(--color_primary);
        border-color: white;
    }
}
.promoButtonArch {
    border-color: rgba(255, 255, 255, 0.8);
    transition: all .2s;
    &._dark {
        background-color: #fff;
    }
}

.rightMobile {
    position: fixed;
    top: 40px;
    right: 40px;
    display: none;
    > img {
        position: relative;
        cursor: pointer;
        z-index: 2;
    }
    &_popup {
        position: absolute;
        padding: 40px;
        top: -40px;
        right: -40px;
        background-color: #fff;
        width: 250px;
        z-index: 1;
        border-radius: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
        &._open {
            opacity: 1;
            visibility: visible;
        }
    }
}

@media screen and (max-width: 767px) {
    ._future {
        position: absolute !important;
        .wrapper {
            min-height: auto;
        }
        .right {
            display: none;
        }
        .rightMobile {
            display: block;
        }
    }
}
