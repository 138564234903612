.header-wrapper {
    z-index: 8;
    background-color: #fff;
    width: calc(80rem + 440px);
    min-width: 100%;
    padding: 32px 0 32px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    transition: all .2s;
}

.belowTop-frame-width {
    height: 0px;
    z-index: 9;
    top: 115px;
    width: calc(80rem + 490px);
    position: relative;
    margin: auto;
}

.underComments-absolute {
    position: absolute;
    width: 488px;
    height: fit-content;
    left: calc(80rem + 2px);
}

.header-wrapper-mask {
  position: absolute;
  top: 0;
  left: -40px;
  background: white;
  height: 100%;
  width: calc(100% + 80px);
}    

.header {
    position: relative;
    display: flex;
    align-items: baseline;
    font-family: var(--fontFamily);
    height: 50px;
    z-index: 1;
    min-width: fit-content;
}
.logoplace {
    position: relative;
    top: 13px;
    align-self: start;
}

.header-sideBarButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px;
    width: 19px;
    margin-right: 40px;
    margin-left: 40px;

    img {
        width: 14px;
        height: 15px;
    }
}

.header-projectNameBlock, ._SAFARI_header-projectNameBlock {
    display: flex;
    overflow: visible;
    color: var(--color_darkGray);
    font-size: var(--fontSize_standart);
    white-space: nowrap;
    width: auto;
    position: relative;
    a {
        width: auto;
        height: 14px;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--color_darkGray);
        transition: all .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}

.header-collaborationMode {
    position: absolute;
    color: var(--color_lightRed);
    left: 0;
    top: 15px;
    padding-top: 2px;
    background-color: white;
    z-index: 1;
}
.header-versionBlock {
    position: relative;
    width: 338px;
    height: 100%;
    .header-collabArrow, .header-collabArrow-left {
        right: -11px;
    }
    &_open {
        .header-versionButtons {
            width: 537px;
            &::after {
                display: none;
            }
            &-grad {
                right: -235px;
            }
        }
        .header-collabArrow, .header-collabArrow-left {
            right: -209px;
        }
    }
}
.header-versionButtons {
    position: absolute;
    top: 0;
    left: 1px;
    display: flex;
    align-items: center;
    width: 338px;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s;
    z-index: 3;
    &-grad {
        position: absolute;
        top: 0;
        right: -36px;
        height: 50px;
        width: 35px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        z-index: 3;
        transition: all .2s;
    }
    > button:last-child {
        margin: 0 40px;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}
.header-noProjectsText {
    color: var(--color_lightRed);
    font-size: var(--fontSize_standart);
    white-space: nowrap;
    padding-left: 40px;    
}
.header-descText {
    color: var(--color_darkGray);
    font-size: var(--fontSize_standart);
    white-space: nowrap;
}

.header-version-cont {
    margin-right: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_lightRed);
    position: relative;
    flex-shrink: 0;
}
.header-version {
    font-size: var(--fontSize_tip);
    color: var(--color_lightGray2);
    top: 18px;
}
.header-version-button {
    cursor: pointer;
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    flex-shrink: 0;
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
}
.bold {
    font-weight: bold;
    position: relative;
    top: 1px;
}


.header-titleSeparator {
    display: inline-block;
    padding: 0 10px;
    height: 14px;
}

.header-projectName {
    height: 14px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-documentName {
    height: 16px;
    position: relative;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
}

.documentNameGrad {
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}
.header-finalize {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 0 40px;
    height: 50px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
    &-text {
        position: relative;
        color: var(--color_green);
        height: 14px;
        padding-right: 40px;
        white-space: nowrap;
        &::after, &::before {
            top: 3px;
            content: '';
            position: absolute;
            left: -15px;
            bottom: 0;
            height: 10px;
            width: 2px;
            background-color: var(--color_green);
            transform: rotate(45deg);
        }
        &::before {
            top: 6px;
            height: 6px;
            transform: rotate(-45deg);
            left: -20px;
        }
    }
    &-new {
        position: relative;
        padding-top: 15px;
        height: 100%;
        padding-left: 40px;
    }
    &-text_noCheckMark {
        position: relative;
        color: var(--color_green);
        height: 14px;
        padding-right: 40px;
        white-space: nowrap;
    }
    &-time {
        height: 14px;
        margin-right: 40px;
        width: fit-content;
        overflow: hidden;
        color: var(--color_gray);
        white-space: nowrap;
    }
    button {
        margin-right: 40px;
        
    }
}

.header-finalize-new_popup {
    position: absolute;
    top: -20px;
    left: -1px;
    padding: 70px 40px 40px;
    width: 208px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    transition: all .2s;
    > button {
        white-space: normal !important;
        margin-right: 0 !important;
        text-align: left !important;
        line-height: 18px;
        height: fit-content !important;
        &:first-child {
            margin-bottom: 15px;
        }
    }
    &_visible {
        opacity: 1;
        visibility: visible;
    }
}

.header-reviewed {
    position: relative;
    padding: 22px 0 0 40px;
    height: 50px;
    min-width: 248px;
    max-width: 283px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
    &-text {
        max-width: 240px;
        height: 14px;
        padding-right: 40px;
        white-space: nowrap;
        overflow: hidden;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 35px;
            height: 100%;
            background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
        }
    }
    &-time {
        height: 14px;
        width: fit-content;
        overflow: hidden;
        color: var(--color_gray);
        white-space: nowrap;
    }
}
.header-reviewed .header-hintPlace {
    z-index: 6;
    left: 30px;
    top: 8px;
    padding: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 6px;
    > div:last-child {
        color: var(--color_gray);
    }
    > div {
      padding: 0;
      width: fit-content;
      border-radius: 0;
      box-shadow: none;
        &::after {
            display: none;
        }
    }
}
.header-reviewed:hover .header-hintPlace {
    visibility: visible;
    opacity: 1;
}

.header-projectNameBlock:hover .header-hintPlace {
    visibility: visible;
    opacity: 1;
}

.header-hintPlace {
    border-radius: 6px;
    color: var(--color_darkGray);
    visibility: hidden;
    opacity: 0;
    left: -10px;
    top: -10px;
    position: absolute;
    transition: all .2s;
    z-index: 10;
    a {
        text-decoration: none;
        color: var(--color_darkGray);
        transition: all .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
    div {
        width: auto;
        border-radius: 6px;
        color: var(--color_darkGray);
        font-size: var(--fontSize_standart);
        padding: 10px;
        white-space: nowrap;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
        background-color: #fff;
    }
}

.header-buttonBlock {
    position: relative;
    padding: 0 40px;
    //max-width: 455px;
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;
    button {
        margin: 0;
    }
}

.header-extReqEdit {
    position: relative;
    width: 105px;
    margin-right: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_lightRed);
    height: 14px;
    > span {
        position: absolute;
        top: 30px;
        left: 0;
    }
}
.header-redAlert {
    width: fit-content;
    margin-right: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_lightRed);
    height: 14px;
    white-space: nowrap;
}

.header-approvalButton {
    z-index: 7;
    position: relative;
    background-color: transparent;
}

.header-approvalButton button {
    margin-right: 40px;
}

.header-collabButtonsBlock {
    position: relative;
    width: 70px;
    height: 100%;
    &_open {
        .header-collabButtons {
            width: 287px;
            padding-right: 40px;
            &::after {
                display: none;
            }
            &-grad {
                right: -253px;
            }
        }
        .header-collabArrow, .header-collabArrow-left {
            right: -227px;
        }
    }
    &_open2 {
        .header-collabButtons {
            width: 153px;
            padding-right: 40px;
            &::after {
                display: none;
            }
            &-grad {
                right: -119px;
            }
        }
        .header-collabArrow, .header-collabArrow-left {
            right: -93px;
        }
    }
}
.header-collabButtons {
    position: absolute;
    top: 0;
    left: 1px;
    padding-left: 40px;
    padding-right: 0;
    display: flex;
    align-items: center;
    width: 70px;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s;
    z-index: 3;
    &-grad {
        position: absolute;
        top: 0;
        right: -36px;
        height: 50px;
        width: 35px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        z-index: 3;
        transition: all .2s;
    }
    > button:first-child {
        margin-right: 40px;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}

.header-collabArrow, .header-collabArrow-left {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #fff;
    right: -12px;
    top: 16px;
    transition: all .2s;
    z-index: 4;
    &::before {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: #979797;
        top: 3px;
        left: 9px;
        width: 1px;
        height: 8px;
        transform: rotate(-45deg);
    }
    &::after {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: #979797;
        top: 8px;
        left: 9px;
        width: 1px;
        height: 8px;
        transform: rotate(45deg);
    }
}
.header-collabArrow-left {
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

.header-buttonBlock2, .header-buttonBlock2-edit {
    width: auto;
    padding-right: 40px;
    height: 50px;
    display: flex;
    align-items: center;

    &-showNew {
        margin: 0;
    }

    &-tasks {
        height: 50px;
        transform: translateY(-6px);
        display: flex;
        align-items: center;
        margin: 0 0 0 40px;
        z-index: 2;
    }

    &-m40 {
        margin-left: 40px;
    }

    > div {
        display: flex;
        align-items: center;
    }

    button {
        line-height: 8px;
        margin: 0;
    }
}
.header-buttonBlock2-edit {
    padding-left: 40px;
    button {
        width: 95px;
        &:first-child {
            margin-right: 40px;
        }
    }
}
.header-buttonBlock2-editRM button {
    margin-right: 0 !important;
}

.counter {
    margin-right: 11px;
    font-size: var(--fontSize_tip);
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: var(--color_darkRed);
}

.header-userLogo {
    width: 100%;
    min-width: 35px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    align-self: start;
    padding-top: 7px;
}

.sidebar-screen {
    position: fixed;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: #ffffffB0;
    transition: all .2s;
    z-index: 7;
    &._vis {
        visibility: visible;
        opacity: 1;
    }
}
.sidebar-wrapper {
    position: fixed;
    background-color: #fff;
    width: 400px;
    height: 100%;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    left: 0;
    top: 0;
    z-index: 7;
    transition: all .2s;
    &._vis {
        visibility: visible;
        opacity: 1;
    }
}

.sidebar_progressAndTasks {
        position: absolute;
        background-color: #fff;
        left: 400px;
        top: 0;
        width: auto;
        min-width: 801px;
        height: 100%;
        margin-left: -800px;
        transition: all .5s;
        &_vis {
            margin-left: 0;
        }
}

.sidebar {
    position: relative;
    padding-top: 86px;
    padding-right: 40px;
    padding-left: 40px;
    &-header {
        position: absolute;
        top: -8px;
        left: 40px;
        height: 50px;
        width: calc(100% - 40px);
        display: flex;
        align-items: baseline;
        &-close{
            margin-left: 40px;
            cursor: pointer;
            height: 15px;
            width: 14px;
        }
    }
}

.sidebar-toAllProjects {
    display: flex;
    margin-bottom: 50px;

    button {
        margin-right: 11px !important;
    }
}

.document {
    position: relative;
    color: var(--color_darkGray);
    margin-bottom: 20px;
    padding-left: 40px;
    > span {
        font-size: var(--fontSize_tab);
    }
    img {
        position: absolute;
        left: 0;
        height: 15px;
    }
}

.sidebar-document {
    margin-bottom: 50px;

    &-desc {
        padding-left: 40px;
        color: var(--color_darkGray);
        margin-bottom: 15px;
        font-size: var(--fontSize_standart);

        div {
            padding-bottom: 2px;
        }

        span {
            color: var(--color_gray);
        }
    }

    &-buttons {
        position: relative;
        padding-left: 40px;

        .counter {
            display: inline-block;
            margin-left: 11px;
        }
        & > button, & > div:not(.expDocSpinner) {
            display: block;
            margin-right: 40px;
            margin-bottom: 10px;
            white-space: nowrap;
            > div {
                margin-right: 0;
            }
        }
        & > div:last-child, & > button:last-child {
            margin-bottom: 0;
        }
    }
}
.sidebar-documentName {
    cursor: default;
    border: none;
    line-height: 19px;
    overflow-y: hidden;
    font-size: var(--fontSize_tab);
    resize: none;
    color: var(--color_darkGray);
    width: 100%;
    height: 21px;
    padding: 0;
    &:focus {
        cursor: text;
    }
}
.sidebar-documentNameDiv {
    font-size: var(--fontSize_tab);
    line-height: 19px;
    color: var(--color_darkGray);
    height: auto;
    word-break: break-word;
}
.sidebar-documentName:hover ~ .sidebar-document-hintPlace {
    visibility: visible;
    opacity: 1;
}
.sidebar-document-hintPlace {
    color: var(--color_darkGray);
    width: auto;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    top: -35px;
    left: 5px;
    position: absolute;
    transition: all .2s;
    z-index: 4;
    div {
        width: 100%;
        position: static;
    }
}
.sidebar-export {
    background-color: #fff;
    width: 240px;
    height: 63px;
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    font-weight: 400;
    filter: grayscale(.75);
    position: absolute;
    box-shadow: var(--shadow_menu);
    top: -70px;
    bottom: 0;
    right: -20px;
    &::before {
        content: '';
        position: absolute;
        background-color: #fff;
        top: calc(100% - 7px);
        right: 18px;
        width: 13px;
        height: 13px;
        box-shadow: var(--shadow_menu);
        transform: rotate(45deg);
        border: 1px solid #f2f2f2;
    }
    &-message {
        position: absolute;
        z-index: 1;
        background-color: #fff;
        padding: 15px 17px 16px;
    }
}

.sidebar-thisProject {
    position: static;
    &-buttons {
        display: flex;
    }
    &-title {
        font-size: var(--fontSize_tab);
        margin-bottom: 20px;
        color: var(--color_darkGray);
    }
    button {
        margin-right: 20px !important;
    }
}

.sidebar-recentDocumentWrapper {
    border-bottom: 1px solid var(--color_lightGray);
    margin-top: 20px;

    &:last-child {
        border: none
    }
}

.sidebar-recentDocument .sidebar-document-desc {
    margin-bottom: 20px;
}

.sidebar-recentDocument .document {
    margin-bottom: 10px;
}

.sidebar-recentDocument {
    margin-top: 40px;
}

.sidebar-document-actions {
    display: flex;
    font-size: var(--fontSize_standart);
    color: var(--color_lightRed);
    padding-left: 40px;
    margin-bottom: 20px;
}

.sidebar-document-tasks {
}

.sidebar-document-approval {
}

.sidebar-document-comments {
}

.sidebar-document-changes {
}

.approvals-wrapper {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    background-color: #ffffff;
    left: -41px;
    top: -27px;
    padding: 101px 40px 40px 40px;
    width: 402px;
    transition: opacity .2s, visibility .2s;
}

.header-approvalButton:hover .approvals-wrapper {
    opacity: 1;
    visibility: visible;
    z-index: 2;
}

.header-approvalButton button {
    z-index: 2;
}

.header-approvalButton:hover button {
    z-index: 3;
}

.approvals-needed {
    margin-bottom: 0;

    &-text {
        font-size: var(--fontSize_h3);
        color: var(--color_black);
        margin-bottom: 20px;
    }

    button {
        margin-top: 20px;
    }
}

.approvals-approved {
}

.approvals-list {
    display: flex;
    flex-direction: column;
    position: relative;
    left: -15px;
    > div {
        position: relative;
        left: -25px;
        button {
            margin: 0;
        }
    }
    > div:not(:last-child) {
        margin-bottom: 20px;
    }
}

.header-sendButton {
    position: relative;
    //z-index: 1;
    > button {
        width: 83px !important;
    }
}

.changes-hint {
    position: relative;
    white-space: nowrap;
    padding-top: 2px;
    font-size: var(--fontSize_tip);
    color: var(--color_gray);
    z-index: 2;
    transition-delay: .2s;
    &_menuOpen {
        z-index: 6;
        transition-delay: 0s;
    }
}

.sendMenu-screen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 3;
}

.sendMenu-part {
    width: 400px;
    padding: 0 40px;
    &._thin {
      width: 240px;  
    }
    &_2 {
        border-left: 1px solid var(--color_lightGray2);
    }
    &._scroll {
        overflow-y: auto;
        scrollbar-color: #cacaca transparent;
        scrollbar-width: thin;
        &:hover {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -moz-overflow-scrolling: touch;
            -ms-overflow-scrolling: touch;
            -o-overflow-scrolling: touch;
            overflow-scrolling: touch;
        }
        &::-webkit-scrollbar {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            margin-right: 2px;
            border-radius: 2px;
            background-color: #cacaca;
            box-shadow: 0 0 1px rgba(255,255,255,0.5);
        }
    }
}

.sendMenu-wrapper {
    position: absolute;
    display: flex;
    top: -40px;
    left: -40px;
    width: fit-content;
    padding: 100px 0 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    z-index: 7;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ;
    &_vis {
        visibility: visible;
        opacity: 1;
    }
}

.sendMenu-send {
    visibility: visible;
    &-small {
        width: 150px;
        font-size: var(--fontSize_tip);
        color: var(--color_gray);

        span {
            color: var(--color_lightRed);
        }
    }
    &-smallthin {
        font-size: var(--fontSize_tip);
        color: var(--color_gray);
    }
}

.sendMenu-finalize {
    margin-top: 50px;
    &-small {
        font-size: var(--fontSize_tip);
        color: var(--color_gray);
    }
}
.sendMenu-collaborate {

}
.sendMenu-collaborate-small {
    font-size: var(--fontSize_tip);
    color: var(--color_gray);
}

.sendMenu-approvals {

}
.sendMenu-approvalsList {
    > div {
        position: relative;
        left: -40px;
    }
    > div:not(:last-child) {
        margin-bottom: 20px;
    }
}

.sendMenuB-wrapper {
    position: absolute;
    width: 800px;
    top: -40px;
    left: -168px;
    padding: 100px 40px 40px 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    z-index: 2;
    display: flex;
}

.sendMenuB-column {
    width: 320px;

    &:first-child {
        padding-right: 40px;
        border-right: 1px solid var(--color_lightGray2);
    }

    &:last-child {
        padding-left: 40px;
    }
}

.sendMenuB-editDoc {
    margin-bottom: 45px;

    &-title {
        width: 269px;
        white-space: nowrap;
        letter-spacing: 0;
        font-family: ProximaNova-Extrabold, sans-serif !important;
        font-size: var(--fontSize_tab);
        color: var(--color_black);
        margin-bottom: 15px;
    }

    &-select {
        border-radius: 10px;
        margin-bottom: 20px;
        font-size: var(--fontSize_standart);
        text-align: center;
        line-height: 25px;
        color: var(--color_darkGray);
        height: 31px;
        display: flex;
        border: 1px dashed var(--color_lightGray2);

        div {
            cursor: pointer;
            width: 100%;
            border-right: 1px dashed var(--color_lightGray2);

            &:last-child {
                border: none
            }
        }
    }

    &-selectTeam {
        height: 31px;
        display: flex;
        text-align: center;
        line-height: 25px;
        font-size: var(--fontSize_standart);
        border: 1px dashed var(--color_primary);
        color: var(--color_primary);
    }
}

.sendMenu-note > div {
    margin-bottom: -10px;
    margin-top: 20px;
}

.sendMenu_messageBtn {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    > div {
        bottom: 31px;
        left: 0;
        width: 200px;
        white-space: normal;
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
    }
    &:hover > div {
        opacity: 1;
        visibility: visible;
        transition: all .2s .5s;
    }
}

.button-active {
    background-color: var(--color_lightGray2);
    color: #fff;
}
.helpBtn {
    color: var(--color_primary);
    font-size: var(--fontSize_standart);
    font-weight: bold;
    width: 21px;
    height: 21px;
    line-height: 20px;
    border-radius: 50%;
    border: solid 1px var(--color_primary);
    text-align: center;
    text-decoration: none;
}
.sidebar-company {
    &:not(:nth-child(2)) {
        margin-top: 50px;
    }
    a {
        font-size: var(--fontSize_standart);
        color: var(--color_primary);
        text-decoration: none;
        transition: color .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}

.expDocSpinner {
    font-size: var(--fontSize_standart);
    height: 20px;
    color: var(--color_gray);
    display: inline-flex;
    align-items: center;
    transform: translate(-20px, -0.5px);
    user-select: none;
    > div {
        position: static;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px !important;
        img {
            height: 15px;
            width: 15px;
            margin: 0;
        }
    }
}

.btnFreeze {
  margin: 12px 0 10px 0px;
  font-size: var(--fontSize_standart);  
  color: var(--color_primary);
  cursor:pointer;
  &.nonFrost {
     opacity: 0.3;
     cursor: default; 
  }
}

.txtFreeze {
    font-size: var(--fontSize_tip);
    color: var(--color_gray);    
}