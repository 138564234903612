.wrapper {

}

.firstBlock {
    color: #fff;
    &_container {
        padding-top: 180px;
        padding-bottom: 300px;
        background:
                linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)),
                linear-gradient(-195deg, var(--color_orange) 17%, rgba(248, 149, 64, 0) 50%),
                linear-gradient(120deg, #ff6c6c 18%, #157efb 65%);
        /*
         background:
                linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)),
                linear-gradient(-195deg, var(--color_orange) 17%, rgba(248, 149, 64, 0) 50%),
                linear-gradient(120deg, #ff6c6c 15%, #157efb 70%);
        */
        background-size: 100% 500px, 75% 300px, 100% 100%;
        background-position: bottom, 0 0, 0 0;
        background-repeat: no-repeat, no-repeat, no-repeat;
    }

    .pageTitle {
        font-size: 80px;
        font-weight: bold;
        width: 741px;
        padding-left: 120px;
        margin: 0 auto 40px;
    }
    .pageTitleDesc {
        width: fit-content;
        padding-left: 50px;
        font-size: 30px;
        margin: 0 auto 80px;
    }
    .pageTitleButton {
        width: 500px;
        margin: 0 auto;
    }
    .c1screen {
        height: 867px;
        padding: 100px;
        margin: 20px auto 5px;
        background-image: radial-gradient(rgba(0, 171, 38, 0.5) 55%, rgba(0, 171, 38, 0) 72%, rgba(0, 171, 38, 0) 100%);
        background-repeat: no-repeat;
        background-size: 100% 95%;
        background-position: center;
        display: flex;
        justify-content: space-between;
        min-width: 1310px;
        max-width: 1310px;
        /*max-width: 1663px;*/
        > img:first-child { /* left */
            margin-right: -661px;
            margin-top: 33px;
            background-color: white;
            border-radius: 10px;
        }
        > img:not(:first-child):not(:last-child) { /* center */
            z-index: 1;
            border-radius: 10px;
            box-shadow: var(--shadow_alert);
            background-color: white;
        }
        > img:last-child { /* right */
            margin-left: -661px;
            margin-top: 33px;
            background-color: white;
            border-radius: 10px;
        }
    }
}

.featuredBlock {
    position: relative;
    top: -100px;

    &_container {
        padding: 0 60px;
        background-color: #f9f9f9;
        margin-bottom: -200px;
    }

    &_item {
        position: relative;
        color: #606060;
        font-size: 24px;
        padding: 50px 120px 100px 100px;
        border-radius: 10px;
        max-width: 1120px;
        height: 800px;
        margin: 0 auto;
        background-color: white;
        opacity: 0.8;
        overflow: hidden;
        transition: all .2s;
        &_img {
            position: relative;
            left: -100px;
            width: calc(100% + 220px);
            height: fit-content;
            overflow: auto;
            > img {

            }
        }
        &:not(:last-child) {
            margin-bottom: 100px;
        }
        *:not(.statsGradient) {
            z-index: 1;
        }
        .lightRed {
            color: var(--color_darkGray);
        }
        &:hover {
            opacity: 1;
            .lightRed {
                color: var(--color_lightRed);
            }
            .h2.darkGray {
                color: var(--color_primary);
            }
        }
    }

    &_flex {
        display: flex;
        justify-content: center;
        .featuredBlock_item {
            padding: 96px 100px 100px;
            width: 510px;
            height: 677px;
            &:first-child {
                margin-left: 0;
                margin-right: 100px;
            }
            &:not(:first-child) {
                margin-left: 0;
                margin-right: 0;
                padding-right: 90px;
            }
        }
    }
}

.benefits {
    position: static;
    &_container {
        padding-top: 300px;
        padding-bottom: 190px;

        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white),
        linear-gradient(to bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0)),
        linear-gradient(123deg, #157efb 25%, #ff6c6c 60%);
        background-size: 100% 400px, 100% 400px, 100% 100%;
        background-position: bottom, top, 0 0;
        background-repeat: no-repeat, no-repeat, no-repeat;
    }
    &_152 {
        position: relative;
        padding: 78px 120px 100px 100px;
        margin: 200px auto;
        max-width: 1120px;
        height: 800px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: -5px 0 80px 0 rgb(255, 255, 255);
        .h2 {
            margin-bottom: 380px;
        }
        &_numbers {
            position: absolute;
            top: 152px;
            left: 100px;
            width: fit-content;
            font-weight: bold;
            font-size: 262px;
            letter-spacing: 2px;
            color: rgba(255, 255, 255, 0);
            -webkit-background-clip: text;
            background-clip: text;
            background-image: linear-gradient(115deg, var(--color_primary) 24%, var(--color_lightRed) 73%);
            background-size: 100% 100%;
        }
        &:hover .h2 {
            color: var(--color_primary);
        }
    }

    &_cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 190px auto 0;
        padding: 0 20px;
        max-width: 1330px;
        &_item {
            margin: 10px;
            padding: 40px;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 20px;
            width: 300px;
            &.double {
                width: 620px;
            }
            &_head {
                font-size: 100px;
                font-weight: bold;
                margin-bottom: 40px;
                span {
                    display: block;
                    font-size: 30px;
                    color: var(--color_gray);
                }
            }
            &_text {
                width: 220px;
                font-size: 25px;
                color: var(--color_darkGray);
                line-height: 30px;
            }
        }
    }
}

.learnMore {
    position: static;
    &_container {

    }
    &_cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 50px auto 180px;
        padding: 40px;
        max-width: 1330px;
        > a {
            text-decoration: none;
        }
        &_item {
            width: 300px;
            min-width: 300px;
            border-radius: 20px;
            background-color: #f9f9f9;
            padding: 40px;
            margin: 20px 10px;
            font-size: var(--fontSize_18);
            color: var(--color_darkGray);
            &_title {
                position: relative;
                font-size: 30px;
                line-height: 40px;
                font-weight: bold;
                color: var(--color_black);
            }
            &_text {
                margin-top: 20px;
            }
        }
        .negotiateNew {
            color: rgba(255, 255, 255, 0);
            -webkit-background-clip: text;
            background-clip: text;
            background-image: linear-gradient(115deg, var(--color_primary) 24%, var(--color_lightRed) 73%);
            background-size: 100% 100%;
        }
    }

    &_btnContainer {
        width: fit-content;
        margin: 0 auto;
        &_mob {
            display: none;
        }
    }
    .securityIcon {
        position: absolute;
        top: 6px;
        left: 0;
        height: 28px;
        image-rendering: -webkit-optimize-contrast;
        /*width: 30px;*/
    }
}

.reviews {
    /*max-width: 1200px;
    height: 240px;
    border: 1px solid #0563c1;
    margin: 200px auto 140px;*/
    width: 100%;
    height: 200px;
}

.quoteBlock {
    max-width: 500px;
    margin: 0 auto;
    color: #fff;
}

.quoteText {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 40px;
    font-family: 'ProximaNova Bold', 'ProximaNova', sans-serif !important;
}

.quoteDesc {
    font-size: var(--fontSize_18);
}

.transparentButton, .blueButton, .blueButtonFill {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    padding: 30px 98px;
    border-radius: 10px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.25);
    transition: all .2s;
    &:hover {
        cursor: pointer;
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}
.blueButton {
    border: 1px solid var(--color_primary);
    background-color: rgba(255, 255, 255, 0);
    color: var(--color_primary);
}
.blueButtonFill, .blueButtonFill {
    background-color: var(--color_primary);
    color: #fff;
    &:hover {
        background-color: var(--color_primary_hover);
        color: #fff;
    }
}

.h2 {
    font-size: 50px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 40px;
    transition: all .2s;
}
.fz60 {
    font-size: 60px;
}
.fz50 {
    font-size: 50px;
}
.fz40 {
    font-size: 40px;
}
.fz30 {
    font-size: 30px;
}
.fz24 {
    font-size: 24px;
}
.fz20 {
    font-size: var(--fontSize_h2);
}
.link {
    text-decoration: none;
    color: var(--color_primary);
    transition: all .2s;
    &:hover {
        color: var(--color_primary_hover);
    }
}

.flex {
    display: flex;
}
.lightBlue {
    color: var(--color_primary_hover);
}
.darkGray {
    color: var(--color_darkGray);
}
.blue {
    color: var(--color_primary);
}
.darkRed {
    color: var(--color_darkRed);
}
.lightRed {
    color: var(--color_lightRed);
}
.orange {
    color: var(--color_orange);
}
.green {
    color: var(--color_green);
}

.statsGradient {
    position: absolute;
    top: 442px;
    left: 0;
    height: 150px;
    width: 100%;
    background-image: linear-gradient(to bottom, #f9f9f9, #fff);
    &::before, &::after {
        content: "";
        position: absolute;
        top: -25px;
        height: 100%;
        width: 100px;
    }
    &::before {
        left: 0;
        background-image: linear-gradient(to right, white 5%, white 15%, rgba(255, 255, 255, 0));
    }
    &::after {
        right: 0;
        background-image: linear-gradient(to left, white 5%, white 15%, rgba(255, 255, 255, 0));
    }
}

.parallax {
    position: relative;
    top: 150px;
}

/* tablets */
@media screen and (max-width: 1325px) {
    .c1screen {
        overflow: auto;
        max-width: initial !important;
        min-width: initial !important;
    }
    .firstBlock_container {
        background-size: 100% 500px, 100% 300px, 100% 100%;
    }
}

@media screen and (max-width: 1280px) {
    .pageTitle, .pageTitleDesc {
        padding-left: 0 !important;
        width: 622px !important;
    }

    .c1screen {
        background-image: none !important;
    }

    .featuredBlock {
        &_item {
            height: fit-content;
            opacity: 1;
            .h2.lightRed {
                color: var(--color_lightRed);
            }
            .h2.darkGray {
                color: var(--color_primary);
            }
            .flex {
                flex-wrap: wrap;
                > div:first-child {
                    margin-bottom: 40px;
                }
            }
        }
        &_flex {
            .featuredBlock_item {
                height: fit-content;
            }
        }
    }

}

@media screen and (max-width: 1024px) {
    .featuredBlock {
        &_flex {
            flex-wrap: wrap;
            margin-bottom: 40px;
            .featuredBlock_item {
                height: fit-content;
                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }
    .benefits_152 {
        margin: 200px 40px;
        height: fit-content;
        .h2 {
            margin-bottom: 240px;
        }
        &_numbers {
            font-size: 200px;
        }
        .flex {
            flex-wrap: wrap;
            > div:first-child {
                margin-bottom: 40px;
            }
        }
    }
}

@media screen and (max-width: 851px) {
    .benefits_152_numbers {
        top: 202px;
    }
}

/* phones */
@media screen and (max-width: 767px) {
    .pageTitle {
        font-size: 45px !important;
        padding: 0 20px;
    }
    .pageTitleDesc {
        font-size: var(--fontSize_h2) !important;
    }
    .pageTitle, .pageTitleDesc {
        max-width: 450px;
        width: auto !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .pageTitleButton {
        width: fit-content !important;
    }
    .h2 {
        font-size: 40px !important;
    }

    .c1screen {
        padding: 100px 40px !important;
        > img:last-child, > img:first-child {
            display: none;
        }
    }
    .quoteBlock {
        padding: 0 40px;
        width: auto !important;
        .quoteText {
            font-size: 24px !important;
        }
    }
    .featuredBlock {
        &_flex > .featuredBlock_item:last-child > div:not(.darkGray) {
            transform: translate(-40px, -50px) !important;
        }
        &_container {
            padding: 0;
        }
        &_item {
            font-size: 30px !important;
            width: 100% !important;
            padding: 60px 40px 40px !important;
            &_img {
                text-align: center;
                width: calc(100% + 80px) !important;
                left: -40px !important;
            }
            .fz40 {
                font-size: 30px !important;
            }
            * {
                min-width: auto !important;
                margin-right: 0 !important;
            }
        }
        > .featuredBlock_item:nth-child(1) > .featuredBlock_item_img > img {
            width: 613px !important;
        }
        > .featuredBlock_item:nth-child(2) > .featuredBlock_item_img > img,
        > .featuredBlock_item:nth-child(3) > .featuredBlock_item_img > img,
        > .featuredBlock_item:nth-child(4) > .featuredBlock_item_img > img {
            width: 800px !important;
        }
        > .featuredBlock_item:nth-child(6) > .featuredBlock_item_img > img {
            width: 700px !important;
        }
    }
    .benefits_152 {
        padding: 40px;
        margin-right: 0 !important;
        margin-left: 0 !important;
        .flex > div:first-child {
            min-width: auto !important;
            margin-right: 0 !important;
        }
        .h2 {
            margin-bottom: 170px;
            color: var(--color_primary);
        }
        .fz40 {
            font-size: 30px !important;
        }
        &_numbers {
            font-size: 120px;
            top: 140px;
            left: 20px;
        }
    }
    .benefits {
        &_cards_item.double {
            width: 300px;
            .flex {
                flex-wrap: wrap;
                > div:first-child {
                    margin-bottom: 40px;
                }
            }
        }
        &_cards_item {
            &_text {
                font-size: 25px;
                margin-right: 0 !important;
            }
        }
        &_cards_item:last-child &_cards_item_head {
            font-size: 40px;
        }
    }
    .learnMore_cards {
        margin-top: -2px;
        margin-bottom: 40px;
        &_btnContainer._mob {

        }
    }
    .reviews {
        height: 50px;
    }
}