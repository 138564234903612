.wrapper {
    margin-top: 20px;
    &._invisible {
        display: none;
    }
}

.comment {
    padding: 15px 24px 15px 24px;
    border-radius: 8px;
    background-color: hsla(0,0%,87%,.15);
    font-size: var(--fontSize_tab);
    display: flex;
    justify-content: flex-start;
    position: relative;
    &._isPublic {
      background-color: rgba(21,126,251,.05);
    }
    &._isFreeze{
      background-color: #E5EFFD;
    }
    &._isNeutral{
      background-color: white;
      padding: 0 0 0 24px;
    }
}

.section {
    padding-left: 20px;
    letter-spacing: 0;
    color: var(--color_darkGray);
    word-break: break-word;
}

.logo {
    min-width: 25px;
    max-width: 25px;
}

.time {
    margin-left: 5px;
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
}

.cmTitle {
    margin-top: 5px;
    font-weight: bold;
}

.article {
    line-height: 1.36;
    margin-top: 5px;
}
.article del {
   text-decoration: line-through;
}
.nav {
  font-size: var(--fontSize_standart);
  color: var(--color_primary);
  margin-right: 10px;
  cursor: pointer;
}
div.nav {
    color: var(--color_darkRed);
    margin-top: 5px;
    cursor: initial;
}

.buttons {
    margin-top: 5px;
    padding-left: 69px;
}