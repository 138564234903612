.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.wrapper {
  display: flex;
  align-items: baseline;
  padding-left: 40px;
}

.status {
  font-size: var(--fontSize_standart);
  color: var(--color_darkGray);
  margin-right: 40px;
  white-space: nowrap;
  &._green {
    color: var(--color_green);
  }
}

.border {
  margin-right: 40px;
  width: 0;
}
.BtnsOpaque {
  opacity: 0.3;
}

.publish {
  position: relative;
  &_btnBlock {
    display: flex;
    align-items: baseline;
    position: relative;
    margin-right: 40px;
    transition: z-index .0s .2s;
    z-index: 1;
    & > div {
      display: flex;
      align-items: baseline;
      position: relative;
      cursor: pointer;
      & > img {
        position: relative;
        top: 5px;
        margin-right: 10px;
      }
    }
  }
  &._open {
    .publish_btnBlock {
      transition: z-index .0s .0s;
      z-index: 7;
    }
    .publish_popup {
      opacity: 1;
      visibility: visible;
    }
  }
  .smallText {
    font-size: var(--fontSize_tip);
    color: var(--color_gray);
    width: 150px;
  }
  &_popup {
    position: absolute;
    top: -40px;
    left: -40px;
    padding: 70px 40px 40px;
    width: 280px;
    background-color: #FFFFFF;
    box-shadow: var(--shadow_menu);
    border-radius: 6px;
    z-index: 6;
    transition: all .2s;
    opacity: 0;
    visibility: hidden;
    &_btnBlock {
      margin-top: 20px;
      > button {
        margin-bottom: 8px;
      }
      > textarea {
        margin-top: 8px;
        font-size: var(--fontSize_tip);
        color: var(--color_gray);
        width: 200px;
        padding: 4px 15px 3px;
        border: 1px solid var(--color_gray);
        border-radius: 4px;
        resize: none;
        overflow: hidden;
      }
    }
  }
}

.versions {
  position: relative;
  > button {
    transition: z-index .0s .2s;
    z-index: 1;
  }
  &._open {
    > button {
      transition: z-index .0s .0s;
      z-index: 7;
    }
    .versions_popup {
      opacity: 1;
      visibility: visible;
    }
  }
  &_popup {
    position: absolute;
    top: -40px;
    left: -40px;
    font-size: var(--fontSize_standart);
    padding: 100px 40px 40px;
    width: 400px;
    background-color: #FFFFFF;
    box-shadow: var(--shadow_menu);
    border-radius: 6px;
    z-index: 6;
    transition: all .2s;
    opacity: 0;
    visibility: hidden;
    &_version {
      display: flex;
      align-items: baseline;
      padding: 10px 0;
      &:last-child {
        padding-bottom: 0;
      }
      &:not(:first-of-type) {
        border-top: 1px solid var(--color_lightGray);
      }
      &_title {
        width: 130px;
        padding-right: 40px;
        color: var(--color_primary);
        &._current {
          font-weight: bold;
        }
        &._prev {
          color: var(--color_lightRed);
        }
      }
      &_desc {

      }
    }
  }
}

.userBlock {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
  a:first-child {
    margin-right: 15px;
  }
  &_name, &_email {
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
    white-space: nowrap;
    font-weight: bold;
    overflow: hidden;
    transition: color .2s;
  }
  &_name + &_email {
    font-size: var(--fontSize_tip);
    color: var(--color_gray);
    font-weight: normal;
    overflow: hidden;
  }
}

.bold {
  font-weight: bold;
}
.green {
  color: var(--color_green);
}
.gray {
  color: var(--color_gray);
}
.red {
  color: var(--color_lightRed);
}