.wrapper {

}

.project-title {
    position: relative;
    input {
        width: 100%;
        font-size: var(--fontSize_h1);
        color: var(--color_black);
        font-weight: 700;
        border: none;
    }
    &-hint {
        color: var(--color_darkGray);
        width: auto;
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
        top: -33px;
        left: 0;
        position: absolute;
        transition: all .2s;
        z-index: 10;
        div {
            width: 100%;
            position: static;
        }
    }
    &:hover &-hint {
        opacity: 1;
        visibility: visible;
    }
}

.row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 40px;
    > div {
        margin-top: 0;
    }
}

.dropzone {
    position: relative;
    width: 400px;
    height: fit-content;
    min-height: 242px;
    border-radius: 10px;
    border: 1px dashed var(--color_lightGray2);
    padding: 40px 40px 175px;
    margin-right: 40px;
    transition: border .2s ease-out, color .2s ease-out, background-color .2s;
    cursor: pointer;
    &-title {
        font-size: var(--fontSize_h2);
        color: var(--color_black);
        font-weight: bold;
        margin-bottom: 20px;
    }
    &._active, &:hover {
        border: 1px dashed var(--color_primary);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    &._active:hover {
        background-color: var(--color_gray);
    }
    & path {
        transition: stroke .2s ease-out;
    }
    .plus {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: var(--fontSize_18);
        color: var(--color_gray);
        text-align: center;
    }

}

.docList {
    color: var(--color_darkGray);
    font-size: var(--fontSize_tab);
    width: fit-content;
    &-item {
        width: fit-content;
        position: relative;
        transition: color .2s;
        padding-right: 20px;
        margin-bottom: 20px;
        .deleteBtn {
            top: -3px;
            right: 0;
        }
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}