.screen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 3;
}

.requestBlock {
    padding: 0 40px;
    position: relative;
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;
    z-index: 3;
    > button {
        z-index: 1;
    }
}
.requestPopup {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: -17px;
    left: -1px;
    min-width: 304px;
    max-width: 400px;
    padding: 86px 40px 40px 41px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    transition: all .2s;
    &_vis {
        visibility: visible;
        opacity: 1;
    }
    &-desc {
        font-size: var(--fontSize_standart);
        color: var(--color_gray);
        margin-top: 10px;
    }
}

.sendMenu-note > div {

}