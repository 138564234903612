
.rename-input{
    width: 599px;
    color: var(--color_darkGray);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_18);
    font-weight: 400;
    border-radius: 15px;
    border: solid 1px var(--color_lightGray);
    background-color: #fff;
    padding: 29px 70px 29px 30px;
    &-area{
      position: relative;
    }
  }
  .rename-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
    margin: auto;
  }
  .rename-buttons-row{
    justify-content: center;
    width: 460px;
    margin: 30px auto 0;
    display: flex;
    justify-content: space-between;
  }
  .rename-close-button{
    position: absolute;
    top: 25px;
    right: 25px;
    box-sizing: content-box;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    background: var(--color_lightGray2);
    cursor: pointer;
    &:before{
      display: inline-block;
      box-sizing: content-box;
      width: 15px;
      height: 2px;
      position: absolute;
      content: "";
      top: 16.2px;
      left: 9.5px;
      border: none;
      text-overflow: clip;
      background: #ffffff;
      transform: rotateZ(45deg);
    }
    &:after{
      display: inline-block;
      box-sizing: content-box;
      width: 15px;
      height: 2px;
      position: absolute;
      content: "";
      top: 16.2px;
      left: 9.5px;
      border: none;
      text-overflow: clip;
      background: #ffffff;
      text-shadow: none;
      transform: rotateZ(-45deg);
    }
    &:hover {
      background: #ababab;
    }
  }

  .rename-title{
    color: var(--color_black);
    font-family: var(--fontFamily);
    font-size: 30px;
    font-weight: 300;
    margin: 0.67em 0 40px;
    text-align: center;
  }



  .input {
  width: 599px;
  color: var(--color_darkGray);
  font-family: var(--fontFamily);
  font-size: var(--fontSize_18);
  font-weight: 400;
  border-radius: 15px;
  border: solid 1px var(--color_lightGray);
  background-color: #fff;
  padding: 29px 30px;
  &::placeholder {
    color: #ababab;
  }

  &._empty {
    border-color: #ebebec;
  }
}



.error-message {
  font-family: var(--fontFamily);
  color: var(--color_lightRed);
  font-size: var(--fontSize_standart);
  position: absolute;
  top: 15px;
  left: 20px;
}
