.wrapper {
    margin: 0 auto;
    width: 750px;
    color: #212121;
    padding-top: 160px;
    padding-bottom: 50px;
}

.title {
    position: relative;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 100px;
    &-desc {
        line-height: 36px;
        font-size: var(--fontSize_18);
    }
    > img {
        position: absolute;
        top: 20px;
        bottom: 0;
        left: -60px;
        width: 20px;
        height: 25px;
    }
}

.section {
    margin: 100px 0;
    line-height: 36px;
    font-size: var(--fontSize_18);
    &-title {
        font-weight: bold;
        font-size: var(--fontSize_h1);
        line-height: 40px;
        margin-bottom: 40px;
    }
}
.cols-row {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    &-col {
        &-title {
            font-size: var(--fontSize_h2);
            font-weight: bold;
            line-height: 40px;
            padding-bottom: 20px;
        }
        width: 100%;
        &:not(:last-child) {
            margin-right: 50px;
        }
    }
}

.link {
    display: inline-block;
    text-decoration: none;
    color: var(--color_primary);
}

.bold {
    font-weight: bold;
}