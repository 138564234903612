.wrapper {
    padding-top: 102px;
    padding-bottom: 102px;
    height: 100vh;
    min-height: 604px;
    display: flex;
    align-items: center;
}

.logo {
    position: absolute;
    top: -62px;
    left: 0;
}

.card {
    margin: 0 auto;
    position: relative;
    width: 400px;
    padding: 40px;
    border-radius: 20px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    color: var(--color_black);
}

.title {
    font-size: var(--fontSize_h2);
    font-weight: bold;
    padding-bottom: 40px;
}

.text {
    font-size: var(--fontSize_h3);
    /*
    padding-bottom: 40px;
    */
}

.btn {
    display: flex;
    justify-content: center;
}