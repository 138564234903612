.wrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: var(--fontFamily);
  font-size: var(--fontSize_18);
  font-weight: bold;
  color: var(--color_darkGray);
}
div.wrapper {
    cursor:default;
    -ms-user-select: none; 
    -moz-user-select: none; 
    -webkit-user-select: none; 
    user-select: none; 
}
.one {
  width: 42px;
  height: 22px;
  border-radius: 8px;
  background-color: var(--color_darkGray);
  margin-left: 6px;
  font-size: var(--fontSize_h3);
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #ffffff;
  text-align: center;
}

.text {
  font-family: var(--fontFamily);
  font-size: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--color_darkGray);
  opacity: 0.5;
}

.span {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #acacac;
  opacity: 0.5;
}

.icon {
  width: 132px;
  height: 25px;
}
