.title {
  margin: 0 0 10px;
  //color: black;
  font-family: var(--fontFamily);
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  &._big {
      font-size: 60px;
      letter-spacing: 0.4px;
  }
}

.slogan {
  margin: 30px 0 69px 0;
  color: var(--color_black);
  font-family: var(--fontFamily);
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  opacity: 0.5;
  text-align: center;
  //letter-spacing: 0.6px;
  //width: 585px;
}

.planWrapper {
    display: inline-block;
    width: 350px;
    color: var(--color_black);
    flex: 0 0 350px;
}

.planElement {   
    height: 320px;
    border-radius: 8px;
    background-color: rgba(21,126,251,0.05);
    padding: 35px;
    cursor: pointer;
    &:hover{
      box-shadow: 0 50px 100px 0 rgba(0,0,0,0.1);      
    }
    &._promo{
        height: 390px;
        cursor: default;
    }
    &._clicked{
        background-color: var(--color_primary);
        color: white;
    }
    &._last{
        background-color:rgba(222,222,222,0.15);
    }
    &._first{
        background-color:rgba(254,246,239,1);
    }
}

.promo-nav {
    display: block;
    margin: 30px auto 46px auto;
    width: 250px;
}

.price-container {
    margin: 44px auto 30px auto;
    display: flex;
    justify-content: center;
    align-content: center;
    &._isSmall {
        margin: 0 0 0 60px;
        float: left;
        width: 200px;
    }
}

.price {
    display: inline-block;
    font-size: 80px;    
    margin: 0;
    padding: 0;
    font-weight: 100;
    letter-spacing: -3px;
    line-height: 0.75;
    &:before {
      content: "$";
      font-size: 26px;
      vertical-align: super;
      margin-right: 3px;
    }
    &._isSmall {
        font-size: 60px;
    }
}

.priceRemark {
    width: 70px;
    font-size: var(--fontSize_18);
    display: inline-block;
    align-self: center;
    margin-left: 8px;
}

.bonusText {
    font-size: var(--fontSize_h2);
    width: 251px;
    opacity: 0.8;
    font-weight: 300;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
}

.radio {
    width: 350px;
    height: 85px;
    display: none;
    background-color: #f3f8ff;
    border-radius: 0 0 8px 8px;
    margin: 0 -35px;
    color: var(--color_black);
    font-size: var(--fontSize_h3);
    &._clicked{
        display: flex;
        flex-direction: column;
    }
    &>div {
        margin: 9px;
        display: flex;        
    }
    //position: static;
    //top: 287px;
}

.radioButton {
    display: inline-block;
    width:18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ffffff;
    flex: 0 0 18px;   
    border: solid 1px var(--color_primary);
    margin-right: 20px;
    &._clicked{
        border: solid 5px var(--color_primary);
    }
}

.prophits {
    font-size: var(--fontSize_h3);
    margin: 64px 0 0;
    padding: 0 0 0 0px;
    //list-style: outside;
    &._promo {
      margin: 30px 0 0;
    }
    &>li {
        margin-bottom: 20px;
        display: flex;
        &:before {
            color: var(--color_primary);
            content: "\2022";
            flex: 0 0 20px;
        }
    }
}

.prophitsRemark {
    padding: 0 10px 0 10px;
    text-align: center;
    font-size: var(--fontSize_tab);
    opacity:0.8;
    &:before {
       content: "*";
    }
}