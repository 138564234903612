.projectPage-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 8;
}
.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100%;
    font-family: var(--fontFamily);
    padding: 40px 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 8;
    overflow-y: scroll;
    &.newProject {
        height: calc(100% - 80px);
        padding: 30px 40px 40px;
        overflow-y: auto;
        top: 80px;
        background-color: white;
        z-index: 8;
        .project-title {
            width: 100%;
        }
    }
    &._zIndHigh {
        z-index: 11;
    }
    &._zIndLow {
        z-index: 1;
    }
}
.docIcon {
  transform: translateY(1px);
  margin-right: 30px;
  min-width: 11px;
  min-height: 13px;
}
.docList {
    color: var(--color_darkGray);
    font-size: var(--fontSize_tab);
    &-item {
        position: relative;
        transition: color .2s;
        padding-right: 20px;
        margin-bottom: 20px;
        word-break: break-word;
        .docFlex {
            display: flex;
        }
        .deleteBtn {
            top: -3px;
            right: -10px;
        }
        > button {
          display: block;
          margin-top: 6px;
          margin-left: 41px;
        }        
    }
}

.drop-down {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    padding: 40px;
    margin: 0 auto;
    width: 1200px;
    height: fit-content;
    min-height: 450px;
    background-color: white;
}

.project-title {
    width: 360px;
    height: 40px;
    font-size: var(--fontSize_h2);
    font-weight: 700;
    border: none;
}

.title {
  margin-bottom: 20px;
  &._woMargin {
    margin: 0;
  }
}

.project-name {
  width: 400px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px var(--color_lightGray);
  margin-bottom: 50px;
  padding: 0 20px;  
}

.project-name input {
  font-size: var(--fontSize_h2);
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.01px;
  color: var(--color_black);
  background: none;
}
.doc-input{
  border: none !important;
}
.doc-label {
    cursor: text;
}

.pages {
    display: inline-block;
    margin-right: 40px;
    padding-bottom: 15px;
    font-size: var(--fontSize_tab);
    line-height: 1.36;
    letter-spacing: 0.01px;
    color: var(--color_darkGray);
    cursor: pointer;
    &._isSelected {
      border-bottom: solid 5px var(--color_lightGray2);
    }
}
.aline {
    margin: 0 -40px 40px;
    height: 1px;
    background-color: var(--color_lightGray2);
}
.interline {
}
.acme {
    width: fit-content;
    position: relative;
    font-size: var(--fontSize_tab);
    line-height: 19px;
    color: var(--color_darkGray);
    margin: 20px 0 20px 40px;
}
.acme img {
    left: -40px;
    top: 4px;
    position: absolute;
    height: 11px;
}
.workflows {
    margin-bottom: 50px;
    display: flex;
}
.flows {
    display: inline-block;
    margin: 20px 40px 0 0;
    line-height: 19px;
    padding: 20px;
    font-size: var(--fontSize_tab);
    font-weight: normal;
    color: var(--color_black);
    width: 320px;
    height: 176px;
    border-radius: 10px;
    border: dotted 1px var(--color_lightGray2);
    cursor: pointer;
    &._isSelected {
      border-color: var(--color_primary);
      cursor: initial;
    }
}
.flowName {
    font-size: var(--fontSize_h3);
    font-weight: bold;
    margin-bottom: 20px;
}

.buttons {
    display: flex;
}

.add-project {
  margin: 20px 0 40px;
  width: 400px;
  border-radius: 10px;
  border: 1px dashed var(--color_lightGray2);
  height: 160px;
  color: var(--color_gray);
  font-size: var(--fontSize_18);
  position: relative;
  cursor: pointer;
  text-align: center;
  transition: border .2s ease-out, color .2s ease-out, background-color .2s;
    &:first-child {
        border: none
    }
  & path {
    transition: stroke .2s ease-out;
  }
  & > span {
      color: var(--color_primary);
  }
    &.active, &:hover {
        border: 1px dashed var(--color_primary);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    &:hover {
        border: 1px dashed var(--color_gray);
    }
}
.add-project .add-project {
    margin: 0;
}
.disDoc {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: -30px;
    transform: rotate(45deg);
    color: #d8d8d8;
    font-size: 25px;
    width: 20px;
    height: 20px;
}

.teams {
  display: flex;
  margin: 40px 0 0;
  width: fit-content;
  position: relative;
}
.team {
  width: 400px;
  padding: 40px;
    border-radius: 8px;
    &.int {
        background-color: #f9f9f9;
    }
    &.ext {
        background-color: #f3f8ff;
    }
}

.team+.team {
    margin-left: 40px;
}

.teamCommandLine {
  /*margin-top: 20px;*/
  /*margin-bottom: 20px;*/
  display: flex;
}
.teamCommandLine1 {
  margin: 20px 0 0 40px;  
}

.teamCommand {
    color: var(--color_primary);
    font-size: var(--fontSize_standart);
    margin-right: 15px;
    transition: all 0.2s;
    border-bottom: dashed 1px var(--color_primary);
    &:hover {
      color:var(--color_primary_hover);
      border-bottom: dashed 1px var(--color_primary_hover);
      cursor: pointer;
    } 
}

.teamRemark {
  width: 200px;
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
  margin-bottom: 10px;
}
.subteam-wrapper {
    /*margin-bottom: 40px;*/
}
.subteam {
  margin-top: 10px;
    > div:last-child {
        margin-bottom: 0;
    }
}

.justAddedUsers {
    position: relative;
    padding: 20px 20px 15px;
    width: calc(100% + 40px);
    left: -20px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: var(--color_lightGray);
    &.ext {
        background-color: #e7f1ff;
    }
    &_list > div:last-child {
        margin-bottom: 0;
    }
    &-sendBtn {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        &[data-lang="0"] {
            flex-direction: column;
            & button {
                margin-bottom: 10px;
            }
        }
        &-desc {
            font-size: var(--fontSize_tip);
            color: var(--color_darkGray);
        }
    }
    &_spinner {
        font-size: var(--fontSize_standart);
        color: var(--color_gray);
        white-space: nowrap;
        min-width: 114px;
        margin-right: 20px;
        margin-top: -1px;
        > div {
            position: relative;
            display: inline-block;
            top: 3px;
            left: 0;
            width: 15px;
            margin-right: 5px;
            > img {
                width: 15px;
                height: 15px;
                margin: 0;
            }
        }
    }
}

.project-title {
    position: relative;
    input {
        width: 100%;
        font-size: var(--fontSize_h1);
        color: var(--color_black);
        font-weight: 700;
        border: none;
    }
    &-hint {
        color: var(--color_darkGray);
        width: auto;
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
        top: -31px;
        left: 0;
        position: absolute;
        transition: all .2s;
        z-index: 10;
        div {
            width: 100%;
            position: static;
        }
    }
    &:hover &-hint {
        opacity: 1;
        visibility: visible;
    }
}

.dropzone {
    position: relative;
    min-width: 400px;
    width: 400px;
    height: fit-content;
    min-height: 242px;
    border-radius: 10px;
    border: 1px dashed var(--color_lightGray2);
    padding: 40px 40px 175px;
    margin-right: 40px;
    transition: border .2s ease-out, color .2s ease-out, background-color .2s;
    &-title {
        font-size: var(--fontSize_h2);
        color: var(--color_black);
        font-weight: bold;
        margin-bottom: 20px;
    }
    &._active, &:hover {
        border: 1px dashed var(--color_gray);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    &._active {
        border-color: var(--color_primary);
        & .dropscreen {
            opacity: 0.4;
        }
    }
    & path {
        transition: stroke .2s ease-out;
    }
    .plus {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: var(--fontSize_18);
        color: var(--color_gray);
        text-align: center;
    }

}
.row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 40px;
    > div {
        margin-top: 0;
    }
}

.errorString {
  font-size: var(--fontSize_standart);
  color: var(--color_lightRed);
}
.removeTeam {
  display:none;
}
.editTeam {
  position: relative;
    font-size: var(--fontSize_h3);
    font-weight: bold;
    color: var(--color_black);
    margin-top: 40px;
    margin-bottom: 20px;
}
.hintPlace-team {
    position:absolute;
    width: 0;
    height: 0;
    left: 50px;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
}
.hintPlace-team div {
    width: 90px;
}
.editTeam div:first-child:hover ~ .hintPlace-team {
    visibility: visible;
    opacity: 1;
}

.wsAbsolute {
  position: absolute;
  left: 0;
  bottom: 40px;
  z-index: 4;
  width: 100%;
}
.ws_wrapper {
   background-color: rgba(255, 255, 255, 0.66);
   display: flex;
   height: 60px;
   border-radius: 10px;
   border: solid 1px var(--color_lightGray2);
   margin: -1px 20px;
   padding: 0 19px;
   justify-content: space-between;
   cursor: pointer;
}
.ws_item {
   display: flex;
   width: 280px;
   align-items: center;
    span > svg {
        top: -3px;
    }
}
.SC_title {
    margin-bottom: 20px;
}
.SC_title-invite {
    margin-top: 20px;
    margin-bottom: 9px;
}

.addTeamPosition {
   position: fixed;
   height: fit-content;
   max-height: 100%;
   padding: 40px 0;
   width: 100%;
   top: 0;
   left: 0;
   right: 0;
   z-index: 8;
   overflow-y: auto;
}
.screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 8;
}
.addTeam {
   position: relative;
    z-index: 6;
    top: 0;
    left: 0;
    width: 800px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    padding: 40px;
    margin: 0 auto;
   &._number {
      right: 0;
   }
}
.addTeamFake {
    height: 800px;
}
.addTeamsCont {
  display: flex;
  margin: 40px 0 0;
  width: fit-content;
  position: relative;    
}
.addTeamsPart {
  width: 360px;
  padding-right: 40px;    
}
.addTeamsPart+.addTeamsPart {
  border-left: solid 1px var(--color_lightGray2);
  padding: 0 0 0 40px;
}

.listUser > *:not(:last-child) {
    margin-bottom: 20px;
}
.listUser > :first-child {
    margin-top: 10px;
}

.listCompany {
   display: flex;
   overflow: auto;
}
.listCompanyRight {
  width: 100%;
  padding: 20px;
}
.listCompanyLeft {
    min-width: 33px;
    padding-top: 20px;
    position: relative;
}
.listCompanyRightCont {
  font-size: var(--fontSize_tab);
  font-weight: bold;
  color: var(--color_darkGray);
  display: flex;
  align-items: center;
}


.listCompanyDown {
    margin-left: 7px;
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 5px;
}

.listCompanyDown:before {
    position: absolute;
    top: -10px;
    content: "";
    cursor: pointer;
    width: 25px;
    height: 25px;    
    background: url('/images/down@3x.png');
    background-size: cover;
    
}
    ._up:before {
      background: url(/images/up@3x.png);
      background-size: cover;
    }
.listCompanyRightCont span {
  font-weight: normal;
  margin-left: 10px;
  color: var(--color_lightRed);
  opacity: 0;
}
.listCompanyRightCont:hover span {
   opacity: 1;
   transition: opacity 0.5s ease-out 0.1s;
   cursor: pointer;
}

.IUO_wrapper {
    z-index: 10;
    background-color: #fff;
    top: 45px;
    left: -10px;
    position: absolute;
    width: 300px;
    border-radius: 6px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
}
.IUO_AllInvOpt {
    padding: 20px;
}
.IUO_perm {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    &-item {
        cursor: pointer;
         margin-bottom: 20px;
         display: flex;
        &:last-child {
            margin-bottom: 0;
        }
        &-text {
             margin-left: 11px;
            &-small {
                 padding-top: 5px;
                 font-size: var(--fontSize_tip);
                 color: var(--color_gray);
            }
         }
     }
}

.IUO_invitations {
    padding: 20px;
}
.IUO_inv-timeJoin, .IUO_inv-timeAccept {
    font-size: var(--fontSize_tip);
    color: var(--color_gray);
}
.IUO_inv-timeJoin {
    margin-bottom: 5px;
}
.IUO_inv-timeAccept {
    margin-bottom: 40px;
}
.IUO_options-item {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.IUO_send {
    font-size: var(--fontSize_tab);
    color: var(--color_primary);
    &-desc {
         font-size: var(--fontSize_tip);
         color: var(--color_gray);
         margin-bottom: 20px;
         margin-top: 5px;
     }
}
.IUO_createLink {
    padding-left: 40px;
}
.IUO_link {
    font-size: var(--fontSize_tab);
    color: var(--color_black);
    line-height: 18px;
    word-wrap: break-word;
    span {
         font-weight: bold;
     }
    &-valid {
         padding-top: 5px;
         padding-bottom: 5px;
         font-size: var(--fontSize_tip);
         color: var(--color_gray);
     }
}
.IUO_close {
    cursor: pointer;
    font-size: var(--fontSize_18);
    font-weight: bold;
    position: absolute;
    transform: rotate(45deg);
    color: #d8d8d8;
    left: 272px;
    top: 18px;
}
.IUO_close:hover {
    color: #a0a0a0;
}
.teamLink {
    color: var(--color_primary);
    margin-top: 10px;
    font-size: var(--fontSize_button);
    cursor: pointer;
    position: relative;
    & div {
        opacity: 0;
        transition: all .3s;
        z-index: -1;
    }
    &[data-tip] div {
        opacity: 1;
        z-index: 2;
    }
}