.spinnerWrapper {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  margin: 25px;
  width: 30px;
  height: 30px;
  animation: load8 0.8s infinite linear;
}

@keyframes load8 {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
