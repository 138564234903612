.component {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    transform: translateZ(0);
    z-index: 20;
    background-color: #ffffff;
  }

.header{
  width: 100%;
  height: 86px;  
  padding: 25px 50px 25px 35px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background-color: #ffffff;
}

.wrapper{
  width: 100%;
  height: 1000px;
  }
  
  .congrats {
      width: 100%;
      height: 100%;
      min-height: 100%;
      font-family: var(--fontFamily);
      z-index: 2;
      background-color: rgba(250,250,255,0.96);
      padding-top: 360px;
  }

  .sidebar{ 
      background-color: #ffffff;
  width: 100%;
  height: 100%;
  min-height: 100%;
  /* box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15); */
  /*padding: 25px 35px;*/
  font-family: var(--fontFamily);
  z-index: 2;
  /*margin-right: -412px; */
  &__header{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  &__title{
    margin: 0;
    height: 37px;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    color: #212121;
    color: var(--color_black);
  }

}

.account-close-button{
  position: relative;
  top: 40px;
  box-sizing: content-box;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  background: #dedede;
  cursor: pointer;
  transition: background-color 0.2s;
  &:before{
    display: inline-block;
    box-sizing: content-box;
    width: 15px;
    height: 2px;
    position: absolute;
    content: "";
    top: 16.2px;
    left: 9.5px;
    border: none;
    text-overflow: clip;
    background: #ffffff;
    transform: rotateZ(45deg);
  }
  &:after{
    display: inline-block;
    box-sizing: content-box;
    width: 15px;
    height: 2px;
    position: absolute;
    content: "";
    top: 16.2px;
    left: 9.5px;
    border: none;
    text-overflow: clip;
    background: #ffffff;
    text-shadow: none;
    transform: rotateZ(-45deg);
  }
  &:hover {
    background: #ababab;
  }
}

.flex{
  display: flex;
  justify-content: space-between;
}

.input-area {
  width: 100%;
  padding: 9px 15px;
  padding: 7.5px 15px;
  display: block;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #ececec;
  margin-bottom: 15px;
  /* font-weight: 300; */
  /* letter-spacing: 0px; */
}


.form{
  &-text{
    font-size: var(--fontSize_tab);
    font-weight: normal;
    color: #606060;
    margin: 35px 0 25px;
  }
  &-btn{
    width: 220px;
    height: 40px;
    border-radius: 6px;
    font-size: var(--fontSize_tab);
    background-color: #157efb;
    color: #ffffff;
    border: none;
    font-weight: 300;
    margin: 35px auto 0;
    display: block;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover{
      background-color: #0368e0;
    }
  }
}

.text_isError{
  color: var(--errorColor);
}


.error-message {
  font-family: var(--fontFamily);
  color: var(--errorColor);
  font-size: var(--fontSize_standart);
  line-height: 19px;
}

.plans {
    margin: 0 auto 0 auto;
    width: 1120px;
    minwidth: 1120px;
    display: flex;
    justify-content: space-between;
}

.controlButtons {
    
}

.calc {
    width:1120px;
    height: 72px;
    margin: 32px auto 0 auto;
    &-wrapper {        
        position: relative;
        left: -130px;
        padding-left: 140px;
        width: 1380px;
        height: 72px;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    }
    &-choicePlan {
        float:left;
    }
    &-choice {
        font-size: var(--fontSize_h3);
    }
    &-plan {
        font-size: 22px;
        letter-spacing: 0.6px;
        width: 420px;
    }
    &-stripe {
        font-size: var(--fontSize_tab);
        width: 161px;
        margin-left: 18px;
        color: #acacac;
        float:left;
    }
    &-btn{
        width: 220px;
        height: 40px;
        border-radius: 6px;
        font-size: var(--fontSize_tab);
        background-color: #157efb;
        color: #ffffff;
        border: none;
        font-weight: 300;
        //margin: 35px auto 0;
        display: block;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover{
          background-color: #0368e0;
        }
        float:left;
        &._decline {
            margin-left: 40px;
            opacity: 0.8;
        }
        &._disable {
            opacity: 0.2;
            cursor: initial;
        }
   }
}

.checkout {
    float:left;
    width: 677px;
    height: 60px;
    margin-top: 0px;
}

.errMessage  {
    color: red;
    margin-left: 50px;
    & > a {
      color: red;    
    }
}
