.buttonBlock {
    position: relative;
    padding-left: 40px;
    width: auto;
    display: flex;
    align-items: baseline;    
    button {
        margin: 0;
    }
}

.versionBlock {
    position: relative;
    width: 312px;
    height: 100%;
    .arrow-right, .arrow-left {
        right: -11px;
    }
    &_open {
        .versionButtons {
            width: 537px;
            &::after {
                display: none;
            }
            &-grad {
                right: -261px;
            }
        }
        .arrow-right, .arrow-left {
            right: -235px;
        }
    }
}
.versionButtons {
    position: relative;
    left: 1px;
    display: flex;
    align-items: baseline;
    width: 312px;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s;
    z-index: 3;
    &-grad {
        position: absolute;
        top: 0;
        right: -36px;
        height: 50px;
        width: 35px;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        z-index: 3;
        transition: all .2s;
    }
    > button:last-child {
        margin: 0 40px;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
}
.version-button {
    cursor: pointer;
    position: relative;
    font-size: var(--fontSize_standart);
    color: var(--color_primary);
    flex-shrink: 0;
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
}
.bold {
    font-weight: bold;
    position: relative;
    /* top: -1px; */
}
.version-cont {
    margin-right: 40px;
    font-size: var(--fontSize_standart);
    color: var(--color_lightRed);
    position: relative;
    flex-shrink: 0;
    &_time {
        position: absolute;
        top: 18px;
        left: 0;
        padding-top: 2px;
        background-color: white;
        font-size: var(--fontSize_tip);
        color: var(--color_lightGray2);
        z-index: 1;
    }
    &.just-imported {
        margin-right: 40px;
    }
    &.frozen {
        color: var(--color_darkGray);
        margin-right: 80px;
    }
}

.try-popup {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 230px;
    padding: 40px;
    background-color: #fff;
    box-shadow: var(--shadow_menu);
    transition: all .2s;
    border-radius: 10px;
    z-index: 4;
}

.arrow-right, .arrow-left {
    cursor: pointer;
    width: 1px;
    height: 50px;
    position: absolute;
    background-color: var(--color_lightGray); 
    top: 0px;
    right: -12px;
    z-index: 4;
    & span {
        width: 20px;
        height: 20px;
        position: absolute;
        background-color: #fff;    
        top: 15px;
        right: -10px;
        transition: all .1s;        
        &::before, &::after {
            content: '';
            border-radius: 1px;
            position: absolute;
            background-color: #979797;
            top: 3px;
            left: 9px;
            width: 1px;
            height: 8px;
            transform: rotate(-45deg);
        }
        &::after {
            top: 8px;
            transform: rotate(45deg);
        }
    }
}
.arrow-left span {
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

.expDocSpinner {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    display: inline-flex;
    align-items: baseline;
    user-select: none;
    > div {
        position: static;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px !important;
        transform: translate(0px, 3px);
        img {
            height: 15px;
            width: 15px;
            margin: 0;
        }
    }
}
.screen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 3;
}