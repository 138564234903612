.container {
    width: 190px;
    height: 29px;
    padding: 0px 15px 0px 15px;
    border-radius: 6px;
    box-shadow: var(--shadow_stylesBlock);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &.full {
        width: 400px;
    }
    &.active {
        padding: 0px 40px 0px 15px;
    }
    &.opened {
        width: 400px;
        padding: 0px 40px 0px 15px;
        height: auto;
        & > .title {
            margin-bottom: 28px;
        }
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: var(--color_lightGray);
            position: absolute;
            top: 29px;
            left: 0px;
        }
    }
    &.main {
        margin-bottom: 20px;
    }
    &.main::before, &.mainInList::before {
        content: "";
        background-color: var(--color_primary);
        width: 2px;
        height: 15px;
        position: absolute;
        left: 0px;
        top: 7px;
    }
}

.title {
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 15px; */
}

.titleStyles {
    /* height: 15px; */
}

.arrow {
    position: absolute;
    right: 15px;
    top: 25px;
}

.gradient {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 15px;
    height: 15px;
    background-image: linear-gradient(to right, var(--gradient_black0), var(--color_white));
    &.active {
        right: 40px;
    }
}

.container:hover {
    cursor: pointer;
}

.params:hover {
    cursor: default;
}
.buttons {
    margin: 20px 0px 13px;
    display: flex;
    gap: 10px;
}