.row {
    display: flex;
    align-items: baseline;
    color: var(--color_darkGray);
    font-size: var(--fontSize_standart);
    &.centered {
        align-items: center;
    }
}

.column {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    height: 160px;
} 

.svgIcons {
    width: 128px;
    height: 19px;
    margin-right: 20px;
    /* border-radius: 5px; */
    display: flex;  
    & > div {
        text-align: center;
        flex-grow: 1;
        border: solid 1px var(--color_lightGray2);
        border-right: solid 0px;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & :first-child {
        border-radius: 5px 0 0 5px;
    }
    & :last-child {
        border-radius: 0 5px 5px 0;
    }
    & > div:last-child {
        border-right: solid 1px var(--color_lightGray2);
    }
    & > div:has( > svg > .active):last-child {
        border-right: 0px;
    }
}

/* Доделать */

div:has( > svg > .active) {
    background-color: var(--color_gray);
    border: solid 1px var(--color_gray);
    & + div {
        border-left: solid 0px;
    }
}

.svg {
    stroke: var(--color_gray);
    &.active {
        stroke: var(--color_white);
    }
}

.clickable {
    cursor: pointer;
}

.marg10 {
    margin-top: 10px;
}

.marg20 {
    margin-top: 20px;
}

.marg25 {
    margin-top: 25px;
}

.margl10 {
    margin-left: 10px;
}

.margl20 {
    margin-left: 20px;
}

.headingContainer {
    display: flex;
    flex-direction: column;

    &.after {
        margin-left: 148px;
    }
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.heading {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    margin-bottom: 2px;
    max-width: 80px;
    &.auto {
        max-width: max-content;
    }
}

.textContainer {
    border-radius: 5px;
    border: dashed 1px var(--color_lightGray);
    padding: 4px 10px 5px 25px;
    display: flex;
    flex-direction: column;
    position: relative;

    min-width: 190px; 
    max-width: 190px; 
    /* Позже попытаться поменять */
}

.textPart {
    margin-top: 0px;
    min-width: 150px;
    font-size: var(--fontSize_9);
    color: var(--color_gray);
    margin: 0px;
    &.indentPlus {
        text-indent: 20px;
    }
    &.indentMinus {
        text-indent: -20px;
    }
    &.gap {
        margin-bottom: 12px;
    }
}

.hilighter {
    opacity: 0.2;
    background-color: var(--color_primary);
    position: absolute;
    &.left {
        left: 5px;
        top: 4px;
        bottom: 4px;
        width: 20px;
    }
    &.right {
        left: 165px;
        top: 4px;
        bottom: 4px;
        width: 20px;
    }
    &.before {
        left: 25px;
        top: 46px;
        width: 140px;
        height: 5px;
    }
    &.after {
        left: 25px;
        top: 108px;
        width: 140px;
        height: 5px;
    }
    &.hanging {
        left: 5px;
        top: 51px;
        width: 20px;
        height: 10px;
    }
    &.firstline {
        left: 25px;
        top: 118px;
        width: 20px;
        height: 10px;
    }
    &.line {
        position: relative;
        width: 140px;
        height: 2px;
    }
}

.linespacing {
    position: absolute;
    left: 25px;
    top: 60px;
    height: 51px;
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}