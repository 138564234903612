.wrapper {
    width: 100%;
    height: calc(100vh - 110px);
    margin-top: -10px;
    padding: 0 40px 40px;
    display: flex;
    justify-content: center;
}
.dropzone, .setUp {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--color_lightGray2);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    transition: border .2s ease-out, color .2s ease-out, background-color .2s;
    cursor: pointer;
}
.dropzone {
    margin-right: 40px;
    color: var(--color_gray);
    & path {
        transition: stroke .2s ease-out;
    }
    & > span {
        color: var(--color_primary);
    }
    &.active, &:hover {
        border: 1px dashed var(--color_green);
        color: var(--color_black);
        & path {
            stroke: var(--color_black);
        }
    }
    &:hover {
        border: 1px dashed var(--color_green);
    }
    .title {
        color: var(--color_green);
    }
}

.setUp {
    .title {
        color: var(--color_primary);
    }
    &:hover {
        border: 1px dashed var(--color_primary);
    }
}

.title {
    font-size: var(--fontSize_h2);
    font-weight: bold;
    margin-bottom: 20px;
}
.text {
    font-size: var(--fontSize_tab);
    color: var(--color_darkGray);
}
