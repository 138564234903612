.wrapper {
    position: relative;
    margin: 20px 0;
    height: 50px;
    display: flex;
    z-index: 1;
    &.noCheck {
        .left::after {
            display: none;
        }
        .userInfo_button {
            display: none;
        }
        .nameBlock {
            cursor: default;
            &:hover .name, &:hover .email{
                transition-delay: 9999999s;
            }
        }
    }
    &.fullInfo {
        z-index: 5;
        .userInfo_button {
            opacity: 1;
        }
        .userInfo {
            opacity: 1;
            visibility: visible;
        }
        .left::after {
            /*opacity: 0; */
            transition: opacity 0s 0s;
        }
        .control:not(.prove):not(._checked) {
            opacity: 1;
            visibility: visible;
            display: block;
        }
    }
    &.ja:not(.b) {
        .control {
            opacity: 1 !important;
            visibility: visible !important;
            display: block !important;
        }
    }
    &.addListItem {
        height: fit-content;
        .left {
            width: 100%;
            &::after {
                background: linear-gradient(to right, rgba(255, 255, 255, 0), white) !important;
            }
        }
        .nameBlock {
            max-width: 240px;
        }
    }
    &:hover {
        .userInfo_button {
            opacity: 1;
        }
        .addBtn {
            opacity: 1;
        }
    }
    &.ext {
        .left::after {
            background: linear-gradient(to right, #f3f8ff00, #f3f8ff);
        }
    }
    &.b {
        .left::after {
            background: linear-gradient(to right, rgba(231, 241, 255, 0), var(--color_lightGray));
        }
    }
    &.b.ext {
        .left::after {
            background: linear-gradient(to right, rgba(231, 241, 255, 0), #e7f1ff);
        }
    }
    &.fullInfo .left::after {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white) !important;
    }
    &.nc:not(.addListItem) {
        .nameBlock {
            cursor: default;
        }
        .nameBlock:hover {
            .name, .email  {
                transition-delay: 9999999s;
            }
        }
        .left::after {
            background: linear-gradient(to right, rgba(255, 255, 255, 0), white) !important;
        }
    }
}

.left {
    position: relative;
    display: inline-flex;
    width: 160px;
    margin-right: 20px;
    .nameBlock {
        position: relative;
        margin-left: 15px;
        max-width: 140px;
        height: fit-content;
        cursor: pointer;
        &._email {
            transform: translateY(3px);
        }
        &_tip > div {
            bottom: 39px;
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s, visibility .2s;
        }

    }
    .nameBlock:hover {
        .name, .email {
            color: var(--color_primary);
        }
        .name + .email {
            color: var(--color_gray)
        }
        .nameBlock_tip > div {
            opacity: 1;
            visibility: visible;
            transition: opacity .2s .5s, visibility .2s .5s;
        }
    }
    .name, .email {
        font-size: var(--fontSize_tab);
        color: var(--color_black);
        white-space: nowrap;
        font-weight: bold;
        overflow: hidden;
        transition: color .2s;
    }
    .name + .email {
        font-size: var(--fontSize_tip);
        color: var(--color_gray);
        font-weight: normal;
        overflow: hidden;
    }
    .notRegistered {
        font-size: var(--fontSize_tip);
        color: var(--color_lightRed);
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        background: linear-gradient(to right, rgba(238, 238, 238, 0), #f9f9f9);
        width: 50px;
        height: 100%;
        transition: opacity 0s .2s;
    }
}

.addNamePopup {
    position: absolute;
    bottom: 0;
    left: -20px;
    width: 360px;
    padding: 20px;
    box-shadow: var(--shadow_menu);
    background-color: var(--color_white);
    border-radius: 6px;
    z-index: 6;
    input {
        width: 149px;
        padding: 10px 15px;
        font-size: var(--fontSize_tab);
        color: var(--color_black);
        border-radius: 4px;
        border: 1px solid var(--color_lightGray);
        margin-bottom: 20px;
        &::placeholder {
            color: var(--color_gray);
        }
    }
}

.controls {
    display: inline-block;
    max-height: 50px;
    .control {
        position: relative;
        margin-bottom: 5px;
        transition: all .2s;
        > div {
            width: 150px;
            white-space: normal;
            opacity: 0;
            left: -70px;
            bottom: 25px;
            visibility: hidden;
            transition: all .2s;
        }
        &:hover > div {
            opacity: 1;
            visibility: visible;
            transition: all .2s .5s;
        }
    }
    .control:not(.prove):not(._checked) {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
}

.removePopupWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
}

.removePopup {
    position: relative;
    width: 250px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: var(--shadow_big);
    margin: 0 auto;
    background-color: white;
}
.userInfo {
    position: absolute;
    top: -20px;
    left: -20px;
    padding: 80px 20px 20px;
    height: auto;
    width: calc(100% + 40px);
    opacity: 0;
    visibility: hidden;
    background-color: var(--color_white);
    border-radius: 6px;
    box-shadow: var(--shadow_menu);
    transition: all .2s;
    z-index: -1;
}
.userInfo_button {
    opacity: 0;
    border: none;
    background: transparent;
    transition: all 0.2s;
    font-size: 0;
    cursor: pointer;
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    right: 20px;
    top: -4px;
    display: flex;
    flex-direction: column;
    & > span {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #606060;
        display: inline-block;
        &:nth-child(2) {
            margin: 3px 0;
        }
    }
    &:hover{
        & > span {
            background-color: var(--color_primary_hover__dark);
        }
    }
}

.addBtn {
    position: relative;
    display: inline-block;
    top: 3px;
    opacity: 0;
    transition: all .2s;
}

.userStats {
    width: 140px;
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    > div:not(:last-child) {
        margin-bottom: 20px;
    }
}

.resendInviteSpinner {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    white-space: nowrap;
    min-width: 114px;
    margin-right: 20px;
    margin-top: -2px;
    height: 15px;
    > div {
        position: relative;
        display: inline-block;
        top: 3px;
        left: 0;
        width: 15px;
        margin-right: 5px;
        > img {
            width: 15px;
            height: 15px;
            margin: 0;
        }
    }
}
