.ruler {
    /* border-bottom: 1px solid var(--color_gray); */
    width: 80rem;
    height: 45px;
    display: flex;
    align-items: end;
    margin-left: -3px;
    overflow-x: hidden;
    position: relative;
    padding-bottom: 25px;
    user-select: none;
    &.disabled {
        user-select: none;
        opacity: 0.25;
    }
}

.item {
    width: 1px;
    min-width: 1px;
    max-width: 1px;
    background-color: var(--color_lightGray2);
    margin-left: calc(0.95rem - 1px);
    height: 2px;
    position: relative;
    overflow-y: visible;
    &:nth-child(1) {
        margin-left: 0px;
    }
    &.long {
        height: 5px;
        &.inactive {
            height: 10px;
        }
    }
    &.inch {
        margin-left: calc(2.4rem - 1px);
    }
    &.inch:nth-child(1) {
        margin-left: calc(2.4rem - 102px);
    }
}

.number {
    font-size: var(--fontSize_tip);
    color: var(--color_darkGray);
    position: absolute;
    bottom: 3px;
    left: -6px;
    width: 12px;
    text-align: center;
    user-select: none;
}

.slider {
    -webkit-appearance: none;
    position: absolute;
    width: calc(80rem + 12px);
    top: 10px;
    left: -7px;
    height: 0;
    pointer-events: none; /* Отключение прямого управления через мышь */
    &.disabled {
        user-select: none;
    }
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    pointer-events: all;
    width: 15px;
    height: 15px;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.disabled::-webkit-slider-thumb {
    user-select: none;
    pointer-events: none;
}

.left-indent::-webkit-slider-thumb {
    background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+CiAgPHBvbHlsaW5lIHBvaW50cz0iMjAsNDAgNTAsNzAgODAsNDAiIHN0cm9rZT0iIzE1N0VGQiIgc3Ryb2tlLXdpZHRoPSIxMCIgZmlsbD0ibm9uZSIgLz4KPC9zdmc+Cg==');
}

.left-indent {
    top: 6px;
}

.right-indent::-webkit-slider-thumb {
    background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+CiAgPHBvbHlsaW5lIHBvaW50cz0iMjAsNDAgNTAsNzAgODAsNDAiIHN0cm9rZT0iIzE1N0VGQiIgc3Ryb2tlLXdpZHRoPSIxMCIgZmlsbD0ibm9uZSIgLz4KPC9zdmc+Cg==');
    transform: scaleY(-1);
}

.right-indent {
    top: 23px;
}

.first-line-indent::-webkit-slider-thumb {
    background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDExMCI+IAogIDwhLS0g0JLQtdGA0YXQvdGP0Y8g0LPQsNC70L7Rh9C60LAgLS0+CiAgPHBvbHlsaW5lIHBvaW50cz0iMjAsMzAgNTAsNjAgODAsMzAiIHN0cm9rZT0iIzE1N0VGQiIgc3Ryb2tlLXdpZHRoPSIxMCIgZmlsbD0ibm9uZSIgLz4KICA8IS0tINCd0LjQttC90Y/RjyDQs9Cw0LvQvtGH0LrQsCAo0YLQsNC60LDRjyDQttC1LCDQutCw0Log0LLQtdGA0YXQvdGP0Y8pIC0tPgogIDxwb2x5bGluZSBwb2ludHM9IjIwLDcwIDUwLDEwMCA4MCw3MCIgc3Ryb2tlPSIjMTU3RUZCIiBzdHJva2Utd2lkdGg9IjEwIiBmaWxsPSJub25lIiAvPgo8L3N2Zz4K');
    transform: scaleY(-1);
}

.first-line-indent {
    top: 23px;
}


.inactiveZone {
    background-color: var(--color_white);
    opacity: 0.75;
    height: 15px;
    width: 76px;
    position: absolute;
    &.right {
        right: 1px;
    }
    &.left {
        left: 0px;
    }
    & :not(.filler) {
        top: 0px;
    }
    &.filler {
        background-color: var(--color_lightGray2);
        height: 5px;
        bottom: 25px;
        opacity: 0.25;
    }
}