.wrapper {
  padding: 43px 100px 0;
  width: 1000px;
  margin: auto;
  color: var(--color_black);
  font-family: var(--fontFamily);
  display: flex;
  flex-direction: column;
  min-height: 75vh;
}

.content{
    flex: 0 0 auto;
}
.align{
    flex: 1 0 auto;
    min-height: 100px;
}

.header {
  font-size: var(--fontSize_h1);
  font-weight: 900;
  line-height: 1.18;
}

.centered {
    text-align: center;
}

.subheader {
  font-size: 22px;
  letter-spacing: 0.6px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.subheader_mrg {
  margin-top: 10px;
  margin-bottom: 30px;
}

.normal {
  font-size: var(--fontSize_tab);
  line-height: 1.57;
  margin-left: 50px;
  & a {
      color: var(--color_primary);
  }  
}

.womargin {
    margin-left: 0;
}

.placeholder {
    margin-bottom: 40px;
    font-size: 22px;
}

.switching{
    width: 306px;
    margin: 0 auto 50px auto;
}

.sw {
    display: inline-block;
    width: 150px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: solid 1px var(--color_lightGray2);
}

.left {    
    border-radius: 6px 0 0 6px;        
}
.right {    
    border-radius: 0 6px 6px 0;    
}

.checked {
    color: var(--color_white);
    background-color: var(--color_lightGray2);
}

.unchecked {    
    color: var(--color_gray);
    &:hover {
        cursor: pointer;
        color: var(--color_black);
    }
}

.bold {
    font-weight: bold;
}

.ital {
    font-style: italic;
    text-align: center;
}

.lol {
    list-style: none;
    counter-reset: li;
    padding-left: 0;
    font-weight: normal;
    & > li:before {
        display: inline-block;
        counter-increment: li;
        width: 50px;
        content: counters(li,".") ". ";
        margin-left: -50px;
        font-weight: normal;
        vertical-align: top;
    }
    & > li, & > div, & > li > div {
        margin-top: 22px;
    }
    & hr {
        margin: 2px 0 10px -50px;
    }       
}

.tbl {
    display: inline-block;
    vertical-align: top;
}

.opad {
    padding-top: 25px;
}

.enum {
    width: 60px;
}
.enum-add {
    width: 690px;
}

.width {
    width: 250px;
}

.wright {
    width: 500px;
}

.wrights {
    width: 500px;
}

.wrights-small {
    width: 440px;
}

.link {
    color: var(--color_primary);
}

.to-main-page-button {
  margin-top: 20px;
  padding: 10px 20px;
  display: inline-block;
  color: var(--color_white);
  text-decoration:
  none; border-radius: 6px;
  background-color: var(--color_primary);
  font-size: var(--fontSize_tab);
  font-weight: 400;
}

.mission-wrapper {
    color: #212121;
    width: 750px;
    margin: 0 auto;
    padding-bottom: 120px;
    padding-top: 70px;
}
.mission-title {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0.02px;
    text-align: center;
    span:nth-child(1) {
        color: var(--color_primary_hover);
    }
    span:nth-child(2) {
        color: var(--color_lightRed);
    }
}
.mission-subTitle {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 100px;
    text-align: center;
}
.mission-text {
    width: 744px;
    font-size: 24px;
    line-height: 36px;
}