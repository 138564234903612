.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 40px 0;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
}
