.screen {
    position: fixed;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 14;
    &._op {
        background-color: rgba(255, 255, 255, 0.70);
    }
}

.popup {
    position: fixed;
    top: 40px;
    right: 40px;
    bottom: 0;
    height: fit-content;
    width: 400px;
    background-color: var(--color_white);
    padding: 40px;
    box-shadow: var(--shadow_big);
    border-radius: 20px;
    transition: opacity .2s, visibility .2s;
    z-index: 15;
    &._freeze {
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}

.loginLink {
    position: absolute;
    cursor: pointer;
    right: 83px;
    top: 47px;
    font-size: var(--fontSize_standart);
    > button {
        width: 72px;
        height: fit-content;
        text-align: left;
    }
}

.row {
    position: relative;
    padding: 20px 0;
    display: block;
    &.border {
        border-bottom: 1px solid var(--color_lightGray2);
    }
}

.input {
    display: block;
    height: 19px;
    width: 100%;
    color: var(--color_darkGray);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_tab);
    border: 0;
    padding: 0;
    &::placeholder {
        color: var(--color_darkGray);
        font-size: var(--fontSize_tab);
        font-weight: normal;
    }
    &.password {
        padding-right: 110px;
    }
}

.error-message {
    position: absolute;
    color: var(--color_lightRed);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_standart);
    top: 0;
    left: 0;
    &.grey {
        color: var(--color_gray);
    }
}

.checkbox {
    font-size: var(--fontSize_tab);
    display: flex;
    align-items: start;
    width: fit-content;
    padding: 20px 0 10px 0;
    > label > span > svg {
        top: -1px;
    }
    > div > span {
        cursor: pointer;
        margin-top: 4px;
    }
    > label > span:nth-child(2) {
        transform: translateY(-10px);
    }
    &-text {
        padding-left: 10px;
        color: var(--color_gray);
        line-height: 19px;
        cursor: pointer;
        > a {
            color: var(--color_black);
            text-decoration: none;
            transition: color .2s;
            &:hover {
                color: var(--color_primary_hover);
            }
        }
    }
}

.button-row {
    padding-top: 30px;
    text-align: center;
}

.message-wrapper {
    padding-top: 40px;
    text-align: center;
    color: var(--color_lightRed);
    font-family: var(--fontFamily);
    font-size: var(--fontSize_h3);
    font-weight: 400;
}