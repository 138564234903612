.wrapper {
  width: auto;
  height: 100%;
}


.mainfield {
  position: relative;
  border-left: 1px solid var(--color_lightGray2);
  box-shadow: 50px 0 70px 0 rgba(0, 0, 0, 0.05);
  width: 801px;
  min-height: 100%;
  max-height: 100%;
  background-color: white;
  font-family: var(--fontFamily);
  display: flex;
}

.tip {
    display: inline-block;
    position: relative;
    & div {
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s, visibility .2s;
    }
    &:hover div {
      opacity:1;
      visibility: visible;
    }
}

.part {
    padding: 40px;
    width: 400px;
}
.partCommon{
    padding: 0 43px 100px 0;
}
.bottomed {
    padding-bottom: 117px;
}

.vertRule {
    width: 1px;
    background-color: var(--color_lightGray2);
}

.title {
  margin: 0 0 50px;
  font-size: var(--fontSize_h1);
  font-weight: 700;
  color: var(--color_black);
}

.neutral {
  font-size: var(--fontSize_tab);
  color: var(--color_darkGray);
}

.radioField {
  display: flex;
  justify-content: center;
   padding: 0 20px 50px 20px;
}

.h2 {
  font-size: var(--fontSize_h2);
  font-weight: 700;
  color: var(--color_black);
  height: 40px;
  line-height: 40px;
}
.h3 {
  font-size: var(--fontSize_h3);
  font-weight: 700;
  color: var(--color_black);
  margin-bottom: 9px;
}

.buttonField {
  padding-bottom: 50px;
}

.versionField {
    padding-bottom: 20px;
}

.arrow {
  position: relative;
    left: 10px;
    top: 4px;
    background: url('/images/down@3x.png');
    background-size: cover;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &._expanded {
        background: url(/images/up@3x.png);
        background-size: cover;
    }
}

.close-button {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color_lightGray2);
  cursor: pointer;
  &:hover {
    background: #ababab;
   }
}

.close-button::before {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 15px;
  height: 2px;
  position: absolute;
  content: "";
  top: 16.2px;
  left: 9.5px;
  border: none;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #ffffff;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.close-button::after {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 15px;
  height: 2px;
  position: absolute;
  content: "";
  top: 16.2px;
  left: 9.5px;
  border: none;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #ffffff;
  text-shadow: none;
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}


.change-creator {
  font-size: var(--fontSize_standart);
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: var(--color_gray);
.time-calc {
  font-weight: bold;
 }
}
.inspan {
      color: var(--color_primary);
      margin-right: 10px;
      cursor: pointer;
}

.mt-30 {
    margin-top: 30px;
}

.mt-15 {
    margin-top: 15px;
}

.list {
  position: relative;
  left: -20px;
  height: 350px;
  width: calc(100% + 20px);
}

.actual {
  position: relative;
  left: -20px;
  /* overflow-y: hidden; */
  width: calc(100% + 20px);
  margin-top: 40px;
}

.ml-20{
  margin-left: 10px;
}

.btns{
  display: none;
  width: 60px;
  text-align: right;
}

.icon{
  height: 24px;
  width: auto;
  cursor: pointer;
}

.text{
  width: calc(100% - 60px);
}

.version {
  padding: 12px 20px 12px 20px;
  border-radius: 4px;
  margin: 0 34px 6px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
    &._opened {
      background-color: var(--sky);
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
      cursor: default;
      .btns{
        display: block;
      }      
      .number {
        color: #ffffff;
      }
      .creator, .date {
        color: #80b9fe;
      }
      &:hover .number {
        color: #ffffff;
      }
    }
}



.number {
  display: inline-block;
  font-size: var(--fontSize_h3);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0;
  color: var(--color_black);
  padding-bottom: 2px;  
}

.version:hover .number {
    color: var(--color_primary);
}

.creator, .date {
  display: block;
  font-size: 13px;
  color: var(--color2);
}
.date {
  padding-bottom: 3px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.headline {
  margin: 40px 0 5px 0;
  font-size: 22px;
  letter-spacing: 0.6px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: var(--color_black);
  word-break: break-word;
  &.timing {
     color: var(--color_darkGray);
   }
}

.showMore {
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 13px;
    color: var(--color_black);
    font-weight: bold;
    display: none;
    &._visible {
        display: block;
    }
}
