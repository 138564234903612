.wrapper {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 40px;
    border-radius: 20px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    font-size: var(--fontSize_standart);
    width: fit-content;
    display: flex;
    align-items: baseline;
    z-index: 15;
    max-width: 834px;
    button {
        white-space: nowrap;
    }
}

.text {
    display: inline-block;
    color: var(--color_darkGray);
    padding-right: 55px;
    white-space: nowrap;
    span {
        color: var(--color_gray);
    }
}

.btn_no {
    cursor: pointer;
    display: inline-block;
    color: var(--color_gray);
    white-space: nowrap;
}

@media screen and (max-width: 810px) {
    .wrapper {
        max-width: 430px;
        flex-wrap: wrap;
        .text {
            margin-bottom: 20px;
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 460px) {
    .wrapper {
        max-width: 300px;
        flex-wrap: wrap;
        .text {
            margin-bottom: 20px;
            white-space: normal;
        }
        .btn_no {
            margin-top: 10px;
        }
    }
}