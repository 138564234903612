.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 40px 0;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;

}
.popup_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: 0 auto;
    padding-bottom: 40px;
    width: 400px;
}
.popup {
    border-radius: 20px;
    padding: 40px;
    box-shadow: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-color: #fff;
    color: var(--color_black);
}

.header {
    font-size: var(--fontSize_h2);
    font-weight: bold;
    margin-bottom: 40px;
}

.text {
    font-size: var(--fontSize_h3);
    margin-bottom: 40px;
}
.question {
    font-size: var(--fontSize_h3);
    font-weight: bold;
    margin-bottom: 20px;
}

.btn-row {
    display: flex;
    justify-content: center;
    &._swap > button:last-child {
         order: -1;
         margin-left: 0;
    }
    &._swap > button:first-child:not(:last-child), &:not(._swap) > button:last-child:not(:first-child) {
        margin-left: 20px;
    }
}

.coverMessage {
    margin-top: 20px;
    margin-bottom: 40px;
}