.wrapper {
    position: absolute;
    top: 10px;
    left: -40px;
    opacity: 0;
    padding: 40px 20px 0;
    width: 400px;
    background-color: white;
    border-radius: 6px;
    box-shadow: var(--shadow_menu);
    visibility: hidden;
    transition: all .2s;
    cursor: default;
    z-index: 1;
    &._vis {
        opacity: 1;
        visibility: visible;
    }
}

.input {
    position: relative;
    margin: 0 20px 40px 20px;
    button {
        position: absolute;
        right: 30px;
        top: 8px;
    }
    input {
        font-size: var(--fontSize_tab);
        color: var(--color_darkGray);
        width: 100%;
        padding: 10px 55px 10px 15px;
        border: 1px solid var(--color_lightGray);
    }
    input::placeholder {
        color: var(--color_gray);
    }
    > div {
        top: 5px;
        right: 10px;
    }
}

.selectedRecipients {
    border: 1px solid var(--color_lightGray);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 40px;
    > div:not(:last-child) {
        margin-bottom: 20px;
    }
}

.candidates {
    padding: 0 20px 40px;
    > div:not(:last-child) {
        margin-bottom: 20px;
    }
}
.candidate {
    display: flex;
    &:not(._sel) {
        .nameBlock {
            cursor: pointer;
            &:hover > div:first-child {
                color: var(--color_primary_hover);
            }
        }
    }
    .left {
        display: flex;
        width: 295px;
        div:first-child {
            font-size: var(--fontSize_tab);
            color: var(--color_darkGray);
            font-weight: bold;
            transition: color .2s;
        }
        .nameBlock {
            margin-left: 15px;
            > div:last-child {
                font-size: var(--fontSize_tip);
                color: var(--color_gray);
            }
            > div:first-child {
                font-size: var(--fontSize_tab);
                color: var(--color_darkGray);
            }
            > div:first-child:last-child {
                transform: translateY(5px);
            }
        }
    }
    .right {
        position: relative;
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
        .cross {
            top: 0;
            left: 14px;
        }
    }
    &:hover .right {
        opacity: 1;
        visibility: visible;
    }
}