.tagging {
    position: fixed;
    padding: 10px 2px 10px 0;
    width: fit-content;
    background-color: white;
    box-shadow: var(--shadow_menu);
    white-space: nowrap;
    z-index: 8;
    border-radius: 10px;
    overflow: hidden;
    &_userList {
        overflow-y: auto;
        scrollbar-color: #cacaca transparent;
        scrollbar-width: thin;
        &:hover {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -moz-overflow-scrolling: touch;
            -ms-overflow-scrolling: touch;
            -o-overflow-scrolling: touch;
            overflow-scrolling: touch;
        }
        &::-webkit-scrollbar {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            margin-right: 2px;
            border-radius: 2px;
            background-color: #cacaca;
            box-shadow: 0 0 1px rgba(255,255,255,0.5);
        }
    }
    &_userList_item {
        display: flex;
        padding: 15px 20px;
        &:hover {
            background-color: rgba(222,222,222,0.3);
        }
        &:first-child {
            padding: 25px 20px 15px;
            margin-top: -10px;
        }
        &:last-child {
            padding: 15px 20px 25px;
        }
        &:first-child:last-child {
            padding: 25px 20px 25px;
            margin-top: -10px;
        }
        &_nameBlock {
            margin-left: 15px;
            > div:last-child {
                font-size: var(--fontSize_tip);
                color: var(--color_gray);
            }
            > div:first-child {
                font-size: var(--fontSize_tab);
                color: var(--color_darkGray);
            }
            > div:first-child:last-child {
                transform: translateY(3px);
            }
        }
    }
}
.tagging_dueBlock {
    position: relative;
    display: flex;
    user-select: none;
    border-top: 1px solid var(--color_lightGray);
    padding: 12px 20px;
    &._checked {
        .checkboxDue > span > span {
            opacity: 1;
        }
        .choiceDueBlock {
            opacity: 1;
            visibility: visible
        }
    }
    .checkboxDue {
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        transform: translateY(7px);
        svg {
            top: 1px;
        }
        & > span {
            margin-right: 10px;
            padding-left: 10px;
            cursor: pointer;
            transform: translateY(-1px);
            > span {
                transition: opacity .2s;
                opacity: 0;
            }
        }
    }
    .choiceDueBlock {
        display: inline-flex;
        justify-content: space-between;
        min-width: 212px;
        transform: translateY(8px);
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s, visibility .2s;
    }
    .choiceDue {
        display: inline-flex;
        align-items: baseline;
        transform: translateY(-2px);
        & > span {
            height: 20px;
            padding: 2px 5px 1px;
            margin: 0 5px 0 0;
            font-size: var(--fontSize_standart);
            color: var(--color_primary);
            cursor: pointer;
        }
        & > span:last-child {
            margin-right: 0;
        }
        & > span.active {
            color: var(--color_darkGray);
            border-radius: 4px;
            background-color: rgba(222, 222, 222, 0.5);
            padding: 2px 5px 1px;
            cursor: default;
        }
    }
    & .left, & .right {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 20px;
        cursor: pointer;
        transform: translateY(-3px);
        & > div {
            top: 32px;
            left: -13px;
            visibility: hidden;
            opacity: 0;
        }
        &:hover > div {
            visibility: visible;
            opacity: 1;
            transition: opacity .2s .5s, visibility .2s .5s;
        }
        &::before, &::after {
            content: '';
            border-radius: 1px;
            background-color: var(--color_gray);
            width: 8px;
            height: 1px;
            transform: rotate(-45deg);
            position: absolute;
            top: calc(50% + 2px);
            right: 0;
            transition: background-color .2s;
        }
        &::after {
            top: 7px;
            transform: rotate(45deg);
        }
        &._disabled {
            cursor: default;

            &::before, &::after {
                background-color: var(--color_lightGray2);
            }
        }
    }
    & .left {
        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}