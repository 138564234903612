.wrapper {
  width: fit-content;
  margin: auto;
  padding: 50px 40px 30px;
  font-family: var(--fontFamily);
  color: var(--color_darkGray);
  font-size: var(--fontSize_tab);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.wrapper > .logo {
  position: relative;
  top: 6px;
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 20px;
}

.copyright {
  margin-right: 50px;
  margin-bottom: 20px;
}

.link {
  margin-right: 50px;
  margin-bottom: 20px;
  color: var(--color_primary);
  text-decoration: none;
  transition: color .2s;
  &:hover {
    color: var(--color_primary_hover);
  }
}
.mission {
  font-weight: bold;
  transition: color .2s;
  span {
    font-weight: normal;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
  }
  &:hover {
    color: var(--color_primary_hover);
  }
}

@media screen and (max-width: 767px){
  .wrapper {
    .logo {
      display: none !important;
    }
    .copyright {
      display: block;
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
    .link {
      margin-right: 20px;
    }
  }
}